<div>
    <mat-tab-group>
        <mat-tab label="Active Onboarding Applications">
            <ng-template matTabContent>
                <div>
                    <app-dashboard></app-dashboard>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Task Queues">
            <ng-template matTabContent>
                <div>
                    <app-task-queues></app-task-queues>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
