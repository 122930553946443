import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class UsersAndGroupsService {
  constructor(
    private http: HttpClient,
  ) { }

  getGroups() {
    return this.http.get<any>(`${environment.apiUrl}/usergroups/user`)
  }

  getGroupsForUser(req: any) {
    return this.http.post(`${environment.apiUrl}/usersAndGroups/user/groups`, req)
  }

  getCategoryUsers(categoryId) {
    return this.http.get<any>(`${environment.apiUrl}/categories/` + categoryId + `/users`)
  }

  getAllUsers() {
    return this.http.get<any>(`${environment.apiUrl}/usersAndGroups/getUsers`)
  }

  createUser(req: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/djangoauth/user/create`, req).pipe()
  }

  createGroup(group) {
    return this.http.post<any>(`${environment.apiUrl}/usersAndGroups/create`, group).pipe(catchError(this.errorHandler))
  }

  getAllGroups() {
    return this.http.get<any>(`${environment.apiUrl}/usersAndGroups/getAllGroups`).pipe(catchError(this.errorHandler))
  }

  getAllDjangoGroups() {
    return this.http.get<any>(`${environment.apiUrl}/djangoauth/group/all`).pipe(catchError(this.errorHandler))
  }

  getDjangoGroupsforUser(req: any) {
    return this.http.post(`${environment.apiUrl}/djangoauth/group/user`, req)
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
