<mat-card class="mt-7" style = "background-color:#B5D9F3;  margin-top: 10%; margin-left:30%; margin-right:30%; height:100%;" *ngIf="!documentLoaded">
    <div style="text-align: center;">
    <strong>Load Report</strong>
</div>
<div>
    <div class = "mt-2">
        <div class="input-group-sm input-group col-7" style="margin-left:20%">
        <div class="input-group-prepend">
            <span class="input-group-text" style="width:100%; font-size:x-small; max-height:100%">Report Type *</span>
        </div>
        <mat-select  style="font-size: x-small;  min-height:30px" placeholder = "Please select the report type..." [(ngModel)] = "report"
        class="form-control">
        <mat-option *ngFor = "let type of reportType"[value]="type">{{type}}</mat-option>
        </mat-select>
    </div>
    <div class="input-group-sm input-group col-7" style="margin-left:20%; margin-top:1px">
        <div class="input-group-prepend">
            <span class="input-group-text" style=" font-size:x-small; max-height:100%; width:78px;">File *</span>
        </div>
        <input type="file" class="form-control" style = "font-size: x-small; min-height:30px" (change)="handleFileInput($event.target.files)" #fileInput>
    </div>
    </div>

    <div>
        <button mat-button style="background-color: #03277c; color:white; margin-top:5%; font-size:x-small; margin-left:85%" (click)="load()" >Load</button>
    </div>
</div>

</mat-card>

<div *ngIf="documentLoaded">
    <mat-card class="mt-7" style = " background-color:#B5D9F3; text-align: center; margin-top: 10%; margin-left:25%; margin-right:25%;">
        <div style="padding-top: 3%;"><strong>{{uploadMessage}}</strong></div>
        <button mat-button style="background-color: #03277c; color:white; margin-top:5%; font-size:x-small; margin-left:80%" (click)="back()">Ok</button>
    </mat-card>
</div>
