import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CategoryService } from 'src/app/_services/category.service';
import { AlertService } from 'src/app/_services/alert.service';
import { User } from 'src/app/_models';
import { DocumentService } from 'src/app/_services/document.services';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-upload-document-version',
  templateUrl: './upload-document-version.component.html',
  styleUrls: ['./upload-document-version.component.scss']
})
export class UploadDocumentVersionComponent implements OnInit {

  
  showData;

  categoryId: any;
  @Input() fileToUpload: File = null;
  @Input() selectedDocument: any
  @Output() openUpload = new EventEmitter<boolean>();
  category: any;
  azureHeader = {};
  documentTitle: String = null;
  docId: String = null;
  properties = {};
  uploadMessage: Boolean = false;
  toggleUpload: Boolean = true;
  currentUser: User;
  isWorkflow: boolean = false;
  workflowID: any;
  filePath: string = null
  regions: any

  constructor(
    private categorySvc: CategoryService,
    private documentService: DocumentService,
    private alertService: AlertService, 
    //@Inject(MAT_DIALOG_DATA) public data: any
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
   }

  ngOnInit(): void {
    console.log("Selected doc", this.selectedDocument)
    if (this.fileToUpload.type.includes('pdf') || this.fileToUpload.type.includes('image')) {
      this.filePath = (window.URL || window.webkitURL).createObjectURL(this.fileToUpload)
    }
    this.categoryId = 1
    this.categorySvc.get(this.categoryId)
      .subscribe(category => {
        this.category = category;
      })
  }

  uploadDocument() {

    //build object to be sent to the service
    if(this.selectedDocument){

      this.documentService.uploadDocumentVersionDirect(this.selectedDocument).subscribe(resp=>{
        console.log("RESP,", resp)
        this.uploadMessage = true;
        this.toggleUpload = false;
        
      })
        // this.documentService.uploadDocumentVersion(this.selectedDocument)
        // .subscribe(
        //   resp => {
        //     this.docId = resp["docId"];
        //     if (resp['storageProvider'] == "AWS") {
        //       this.documentService.uploadFileToS3(resp["uploadUrl"], this.fileToUpload)
        //         .subscribe(
        //           resp => {
        //             //reset form
        //             this.documentTitle = null;
        //             for (const data of this.category.meta_data_definition.properties) {
        //               data.value = null;
        //             }
        //             (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
        //             this.uploadMessage = true;
        //             this.toggleUpload = false;
        //           }
        //         ),
        //         error => {
        //           this.alertService.error(error);
        //         }
        //     } else if (resp['storageProvider'] == "Azure") {
        //       this.azureHeader = resp["azureMetadata"]
        //       this.documentService.uploadFileToAzure(resp["uploadUrl"], this.fileToUpload, this.azureHeader)
        //         .subscribe(
        //           resp => {
        //             //reset form
        //             this.documentTitle = null;
        //             for (const data of this.category.meta_data_definition.properties) {
        //               data.value = null;
        //             }
        //             (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
        //             this.uploadMessage = true;
        //             this.toggleUpload = false;
        //           }
        //         ),
        //         error => {
        //           this.alertService.error(error);
        //         }
        //     } else if (resp['storageProvider'] == "GCP") {
        //       console.log("GCP response", resp)
        //       this.documentService.uploadFileToGCP(resp["uploadUrl"], this.fileToUpload)
        //         .subscribe(
        //           resp => {
        //             (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
        //             this.uploadMessage = true;
        //             this.toggleUpload = false;
        //           }
        //         ),
        //         error => {
        //           this.alertService.error(error);
        //         }
        //     }
        //     else {
        //       alert("Invalid Storage Provider! Please try again.")
        //     }
        //   }),
        // error => {
        //   this.alertService.error(error);
        // }
      
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  onClose(): void {
    //this.dialogRef.close();
    (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
    this.openUpload.emit(false)
  }

}
