import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormService } from '../_services/forms.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  searchResultsList: MatTableDataSource<any>;
  searchForm: FormGroup;
  range: FormGroup;
  reportType: string[];
  keyValues: string[];
  noSearchResults: boolean  = false
  docCount: number
  searchResults: Array<JSON> = [];
  searchResultsCols: String[] = [];
  searchValue: any
  dataType: string

  sort;
  @ViewChild(MatSort, { static: false }) set content(content: any) {
    this.sort = content;
    if (this.sort) {
      this.searchResultsList.sort = this.sort;
    }
  }

  paginator;
  @ViewChild(MatPaginator, { static: false }) set pageContent(content: any) {
    this.paginator = content;
    if (this.paginator) {
      this.searchResultsList.paginator = this.paginator;
    }
  }

  constructor(
    private formService: FormService,
  ) { }

  ngOnInit(): void {
    
    this.formService.getKey('Report Type').subscribe(resp=>{
      this.reportType = resp['value']['ReportType']
    })
    this.searchForm = new FormGroup({
      report: new FormControl('', Validators.required),
      search: new FormArray([this.filterForm()]),
      

    })

    this.range = new FormGroup({
      start: new FormControl(''),
      end: new FormControl('')
    });
  }

  filterForm() {
    return new FormGroup({
      keyword: new FormControl('', Validators.required),
      filter: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
    })
  }
  getForm(form) {
    return form.controls.search.controls;
  }

  get formArray() {
    return <FormArray>this.searchForm.get("search")
  }

  deleteForm(index: number) {
    this.formArray.removeAt(index);
  }

  getFieldGlassDependencies(type){
    this.formService.getKey(type).subscribe(resp=>{
      this.keyValues = resp['value']['Keyword']
    })
  }


  addNewForm() {
    const control = <FormArray>this.searchForm.get('search');
    
    control.push(this.filterForm());
  }

  checkEmptySearch(){
    this.searchValue = []
    this.searchForm.value.search.forEach((element,index) => {
      console.log("ELEMENT", element)
      if(element['startDate'] != "" ){
        console.log("START DATE", element['startDate'])
        var startdatesplit = element['startDate'].split("-")
        var startDate = startdatesplit[1]+ "/" + startdatesplit[2] + "/" + startdatesplit[0]
        if(element['endDate'] != ""){
        var enddatesplit = element['endDate'].split("-")
        var endDate = enddatesplit[1]+ "/" + enddatesplit[2] + "/" + enddatesplit[0]
        var filter = startDate + "," + endDate
        element['filter'] = filter
        }
               
      }
      
      //element['keyword'] = element['keyword']['Name']
      if(element['keyword'] == "" || element['filter']=="" || element['keyword'] == undefined ){
       
      }
      else{
        this.searchValue.push(element)
      }

      if(index +1 == this.searchForm.value.search.length){
        console.log("SEARCH", this.searchValue)
        this.searchValue.forEach((search,indexx) => {
          search['keyword'] = search['keyword']['Name']
          delete search['endDate']
          delete search['startDate']

          if(indexx +1 == this.searchValue){
            console.log("SEARCH VALUES", this.searchValue)
          }
        });
        this.search()
      }
    });
  }

  search(){
    this.docCount = 0
    var searchReq = {
      "report": this.searchForm.value.report,
      "search": this.searchValue
    }
  

    this.formService.getReportSearchResults(searchReq)
    .subscribe(resp => {
      this.searchResults = []
      if (JSON.stringify(resp) === '{}') {

        this.noSearchResults = true
        this.docCount = 0
      }
      else if(resp){
        
        this.noSearchResults = false
        this.docCount = resp['result'].length
        this.searchResultsCols =  resp['columns']
        for (const data of resp["result"]) {
          if(data.hasOwnProperty('Phone Number')){
            data['Phone Number'] = data['Phone Number']['e164Number']
          }
        
          this.searchResults.push(data);
        }
        if (this.docCount > 0) {

          this.searchResultsList = new MatTableDataSource(this.searchResults);
          this.searchResultsList.sort = this.sort;
          this.searchResultsList.paginator = this.paginator;
        }
        else {
          this.formService.notify("No search results");
        }
      }
    },
      error => {
        var errObj = JSON.parse(error.error)
        if (error.error['status'] = 400) {
          // this.appError = errObj.message
        }
      });
  }

}
