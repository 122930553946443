import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  // if (environment.production) {
  //   enableProdMode();
  //   const scriptEl = window.document.createElement('script');
  //   scriptEl.src ='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  //   scriptEl.type ='text/javascript';
  //   scriptEl.charset='UTF-8';
  //   scriptEl.setAttribute("data-domain-script","3e2856ae-5731-41f8-9754-8d48fe77196c");
  //   window.document.body.appendChild(scriptEl);
    
  //   }
   
  //   else {   
  //  const scriptEl = window.document.createElement('script');   
  //   scriptEl.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';    
  //   scriptEl.type = 'text/javascript';   
  //   scriptEl.charset = 'UTF-8';
  //   scriptEl.setAttribute("data-domain-script", "3e2856ae-5731-41f8-9754-8d48fe77196c-test");
  //   window.document.body.appendChild(scriptEl);
    
  //   }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
