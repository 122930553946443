<mat-card style = "background-color:#03277c; text-align: center; color: white">
    <strong>{{title}}</strong>
</mat-card>
<div class = "row">
    <div class="input-group-sm mt-2 input-group col-4">
        <input class="form-control" type="text" (keyup)="applyFilterOnFaq($event)"
            placeholder="Filter">
    </div>
    <div class="input-group-sm mt-2 input-group col-8" style="float:right; padding-left:43%; font-size:small">
<strong><i>Have more questions? Click <a [href]="mailText">here</a> to drop us an email.</i></strong>
    </div>
</div>
<mat-accordion [displayMode]="displayMode" [multi]="multi" class="faq-container">
    <mat-expansion-panel *ngFor="let faqItem of faqListSearch" style="font-size:small;">
        <mat-expansion-panel-header style="font-size:small;"><strong>{{faqItem.question}}</strong></mat-expansion-panel-header>
        <p style="font-size:small;">{{faqItem.answer}}</p>
      </mat-expansion-panel>
</mat-accordion>