import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';

//import { AuthenticationService } from '@/_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
        private alertService: AlertService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            //this.alertService.clear()
            //if (err.status === 401) {
                // try to get new token refresh
            //    this.authenticationService.logout();
            //    location.reload(true);
            //}
            if (err.status === 400) {
                var errMsg = err.error
                //console.log('sfdadada ' + errMsg['message']);
                var msg = errMsg['status_code'] + ": " + errMsg['message'] 
                //console.log("Error from interceptor " + JSON.stringify(err.error['status_code']));
                this.alertService.error(msg);
            }
            if (err.status === 500) {
                var errMsg = err.error
                var msg = errMsg['status_code'] + ": " + errMsg['message'] 
                this.alertService.error(msg);               
            }
            
            //console.log("from error interceptor " + JSON.stringify(err))
            return throwError(err);
        }))
    }
}