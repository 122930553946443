import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SsoLoginComponent implements OnInit {

  request: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {

        this.router.navigate(['/home']);
    } else {

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.router.url.indexOf("access_token") != -1) {
                    this.setUserDetails();
                    //this.router.navigate(['/']);
                }
            }
        });
    }
}


  ngOnInit() {
    console.log("SSO URL")
    if (!this.authenticationService.currentUserValue) {
        
      window.location.assign(`${environment.cognitoSignInUrl}`);
  }
  }

  setUserDetails() {
    let splitString = this.router.url.split('#');
    let accessDetails = splitString[1].split('&');
    let accessToken = accessDetails[0].split('=');
    let expiryTime = accessDetails[1].split('=');
    this.request = {
        "token": accessToken[1],
        "expiryTime": expiryTime[1]
    }
    this.authenticationService.getUserDetails(this.request)
        .subscribe(
            data => {
                this.router.navigate(['/']);
            },
            error => {
                console.log("Error " + error)
                if (String(error).includes('.throw is not a function')) {
                    console.log("Called")
                    this.alertService.error('ERROR: Could not connect to network. Please make sure you are connected to Deloitte VPN and relaunch the application.');
                  } else {
                    this.alertService.error(String(error));
                }
                var errMsg = error.error
            });
}


}
