import { Component, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree'
import { first } from 'rxjs/operators';
import { isEqual } from 'lodash'
import { User, CategoryNode } from '../../_models';
import { AuthenticationService } from '../../_services/authentication.service';
import { CategoryService } from '../../_services/category.service';
import { UserService } from '../../_services/user.service'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators, FormArray, FormGroup, Form } from '@angular/forms';
import { UsersAndGroupsService } from 'src/app/_services/users-and-groups.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormService } from 'src/app/_services/forms.service';
import { stringify } from 'querystring';
import { deepEqual, deepStrictEqual } from 'assert';


interface CategoryFlatNode {
  expandable: boolean;
  id: number;
  name: string;
  level: number;
  users: [];
}


@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  searchCtrl: string = "";
  isAdmin: boolean = false;
  metaDataValue: FormGroup
  metaDataPropertyForm: FormGroup
  optionValueForm: FormGroup
  metaDataForm: FormGroup
  updateMetaDataForm: FormGroup
  currentUser: User;
  response: {};
  categories = [];
  form = {};
  toggleCheck: Boolean
  fetchedFormName: String;
  docResultsList: MatTableDataSource<any>;
  selectedFormNew: any = [];
  selectedForm: Boolean = false;
  formId: String;
  formName: String;
  catname: string;
  metaDataProperties = [];
  metaData = [];
  usersList = [];
  addFormButton: Boolean = false;
  dropdownSettings = {};
  newMeta = [];
  newMetaDataProperties = [];
  updateFormProperties: any;
  fetchedParentId: number;
  addFormDataPerm: string[] = [];
  getFormDataPerm: string[] = [];
  compliancePerm: string[] = [];
  updateFormDataPerm: string[] = [];
  deleteFormDataPerm: string[] = [];
  createFormPerm: string[] = [];
  deleteFormPerm: string[] = [];
  updateFormPerm: string[] = [];
  getFormPerm: string[] = [];
  errorMsg = '';
  createdForm: boolean = false;
  updatedForm: boolean = false;
  navBarCategories
  filterValue
  userGroups: string[]
  isSubForm: boolean = false
  isOptions: boolean = false
  sort;
  copiedGroups: string[]
  commonPermissions: string[]
  permissionsList: string[] = ["Add Form Data", "Update Form Data", "Delete Form Data", "Get Form Data", "Get Form", "Create Form", "Get Form", "Update Form", "Delete Form", "Compliance"]
  permissionName: string
  cloneChild: boolean = false
  isDeletedForm: boolean = false
  forms: any;
  keys: any;
  // ObjectMapper mapper = new this.ObjectMapper();


  private _transformer = (node: CategoryNode, level: number) => {
    return {
      expandable: !!node.sub_categories && node.sub_categories.length > 0,
      id: node.id,
      name: node.name,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<CategoryFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.sub_categories);

  categoryTreeData = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  constructor(
    private authenticationService: AuthenticationService,
    private categoryService: CategoryService,
    private userService: UserService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private formService: FormService,
    private usersGroupsService: UsersAndGroupsService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.userGroups = this.authenticationService.userGroupsValue;
    this.addNewForm();
    this.updateMetaDataForm = this.fb.group({
      Name: ['', Validators.required],
      updateFormProperties: this.fb.array([this.getformProperties()]),
      Permissions: this.fb.group({
        add_form_data: ['', Validators.required],
        update_form_data: ['', Validators.required],
        get_form_data: ['', Validators.required],
        delete_form_data: ['', Validators.required],
        create_form: ['', Validators.required],
        update_form: ['', Validators.required],
        get_form: ['', Validators.required],
        delete_form: ['', Validators.required]
      })
    })
  }
  hasChild = (_: number, node: CategoryFlatNode) => node.expandable;

  ngOnInit(): void {


    this.filterValue = null
    this.categoryService.getAll().subscribe(categories => {
      this.categories = categories['results']
        })


    this.formService.getAllKeys().subscribe(keys=>{
      this.keys = keys
    }
      
      )

    this.formService.getAll().subscribe(forms=>{
        this.forms = forms['results']
      }
        
        )
    this.userService.getAll().pipe(first()).subscribe(usersList => { this.usersList = usersList; });
    this.dropdownSettings = {
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
    }

    this.userGroups.forEach(group => {
      if (group.match("dms-admin")) {
        this.isAdmin = true
      }
    })
  }

  copyGroups(groups, permissionName) {
    this.copiedGroups = groups
    this.permissionName = permissionName
  }

  closeCopyGroups() {
    this.copiedGroups = null
  }

  cloneGroups() {
    this.commonPermissions.forEach(permission => {
      switch (permission) {
        case "Add Form Data":
          this.copiedGroups.forEach(group => {
            this.addFormDataPerm.push(group)
          })
          this.addFormDataPerm = this.addFormDataPerm.filter((elem, i, arr) => {
            if (arr.indexOf(elem) === i) {
              return elem
            }
          })
          break;
        case "Update Form Data":
          this.copiedGroups.forEach(group => {
            this.updateFormDataPerm.push(group)
          })
          this.updateFormDataPerm = this.updateFormDataPerm.filter((elem, i, arr) => {
            if (arr.indexOf(elem) === i) {
              return elem
            }
          })
          break;
        case "Delete Form Data":
          this.copiedGroups.forEach(group => {
            this.deleteFormDataPerm.push(group)
          })
          this.deleteFormDataPerm = this.deleteFormDataPerm.filter((elem, i, arr) => {
            if (arr.indexOf(elem) === i) {
              return elem
            }
          })
          break;
        case "Get Form Data":
          this.copiedGroups.forEach(group => {
            this.getFormDataPerm.push(group)
          })
          this.getFormDataPerm = this.getFormDataPerm.filter((elem, i, arr) => {
            if (arr.indexOf(elem) === i) {
              return elem
            }
          })
          break;
        case "Get Form":
          this.copiedGroups.forEach(group => {
            this.getFormDataPerm.push(group)
          })
          this.getFormDataPerm = this.getFormDataPerm.filter((elem, i, arr) => {
            if (arr.indexOf(elem) === i) {
              return elem
            }
          })
          break;
        case "Create Form":
          this.copiedGroups.forEach(group => {
            this.createFormPerm.push(group)
          })
          this.createFormPerm = this.createFormPerm.filter((elem, i, arr) => {
            if (arr.indexOf(elem) === i) {
              return elem
            }
          })
          break;
        case "Get Form":
          this.copiedGroups.forEach(group => {
            this.getFormPerm.push(group)
          })
          this.getFormPerm = this.getFormPerm.filter((elem, i, arr) => {
            if (arr.indexOf(elem) === i) {
              return elem
            }
          })
          break;
        case "Update Form":
          this.copiedGroups.forEach(group => {
            this.updateFormPerm.push(group)
          })
          this.updateFormPerm = this.updateFormPerm.filter((elem, i, arr) => {
            if (arr.indexOf(elem) === i) {
              return elem
            }
          })
          break;
        case "Delete Form":
          this.copiedGroups.forEach(group => {
            this.deleteFormPerm.push(group)
          })
          this.deleteFormPerm = this.deleteFormPerm.filter((elem, i, arr) => {
            if (arr.indexOf(elem) === i) {
              return elem
            }
          })
          break;
      }
    })
  }

  addNewForm() {
    this.metaDataForm = this.fb.group({
      Name: ['', Validators.required],
      formProperties: this.fb.array([this.getformProperties()]),
      Permissions: this.fb.group({
        add_form_data: ['', Validators.required],
        update_form_data: ['', Validators.required],
        get_form_data: ['', Validators.required],
        delete_form_data: ['', Validators.required],
        create_form: ['', Validators.required],
        update_form: ['', Validators.required],
        get_form: ['', Validators.required],
        delete_form: ['', Validators.required]
      })
    })
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    if (this.filterValue.length > 0) {
      this.navBarCategories = []
      this.search()
    }
    else {
      this.navBarCategories = this.categories
    }
  }

  search() {
    this.categories.filter(form => {
      if ((form.name.trim().toLowerCase().indexOf(this.filterValue.trim().toLowerCase()) > -1)) {
        this.navBarCategories.push(form)
      }
      if (form.sub_categories) {
        form.sub_categories.filter(element => {
          if ((element.name.trim().toLowerCase().indexOf(this.filterValue.trim().toLowerCase()) > -1)) {
            this.navBarCategories.push(form)
          }
        });
      }
      this.navBarCategories = this.navBarCategories.filter((elem, i, arr) => {
        if (arr.indexOf(elem) === i) {
          return elem
        }
      })
    }
    )
    this.categoryTreeData.data = this.navBarCategories
  }

  activateAddSubForm() {
    this.reset()
    this.metaDataForm.reset()
    this.isSubForm = true
    this.addFormButton = true;
    this.selectedForm = true;
    this.cloneChild = false;
  }

  activateAddForm(cloneForm: boolean) {
    if (!cloneForm) {
      this.metaDataForm.reset()
      this.reset()
    }
    this.isSubForm = false
    this.addFormButton = true;
    this.selectedForm = false;
  }

  

  private getCategoriesTree() {
    this.categoryService.getTree()
      .pipe(first())
      .subscribe(
        categories => {
          this.categories = categories;
          this.categoryTreeData.data = categories
        },
        error => {}
      );
  }

  cloneForm(formDetails) {
    console.log(formDetails)
    this.formPropertiesArray.controls = this.updateFormPropertiesArray.controls
    if (formDetails.parent_id == 0) {
      this.cloneChild = false
    } else {
      this.cloneChild = true
      this.formId = formDetails.parent_id
    }
    this.activateAddForm(true)
  }

  dropUpdate(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.updateFormPropertiesArray.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.updateMetaDataForm.value.updateFormProperties, event.previousIndex, event.currentIndex);

  }

  dropCreate(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.formPropertiesArray.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.metaDataForm.value.formProperties, event.previousIndex, event.currentIndex);

  }

  selectForm(name, id) {
    this.createdForm = false
    this.updatedForm = false
    this.selectedForm = true;
    this.addFormButton = false;
    this.formId = id;
    this.formName = name;
    this.reset();
    this.formService.get(this.formId).subscribe(form => {
      this.form = form;
      this.metaDataProperties = this.form['form_field_definition']['properties'];
      this.formName = this.form['name']
      this.fetchedFormName = this.form['name'];
      this.setPermissions(this.form['permissions'])
      
      this.fetchedParentId = this.form['parent_id']
      this.updateMetaDataForm.setControl('updateFormProperties', this.setExistingProperties(this.metaDataProperties));
    });
  }

  setPermissions(permissions) {
    this.addFormDataPerm = Object.assign([], permissions['add_form_data'])
    this.getFormDataPerm = Object.assign([], permissions['get_form_data']);
    this.updateFormDataPerm = Object.assign([], permissions['update_form_data']);
    this.deleteFormDataPerm = Object.assign([], permissions['delete_form_data']);
    this.getFormPerm = Object.assign([], permissions['get_form']);
    this.createFormPerm = Object.assign([], permissions['create_form']);
    this.updateFormPerm = Object.assign([], permissions['update_form']);
    this.deleteFormPerm = Object.assign([], permissions['delete_form']);
  }

  setExistingProperties(propertiesSet: any[]): FormArray {
    const formArray = new FormArray([]);
    propertiesSet.forEach(p => {
      formArray.push(this.fb.group({
        key: p.key,
        name: [p.name, Validators.required],
        required: p.required,
        data_type: [p.data_type, Validators.required],
        category_id: Number(p.category_id),
        is_document: p.is_document,
        display_in_list: p.display_in_list,
        is_databaselist: p.is_databaselist
        
        
        
      }));
    })
    return formArray;

    
  }

  permissions() {
    var perms = {
      "permissions": {
        "update_form": this.updateFormPerm,
        "get_form": this.getFormPerm,
        "create_form": this.createFormPerm,
        "delete_form": this.deleteFormPerm,
        "add_form_data": this.addFormDataPerm,
        "update_form_data": this.updateFormDataPerm,
        "get_form_data": this.getFormDataPerm,
        "delete_form_data": this.deleteFormDataPerm
      }
    }
    return perms.permissions;
  }

  createNewForm() {

    this.createdForm = false
    this.updatedForm = false
    this.fetchedFormName = this.catname
    this.metaData = [
      {
        "properties": this.newMeta
      }
    ]



    var newCatReq = {
      "name": this.catname,
      "form_field_definition":this.metaData,
      "permissions": this.permissions(),
    }
    console.log("NEW REQ", newCatReq)
    this.formService.createForm(newCatReq)
      .subscribe(resp => {
        this.createdForm = true
        this.formId = resp;
      })
  }

  createNewSubForm() {
    this.fetchedFormName = this.catname
    this.metaData = [
      {
        "properties": this.newMeta
      }
    ]
    var newSubCatReq = {
      "name": this.catname,
      "form_field_definition": this.metaData,
      "permissions": this.permissions()
    }
    this.formService.createForm(newSubCatReq)
      .subscribe(resp => {
        this.createdForm = true
        this.formId = resp;
        this.ngOnInit()
        this.reset()
      },
        error =>
          console.log("Error Message: " + error.error[0])
      )
  }


  reset() {
    this.addNewForm();
    this.addFormDataPerm = [];
    this.getFormDataPerm = [];
    this.compliancePerm = [];
    this.deleteFormDataPerm = [];
    this.getFormDataPerm = [];
    this.getFormPerm = [];
    this.createFormPerm = [];
    this.deleteFormPerm = [];
    this.updateFormPerm = [];
    this.updateFormDataPerm = [];
    this.catname = null
    this.copiedGroups = null
  }

  setUpdateFormValue() {
    let control = <FormArray>this.updateMetaDataForm.controls.updateFormProperties;
    this.updateFormProperties.forEach(element => {
      control.push(this.fb.group({ name: element.name, required: element.required, data_type: element.data_type, is_databaselist: element.is_databaselist, is_document: element.is_document }))
    });

  }

  getCategory(event, formId) {
    this.selectedFormNew = this.categories.find(x => x.id == formId)
  }

  saveFormData(data) {
    this.newMeta = data['formProperties'];
    this.newMeta.forEach(element => {
      if(element['is_document']==true){
        var category = element['category_id']
        element['category_id'] = String(category)
      }
      
      if(element['is_document']==false){
        delete element['category_id']
      }

      if(element['is_databaselist']==false){
        delete element['key']
      }
      if (element.required == null) {
        element.required = false
      }
      if (element.display_in_list == null) {
        element.display_in_list = false
      }
      if (element.is_document == null) {
        element.is_document = false
      }
      if (element.is_databaselist == null) {
        element.is_databaselist = false
      }

      
    })
    return this.createNewForm();
  }

  deleteMetaDataForm(index: number) {
    this.formPropertiesArray.removeAt(index);
  }
  deleteOptionForm(index: number) {
    this.optionValueArray.removeAt(index);
  }

  applyFilterOnForm(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    if (this.filterValue.length > 0) {
      this.navBarCategories = []
      this.search()
    }
    else {
      this.categoryTreeData.data = this.categories
    }
  }

  deleteUpdateMetaDataForm(index: number) {
    this.updateFormPropertiesArray.removeAt(index);
  }

  saveSubFormData(data) {
    this.newMeta = data['formProperties'];
    this.newMeta.forEach(element => {
      if(element['is_document']==true){
        var category = element['category_id']
        element['category_id'] = String(category)
      }
      
      if(element['is_document']==false){
        delete element['category_id']
      }

      if(element['is_databaselist']==false){
        delete element['key']
      }
      if (element.required == null) {
        element.required = false
      }
      if (element.display_in_list == null) {
        element.required = false
      }
      if (element.is_document == null) {
        element.is_document = false
      }
      if (element.is_databaselist == null) {
        element.is_databaselist = false
      }

    })
    return this.createNewSubForm();
  }

  saveUpdateFormData(data) {
    data['updateFormProperties'].forEach(element => {
      if(element['is_document']==true){
        var category = element['category_id']
        element['category_id'] = String(category)
      }
      
      if(element['is_document']==false){
        delete element['category_id']
      }

      if(element['is_databaselist']==false){
        delete element['key']
      }
      
    });
    this.newMetaDataProperties = data['updateFormProperties']
    return this.updateForm();
  }

  get formPropertiesArray() {
    return <FormArray>this.metaDataForm.get("formProperties");
  }

  get optionValueArray() {
    return <FormArray>this.metaDataPropertyForm.get("value");
  }



  get updateFormPropertiesArray() {
    return <FormArray>this.updateMetaDataForm.get("updateFormProperties");
  }

  getformProperties(): FormGroup {
    return this.metaDataPropertyForm = this.fb.group({
      key: [''],
      "name": ['', Validators.required],
      required: true,
      data_type: ['', Validators.required],
      category_id: [''],
      is_document: false,
      display_in_list: true,
      is_databaselist: false
      
      
      

    })
  }

  getValues(): FormGroup {
    return this.metaDataValue = this.fb.group({
      values: ['', Validators.required]
    })
  }


  optionProperties() {
    return this.optionValueForm = this.fb.group({
      value: ['']
    })
  }

  onChange(dataType) {
    console.log(dataType);
    if (dataType === "options") {
      this.isOptions = true
    }
    else {
      this.isOptions = false
    }
  }

  addNewUpdateProperty() {
    this.updateFormPropertiesArray.push(this.getformProperties())
  }

  addNewProperty() {

    this.formPropertiesArray.push(this.getformProperties());
  }

  addNewValue() {
    this.optionValueArray.push(this.optionProperties())
  }
  removeProperty(index) {
    this.formPropertiesArray.removeAt(index)
  }


  updateForm() {
    var updateCatReq1 = {
      "Id": this.formId,
      "name": this.fetchedFormName,
      "form_field_definition":[ {
        "properties": this.newMetaDataProperties
      }],
      "permissions": this.permissions()
    }

    var updateCatReq2 = {
      "Id": this.formId,
      "name": this.fetchedFormName,
      "permissions": this.permissions()
    }

    //console.log( _.isEqual(updateCatReq2, updateCatReq1) );
 
    if (JSON.stringify(this.newMetaDataProperties) == JSON.stringify(this.metaDataProperties)) {
      this.formService.updateForm(updateCatReq2)
        .subscribe(resp => {
          this.response = resp;
          this.updatedForm = true
          this.formName = this.fetchedFormName
        },
          error =>
            console.log("Error Message: " + error),


        )
    }
    else {
      this.formService.updateForm(updateCatReq1)
    
        .subscribe(resp => {
          this.updatedForm = true
          this.response = resp;
          this.formName = this.fetchedFormName
        },
          error =>
            console.log("Error Message: " + error)
        )
    }
  }

  back() {
    if(this.updatedForm){      
      this.updatedForm = false;
      this.selectForm(this.formName, this.formId)
    }
    if(this.createdForm){
      this.addFormButton = false
      this.selectedForm = false;
      this.isDeletedForm = false;
      this.createdForm = false;
      this.ngOnInit()
    }
    
    
  }

  refreshPage() {
    location.reload();
  }



  deleteForm(formId) {
    // let dialogRef = this.dialog.open(DialogDeleteConfirmComponent,
    //   { width: '500px', data: { type: "Delete Form", payload: { Id: formId.toString() } } });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     console.log("DELETED")
    //     this.ngOnInit()
    //     this.formName = this.fetchedFormName
    //     this.isDeletedForm = true
    //   }
    // })
  }
}
