<div>
    <div *ngIf="category" class="card m-2">
        <div class="ml-3" *ngIf="toggleUpload">
            <!-- <mat-divider></mat-divider> -->
            <form #initiateForm="ngForm">
                <!-- <mat-form-field appearance="standard">
                    <mat-label>Document Title</mat-label>
                    <input matInput name="documentTitle" [(ngModel)]="documentTitle" required>
                </mat-form-field> -->
                <div class="row" style="height:calc(100vh-93px);" *ngIf="fileToUpload">
                    <div class="col-8">
                        <div mat-dialog-title style="font-size: medium;">Preview</div>
                        <app-file-preview [filePath]="filePath"></app-file-preview>
                        <button class="mb-3 mr-2 closeButton mt-3" style="float:right" mat-raised-button (click)="onClose()">Discard</button>
                            <button class="mb-3 mr-2 mt-3" style="float:right; background-color: #B5D9F3" mat-raised-button
                             type="submit"
                                (click)="uploadDocument()">Submit</button>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="uploadMessage">
            <div class="mt-2" style="text-align: center;">File Upload has been completed successfully.</div>
            <button style="margin: 1em; float:right" mat-raised-button (click)="onClose()">Close</button>
        </div>


    </div>
</div>