import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentService } from 'src/app/_services/document.services';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss']
})
export class FileViewComponent implements OnInit {

  @Input() documentID: String;
  @Input() versionNumber: number
  @Input() documentType: any

  appError: String = null;

  // ngOnChanges(changes: SimpleChanges) {
  //   //this.documentID = changes.documentID.currentValue;
  //   this.viewDocument();

  // }

  fileUrl: string;
  imageViewer: boolean = false;
  googleViewer: boolean = false;
  archive: boolean = false;
  restore: boolean = false;

  constructor(
    private documentSvc: DocumentService,
    private snackBar: MatSnackBar
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.documentID = changes.documentID.currentValue;
    //this.fetchServiceData();
    if(changes.documentID.currentValue != changes.documentID.previousValue){
      this.ngOnInit()
    }

  }

  ngOnInit(): void {
    this.viewDocument();
  }

  viewDocument() {
    this.appError = null;
    var googleDriveTypes = ["application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.openxmlformats-officedocument.wordprocessing", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]
    var imageViewerTypes = ["image/tiff", "application/pdf", "image/png", "image/jpeg", "image/jpg"]
    this.documentSvc.viewVersionDocument(this.documentID, this.versionNumber)
      .subscribe(
        resp => {
          if (JSON.stringify(resp).includes("Document is in Glacier storage. Please request for retrieval")) {
            this.archive = true;
          } else if(JSON.stringify(resp).includes("Restoration has been requested. Please check status")) {
            this.restore = true;
          } else {
            var url = resp.slice(5);
            if (resp.includes("google")) {
              this.fileUrl = "https" + url;
            } else {
              this.fileUrl = url;
            }
            
            if (googleDriveTypes.indexOf(this.documentType.toString()) !== -1) {

              this.fileUrl = "https://docs.google.com/viewer?url="
              this.fileUrl += encodeURIComponent(url)
              this.fileUrl += "&embedded=true"
            this.googleViewer = true;
            }

            // if (imageViewerTypes.indexOf(this.documentType.toString()) !== -1) {
            //   this.imageViewer = true
            // }

            //}

            console.log("View document " + this.fileUrl);
          }
        },
        error => {
          //console.log("Error " + error.error);
          var errObj = JSON.parse(error.error)
          if (error.error['status'] = 400) {
            this.appError = errObj.message
          }
        });
  }

  retrieveGlacierDocument() {
    
    var request = {
      "docid" : [this.documentID]
    }
    this.documentSvc.retrieveGlacierDocuments(request).subscribe(
      response => { 
        this.snackBar.open("Restoration request for the selected document is placed successfully. The restore status can be tracked from 'Glacier' tab", "Close", {
          duration: 20000
        });
      });
  }


}
