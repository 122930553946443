import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Category, CategoryNode, NewCategory } from '../_models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from './authentication.service';

const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Basic d2JhZG1pbjp3YmFkbWlu'
            }),
        }; 

//PAYPAL WORKFLOW CONTAINER AND PROCESS ID

const workflowContainerID = `onboarding_workflow-kjar-1_0-SNAPSHOT`
const workflowProcessID = `onboarding_workflow-kjar.PayPalOnboardingWorkflow`
const workflowJBPMServerUrlInitiate = `${environment.formUrl}`+`/rest/server/containers/`+workflowContainerID+`/processes/`+workflowProcessID+`/instances`

//const workflowJBPMServerUrlTask = "http://18.219.3.223:8090/rest/server/queries/tasks/instances/variables/Owner?varValue=testuser4&status=Created&status=Ready&status=Reserved&status=InProgress&pageSize=10&sortOrder=true"
@Injectable({ providedIn: 'root' })
export class FormService {
    constructor(private http: HttpClient,
        private authenticationService: AuthenticationService,
        private snackBar: MatSnackBar,) { }

    getTask(email): Observable<any> {
        return this.http.get<any>(`${environment.formUrl}/tasks/gettask/`+email)
            .pipe(
            );
    }

    getAllTassks(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.formUrl}/tasks/getalltasks`);
    }

    getTasksQueues(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.formUrl}/tasks/getqueue/PayPal_Onboarding_Requests@deloitte.com`);
    }


    getReportSearchResults(searchReq: any): Observable<any> {
        var url = `${environment.apiUrl}/reports`;
    
        return this.http.post<any>(url, searchReq)
          .pipe(
            //catchError(err => this.handleError<any[]>('getSearchResults', []))
          );
      }

      notify(message: string): void {
        this.snackBar.open(message, "", { duration: 5000 });
      }




    initateOnboarding(req: any): Observable<any> {
        return this.http.post(`${workflowJBPMServerUrlInitiate}`, req).pipe(map(obj => { }),
            (catchError(this.errorHandler))

        )
    }
    onBoardingEligible(user): Observable<Category> {
        return this.http.get<Category>(`${environment.formUrl}/users/onboardingeligible` + user)
            .pipe(
            );
    }

    migration(req: any): Observable<any> {
        let currentUser = this.authenticationService.currentUserValue;
        const httpOptions = {
            headers: new HttpHeaders({
              'Authorization': `Bearer ${currentUser.token}`,
              "dms-user": currentUser.username,
              "accept": "application/pdf, application/json, text/plain, */*"
            }),
            observe: 'response' as 'body'
          };
        return this.http.post<any>(`${environment.apiUrl}/migration`, req, httpOptions)
                .pipe()
    }
    
    getWorkflowStatus(user): Observable<Category> {
        return this.http.get<Category>(`${environment.formUrl}/profile/status/` + user)
            .pipe(
            );
    }

    populateForm(req: any): Observable<any> {
        return this.http.post<any>(`${environment.formUrl}/profile/populate`, req)
            .pipe(
               )
    }

    getAll(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.formUrl}/form?offset=0&limit=1000`);
    }

    get(formId): Observable<Category> {
        return this.http.get<Category>(`${environment.formUrl}/form/` + formId)
            .pipe(
            );
    }

    getFormName(name): Observable<Category> {
        return this.http.get<Category>(`${environment.formUrl}/form/name/` + name)
            .pipe(
            );
    } 
    getProfile(formUUID): Observable<Category> {
        return this.http.get<Category>(`${environment.formUrl}/profile/data/` + formUUID)
            .pipe(
            );
    } 
    getAllKeys(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.formUrl}/form/key`);
    }

    getKey(key): Observable<any[]> {
        return this.http.get<any[]>(`${environment.formUrl}/form/keyvalue/`+key);
    }

    createForm(req: any): Observable<any> {
        return this.http.post(`${environment.formUrl}/form/create`, req).pipe(map(obj => { }),
            (catchError(this.errorHandler))

        )
    }

    claimTaskCheck(taskID): Observable<any[]> {
        return this.http.get<any[]>(`${environment.formUrl}/rest/server/containers/onboarding_workflow-kjar-1_0-SNAPSHOT/tasks/`+taskID+`?withInputData=true`, httpOptions);
    }

    claimTask(req: any, taskID): Observable<any> {
        return this.http.put<any>(`${environment.formUrl}/rest/server/containers/onboarding_workflow-kjar-1_0-SNAPSHOT/tasks/`+taskID, req, httpOptions)
            .pipe(
                map(obj => { }

                )
            )
    }

    updateForm(req: any): Observable<any> {
        return this.http.put<any>(`${environment.formUrl}/form/update`, req)
            .pipe(
                map(obj => { }

                )
            )
    }

    submitNewResourceWorkflow(req: any, taskID): Observable<any> {
        console.log("HERE")
        return this.http.put<any>(`${environment.formUrl}/rest/server/containers/`+workflowContainerID+`/tasks/`+taskID+`/states/completed?auto-progress=true`, req, httpOptions)
            .pipe(
                map(obj => { }

                )
            )
    }

    changeWorkFlowStatus(profileID): Observable<any[]> {
        return this.http.get<any[]>(`${environment.formUrl}/profile/workflowstatus/`+ profileID);
    }

    
    errorHandler(error: HttpErrorResponse) {
        return throwError(error);
    }
}