import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CategoryService } from 'src/app/_services/category.service';
import { DocumentService } from 'src/app/_services/document.services';
import { FormService } from 'src/app/_services/forms.service';
import { UserService } from 'src/app/_services/user.service';
import { UsersAndGroupsService } from 'src/app/_services/users-and-groups.service';
import { AlertService } from 'src/app/_services/alert.service';
import { SearchService } from 'src/app/_services/search.service';
import { DatePipe } from '@angular/common';
import { DialogConfirmUploadComponent } from 'src/app/dialog-confirm-upload/dialog-confirm-upload.component';
import { DialogViewDocComponent } from 'src/app/dialog-view-doc/dialog-view-doc.component';
import { DialogFilePreviewComponent } from '../dialog-file-preview/dialog-file-preview.component';
import { DialogErrorComponent } from 'src/app/dialog-error/dialog-error.component';

@Component({
  selector: 'app-background-form',
  templateUrl: './background-form.component.html',
  styleUrls: ['./background-form.component.scss']
})
export class BackgroundFormComponent implements OnInit {

  @Input() resourceDetails = new EventEmitter<any>()
  currentUser: User
  form: any
  resourceForm: FormGroup
  values: any[];
  properties: any;
  BAF: File = null;
  docId: any
  NDAdocId: any
  BAFdocId: any
  CSPdocId: any
  AUPdocId: any
  sampleBGForm: any
  projectStartDate: any
  sampleBGFormFilled: any
  BAFUploaded: boolean = false
  isPPMDApprovalRequired: boolean = false
  isNewResourceSubmitted: boolean = false
  minDate: any;
  maxDate: any;
  newDate: any;
  projDate: any;
  latestDate: any
  currentDate: any
  BAFLoaded: boolean = false
  propertyValues: any
  BAFCategory: string
  formSaveMessage: boolean = false

  @Output() hideNewResource = new EventEmitter<boolean>();

  constructor(    
    private documentService: DocumentService,
    private authenticationService: AuthenticationService,
    public datepipe: DatePipe,
    private categoryService: CategoryService,
    private userService: UserService,
    private apiSvc: SearchService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private alertService: AlertService,
    private formService: FormService,
    private usersGroupsService: UsersAndGroupsService,) {

      this.currentUser = this.authenticationService.currentUserValue
     }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    console.log("Resource Details", this.resourceDetails)
    

    var searchReq = {
      "searchformId": 2,
      "offset": 0,
      "limit": 200,
      "storage_class": "S3",
      "criteria": [
        {
          "bindingName": "form",
          "value": "Background",
          "case_insensitive" : false
      }  
      ]
    };
    var searchReq2 = {
      "searchformId": 2,
      "offset": 0,
      "limit": 200,
      "storage_class": "S3",
      "criteria": [
        {
          "bindingName": "form",
          "value": "Background-Filled",
          "case_insensitive" : false
      }  
      ]
    };
    this.apiSvc.getSearchResults(searchReq).subscribe(resp => {

      this.sampleBGForm = resp['results'][0]
    })
    this.apiSvc.getSearchResults(searchReq2).subscribe(resp => {

      this.sampleBGFormFilled = resp['results'][0]
    })


    this.formService.getProfile(this.resourceDetails['profileid']).subscribe(fields=>{

      this.formService.getFormName('Background Check Form').subscribe(form=>{

        this.form = form
        this.checkDocumentCategory()
        this.propertyValues = Object.assign(fields['form_value']['properties'])
        this.values = Object.assign(fields['form_value']['properties']) 
        if(this.values['Background Attestation Form'] != null){
          this.BAFdocId = this.values['Background Attestation Form']
          this.BAFLoaded = true
        }
        this.values['Resource Project Start Date'] = this.datepipe.transform(this.values['Resource Project Start Date'], 'yyyy-MM-dd')
        this.values['Background Check Date'] = this.datepipe.transform(this.values['Background Check Date'], 'yyyy-MM-dd')
        var projDate = new Date(fields['form_value']['properties']['Resource Project Start Date'])
        var newDate = new Date( projDate || new Date() );
        this.projDate = String(this.datepipe.transform(newDate, 'yyyy-MM-dd'))
        newDate.setMonth( newDate.getMonth() - (6 || 0), newDate.getDate());
        let minDate =this.datepipe.transform(newDate, 'yyyy-MM-dd');
        
      
          this.latestDate = String(minDate)
        

        this.properties = {}
      } 
        )
    })

      
  

  }

  back() {
    this.hideNewResource.emit(false);
  }

  handleFileInput(files: FileList, element) {
      if(element == "Background Attestation Form"){
      this.BAFLoaded = true
      
      this.BAF = files.item(0);

      let dialogRef = this.dialog.open(DialogFilePreviewComponent,
        { width: '600px', height:'80%', data: { type: element, payload: element, file:this.BAF }, panelClass: 'custom-modalbox' });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {

            this.uploadDocument(element,this.BAF)
            // this.ngOnInit()
            
          }
        })
    }    
  }

  downloadSample(){
    this.documentService.downloadPdf(this.sampleBGForm.documentId)
      .then(blob=> {
         saveAs(blob, this.values['Resource Last Name']+"_"+this.values['Resource First Name']+"_"+this.sampleBGForm.documentName+'.pdf');
      })
  }

  checkDocumentCategory(){
    this.form.form_field_definition.properties.forEach(element => {
      if(element.name == "Background Attestation Form"){
        this.BAFCategory = element.category_id
      }
    });
  }

  downloadSampleFilled(){
    this.documentService.downloadPdf(this.sampleBGFormFilled.documentId)
    .then(blob=> {
      saveAs(blob, this.values['Resource Last Name']+"_"+this.values['Resource First Name']+"_"+this.sampleBGFormFilled.documentName+'.pdf');
    })
  }

  saveForm(action){
    this.values['Email'] = this.values['Email'].trim().toLowerCase()

    if (this.values['Background Check Date'] == '') {
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: {message: "Please make sure the background check date is valid!" }, panelClass: 'custom-modalbox' });
      return;
  }

    if (this.values['Background Check Date'] > this.currentDate) {
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: {message: "Please refrain from entering a future background check date!" }, panelClass: 'custom-modalbox' });
      return;
  }
  if (this.values['Background Check Date'] < this.latestDate) {
    let dialogRef = this.dialog.open(DialogErrorComponent,
      { width: '50%', height:'25%', data: {message: "Please refrain from entering a date less than 6 months from the project start date!" }, panelClass: 'custom-modalbox' });
    return;
}
if (this.values['Background Check Date'] > this.values['Resource Project Start Date']) {
  let dialogRef = this.dialog.open(DialogErrorComponent,
    { width: '50%', height:'25%', data: {message: "Please refrain from entering a background check date greater than project start date!" }, panelClass: 'custom-modalbox' });
  return;
}
  if (this.values['Resource Project Start Date'] < this.currentDate) {
    let dialogRef = this.dialog.open(DialogErrorComponent,
      { width: '50%', height:'25%', data: {message: "Please make sure resource project start date is valid!" }, panelClass: 'custom-modalbox' });
    return;
}
  if (this.values['Resource Project Start Date'] > "2050-01-01") {
    let dialogRef = this.dialog.open(DialogErrorComponent,
      { width: '50%', height:'25%', data: {message: "Please enter a valid resource project date!" }, panelClass: 'custom-modalbox' });
    return;
}
this.form.form_field_definition.properties.forEach((obj, index) => {
   if (obj.data_type === "date") {
    if (this.values[obj.name] == '' || this.values[obj.name] == null) {
      this.properties[obj.name] = undefined
    } else {
      var dateArray = this.values[obj.name].split("-")
      var date = dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0]
      this.properties[obj.name] = date;
    }
  }
  else if (obj.data_type == "bool") {
    this.properties[obj.name] = false
    if (this.values[obj.name]) {
      this.properties[obj.name] = this.values[obj.name]
    }
  }
  else if (obj.data_type === "str") {
    if (this.values[obj.name] == '' || this.values[obj.name] == null) {
      this.properties[obj.name] = undefined
    }
    else {
      this.properties[obj.name] = this.values[obj.name]
    }
  }
  else {
    this.properties[obj.name] = this.values[obj.name];
  }
  if (obj.name === "Background Attestation Form"){
  this.properties[obj.name] = this.BAFdocId
    this.BAFUploaded = true
    

  }
if(index+1==this.form.form_field_definition.properties.length){
  this.populateForm(action)
  this.formSaveMessage = true
}

})
  }

  uploadDocument(fileName,file){

    if(fileName == 'Background Attestation Form'){

      var meta_data = {
        "Username": this.resourceDetails['Email'],
        "Document Type": fileName
      }
    
      const formData = new FormData();
      formData.append("title", fileName);
      formData.append("category_id", String(this.BAFCategory));
      formData.append("meta_data", JSON.stringify(meta_data));
      formData.append("file", file);
    this.documentService.uploadDocumentDirect(formData).subscribe(resp=>{
      this.BAFdocId = resp['body']["docId"];
      this.properties['Background Attestation Form'] = this.BAFdocId
      this.BAFUploaded = true
      this.formSaveMessage = false
      this.populateForm("save")
    
    })
    }
    
  }

  viewDocument(document){
    var documentId = document
    this.documentService.getDocumentDetails(documentId).subscribe(resp => {
      let dialogRef = this.dialog.open(DialogViewDocComponent,
        { width: '50%', height:'95%', data: { type: "view", payload: documentId, version: resp.activeVersion }, panelClass: 'custom-modalbox' });
    })
    
  }


  getDate(date){
    this.projDate = String(date)

    var newDate = new Date( this.projDate || new Date() );
        newDate.setMonth( newDate.getMonth() - (6 || 0), newDate.getDate());
        let minDate =this.datepipe.transform(newDate, 'yyyy-MM-dd');
        this.latestDate = String(minDate)
  }
populateForm(action){
  if(action == 'save'){
    var populateReqSave = {
      "email": this.resourceDetails['Email'].trim().toLowerCase(),
      "form_id": this.form["id"],
      "formfield_value": this.properties,
      "submit_form": false
    }

    this.formService.populateForm(populateReqSave).subscribe(resp=>{
      console.log("HEREE")
      this.formService.getProfile(this.resourceDetails['profileid']).subscribe(fields=>{
        this.values['Background Attestation Form'] = fields['form_value']['properties']['Background Attestation Form']
      })
      if(this.formSaveMessage){
        let dialogRef = this.dialog.open(DialogErrorComponent,
          { width: '40%', height:'25%', data: {message: "The form has been saved!" }, panelClass: 'custom-modalbox' });
      }
      
    })
  }


  if(this.BAFdocId && action=='submit'){


    var populateReq = {
      "email": this.resourceDetails['Email'].trim().toLowerCase(),
      "form_id": this.form["id"],
      "formfield_value": this.properties
    }


    
    this.formService.populateForm(populateReq).subscribe(resp=>{
      var workflowRequest = {
        "UserID": this.resourceDetails['Email'].trim().toLowerCase(),
        "Owner": this.currentUser.username,
        "OnboardingSpecialist": this.resourceDetails['Onboarding Specialist']
      }
      this.formService.submitNewResourceWorkflow(workflowRequest, this.resourceDetails['taskid']).subscribe(resp=>{
        this.isNewResourceSubmitted = true
      })

    })
  }
}


}
