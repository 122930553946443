<div>
    <div class="card-body" style="text-align: center;">
        <!-- <strong>Please make sure the documents are properly signed and dated! Address</strong> -->
        <strong>{{data.message}}</strong>
          <div class="mt-2">
            <button mat-raised-button (click)="onClose()" class="m-2" style="float: right; background-color:#03277c; color:white; margin-top: 5%">Ok</button>
            
          </div>
        
    </div>
</div>
