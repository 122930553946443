import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'stripDisplay'})
export class StripDisplayPipe implements PipeTransform {
  transform(value: string): string {
    //console.log("Inside display pipe " + value);
    let newStr: string = value;
    if (newStr.trim().startsWith("display_")) {        
        newStr = newStr.trim().slice(8)
        //console.log("inside if " + new);
    }
    //console.log("Trimmed value " + newStr);
    return newStr;
  }
}