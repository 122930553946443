import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/_models';
import { Router, Event as RouterEvent, NavigationEnd } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CategoryService } from 'src/app/_services/category.service';
import { DocumentService } from 'src/app/_services/document.services';
import { FormService } from 'src/app/_services/forms.service';
import { UserService } from 'src/app/_services/user.service';
import { UsersAndGroupsService } from 'src/app/_services/users-and-groups.service';
import { AlertService } from 'src/app/_services/alert.service';
import { SearchCountryField,  CountryISO } from 'ngx-intl-tel-input';
import { DatePipe } from '@angular/common';
import { DialogViewDocComponent } from 'src/app/dialog-view-doc/dialog-view-doc.component';
import { DialogErrorComponent } from 'src/app/dialog-error/dialog-error.component';

@Component({
  selector: 'app-field-glass-form',
  templateUrl: './field-glass-form.component.html',
  styleUrls: ['./field-glass-form.component.scss']
})
export class FieldGlassFormComponent implements OnInit {
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	// TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  @Input() resourceDetails = new EventEmitter<any>()
  currentUser: User
  form: any
  resourceForm: FormGroup
  values: any[];
  properties: any;
  NDA: File = null;
  formId: number;
  AUP:File = null;
  CSP: File = null;
  docId: any
  NDAdocId: any
  CSPdocId: any
  isPreview: boolean = false
  AUPdocId: any
  NDAUploaded: boolean = false
  CSPUploaded: boolean = false
  AUPUploaded: boolean = false
  isPPMDApprovalRequired: boolean = false
  isNewResourceSubmitted: boolean = false
  isViewDocument: boolean = false
  openUpload: boolean = false
  allDocuments: any = []
  docValues: any;
  message: string = null
  selectedDocument: any
  fileToUpload: File = null
  @Output() hideNewResource = new EventEmitter<boolean>();

  constructor(    
    private documentService: DocumentService,
    public router: Router,
    private authenticationService: AuthenticationService,
    private categoryService: CategoryService,
    public datepipe: DatePipe,
    private userService: UserService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private alertService: AlertService,
    private formService: FormService,
    private usersGroupsService: UsersAndGroupsService,) {

      this.currentUser = this.authenticationService.currentUserValue
     }

  ngOnInit(): void {

    console.log("RESOURCE DETAILS", this.resourceDetails)
    this.formService.getProfile(this.resourceDetails['profileid']).subscribe(fields=>{
      this.formService.getFormName('Fieldglass Verification Form').subscribe(form=>{
        this.formId = form['id']
        this.formService.getFormName('Onboarding Specialist Documents').subscribe(documents=>{
          this.allDocuments = documents
          this.docValues = Object.assign(fields['form_value']['properties'])
        })
        this.form = form
        this.values = Object.assign(fields['form_value']['properties'])
        console.log("VLUESS", this.values)
        this.form.form_field_definition.properties.forEach(element => {
          if(element.data_type == "bool"){
            if(this.values[element.name] == true){
              this.values[element.name] = "Yes"
            }
            else if(this.values[element.name] == false){
              this.values[element.name] = "No"
            }
          }
        });

        this.values['Resource Project Start Date'] = this.datepipe.transform(this.values['Resource Project Start Date'], 'yyyy-MM-dd');
        this.values['Background Check Date'] = this.datepipe.transform(this.values['Background Check Date'], 'yyyy-MM-dd');
        this.values['Contract End Date'] = this.datepipe.transform(this.values['Contract End Date'], 'yyyy-MM-dd');
        this.properties = {}
      } 
        )
    })

      
  

  }

  back() {
    this.hideNewResource.emit(false);
  }



  getFieldGlassDependencies(value, field){

    if(field=='Entity'){
      this.formService.getKey(value).subscribe(resp=>{
        this.form.form_field_definition.properties.forEach(element => {
        var keys = Object.keys(resp['value'])
        keys.forEach(keyValue => {
          if(element.name == keyValue){
            this.values[element['name']] = resp['value'][keyValue]
          }
        });
          
        });
      })
    }
    
    if(field=='Fieldglass TQ'){
    this.formService.getKey(value).subscribe(resp=>{
      this.form.form_field_definition.properties.forEach(element => {
      var keys = Object.keys(resp['value'])
      keys.forEach(keyValue => {
        if(element.name == keyValue){
          this.values[element['name']] = resp['value'][keyValue]
          if (element.data_type == "date") {
            if (this.values[element.name] == '' || this.values[element.name] == null) {
    
            } else {
              var dateArray = this.values[element.name].split("/")
              var dateValue = dateArray[0].split(" ")
              this.values[element.name] = dateValue[0]
            }
          }
        }
      });
        
      });
    })}
    }

  saveForm(action){
this.form.form_field_definition.properties.forEach((obj, index) => {
   if (obj.data_type === "date") {
    if (this.values[obj.name] == '' || this.values[obj.name] == null) {
      this.properties[obj.name] = undefined
    } else {
      var dateArray = this.values[obj.name].split("-")
      var date = dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0]
      this.properties[obj.name] = date;
    }
  }
  else if (obj.data_type == "bool") {
    //this.properties[obj.name] = false
    if (this.values[obj.name] == 'Yes') {
      this.properties[obj.name] = true
    }
    else if(this.values[obj.name] == 'No'){
      this.properties[obj.name] = false
    }
  }
  else if (obj.data_type === "str") {
    if (this.values[obj.name] == '' || this.values[obj.name] == null) {
      this.properties[obj.name] = undefined
    }
    else {
      this.properties[obj.name] = this.values[obj.name]
    }
  }
  else {
    this.properties[obj.name] = this.values[obj.name];
  }
  if(index + 1 == this.form.form_field_definition.properties.length ){
    this.populateForm(action)
   }
 })
//  console.log("PROPERTIES", this.properties)
//  this.populateForm(action)

  }

  viewDocument(document){
    this.isPreview = true
    var documentId = this.values[document['name']]
    this.documentService.getDocumentDetails(documentId).subscribe(resp => {
      console.log("RESP VIEW", resp)
      let dialogRef = this.dialog.open(DialogViewDocComponent,
        { width: '50%', height:'95%', data: { type: "view", payload: documentId, version: resp.activeVersion }, panelClass: 'custom-modalbox' });
    })
    
  }

  onCountryChange($event){

  }

  getNumber($event){

  }

  telInputObject($event){

  }

  hasError($event){
    
  }

  releaseTask(){
    this.formService.getKey("Queue").subscribe(resp=>{
      var claimTaskReq = {
        "task-input-data" : {
          "Owner" : resp['value']['FieldGlassAdmin']
        }
      
      }
      this.formService.claimTask(claimTaskReq,this.resourceDetails['taskid'] ).subscribe(resp=>{
        // this.hideNewResource.emit(false);
        this.resourceDetails['claimed'] = false
      })
    })

  }

  claimTask(){
    this.formService.claimTaskCheck(this.resourceDetails['taskid']).subscribe(resp=>{
      this.formService.getKey(this.values['Member Firm']).subscribe(key=>{
        if(resp['task-input-data']['Owner'] != key['value']['Fieldglass Admin Email']){
          let dialogRef = this.dialog.open(DialogErrorComponent,
            { width: '50%', height:'25%', data: {message: "This task has already been claimed!" }, panelClass: 'custom-modalbox' });
            dialogRef.afterClosed().subscribe(result => {
              this.hideNewResource.emit(false);
            })
        }
        else{
          var claimTaskReq = {
            "task-input-data" : {
              "Owner" : this.currentUser['email']
            }
          
          }
          this.formService.claimTask(claimTaskReq,this.resourceDetails['taskid'] ).subscribe(resp=>{
            // this.router.navigate(['/work-item']);
            this.resourceDetails['claimed'] = true
          })
        }
      })
    })
  }
  openUploadForm(file, element) {
    var systemProperties = {
      
      "fileName": element.name,
      "lastModifiedDate": new Date(file[0].lastModified),
      "size": String(file[0].size),
      "type": file[0].type
    } 
    var uploadRequest = {
      "docId": this.values[element['name']],
      "category_id": Number(element.category_id),
      "meta_data": {
      "Username": this.resourceDetails['email'],
      "Document Type": element.name
    },
    "system_properties": systemProperties

    }
    this.selectedDocument = uploadRequest
    if (file.length > 0) {
      this.fileToUpload = file[0]
      this.openUpload = true;
    }
  }

  downloadDocument(document){
    var documentId = this.values[document['name']]
    this.documentService.downloadPdf(documentId)
      .then(blob=> {
        saveAs(blob, this.values['Resource Last Name']+"_"+this.values['Resource First Name']+"_"+document['name']+'.pdf');
      })
  }

  backfromUpload(hide: boolean) {
    if (hide) {
      this.openUpload = true
    }
    else {
      this.openUpload = false
      this.ngOnInit()
    }
  }

populateForm(action){
  if (this.properties['Laptop required'] == false){
    delete this.properties['Address Line 1']
    delete this.properties['Address Line 2']
    delete this.properties['City']
    delete this.properties['State']
    delete this.properties['Country']
    delete this.properties['Zip Code']
    delete this.properties['Mobile Number']

  }

  if(this.properties['Member Firm'] == "Australia"){
    this.isPPMDApprovalRequired = true
  }
  else{
    this.isPPMDApprovalRequired = false
  }
    var populateReq = {
      "email": this.resourceDetails['Email'],
      "form_id": this.formId,
      "formfield_value": this.properties
    } 
    this.formService.populateForm(populateReq).subscribe(resp=>{
      
      if(action == 'approve'){
        var workflowRequest = {
          "UserID": this.resourceDetails['Email'],
          "Owner": this.currentUser['email'],
          "FormID": resp.profileId,
          "OSStatus": "reviewed",
          "TQWorkerOwner": this.properties['SOW/TQ Owner'],
          "OnboardingSpecialist": this.properties['Onboarding Specialist']
        }
        this.formService.submitNewResourceWorkflow(workflowRequest, this.resourceDetails['taskid']).subscribe(workflowstatus=>{

          this.formService.changeWorkFlowStatus(resp.profileId).subscribe(status=>{
          this.isNewResourceSubmitted = true
          this.message = "The fieldglass activation request has been submitted for the resource!"
          })
          
        })
      }
    })
  
}

uploadDocVersion(){
  console.log("uploadversion")
}

}
