import { Component, OnInit, Inject } from '@angular/core';
import { first } from 'rxjs/operators';
import { User } from '../_models';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';

@Component({ templateUrl: 'home.component.html', selector: 'app-home',
styleUrls: ['./home.component.scss'] })

export class HomeComponent implements OnInit {
    currentUser: User;
    users = [];
    isDashboard: boolean = false
    isInitate: boolean = false;
    isReferenceLinks: boolean = false

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit() {
        this.getDashboard();
    }  

    getDashboard(){
        this.isDashboard = true
        this.isInitate = false
        this.isReferenceLinks = false
    }

    initiateOnboarding(){
        this.isDashboard = false
        this.isInitate = true
        this.isReferenceLinks = false
    }

    getReferenceLinks(){
        this.isDashboard = false
        this.isInitate = false
        this.isReferenceLinks = true
    }

    

}