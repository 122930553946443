import { Component, Inject, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentService } from '../_services/document.services';

@Component({
  selector: 'app-dialog-view-doc',
  templateUrl: './dialog-view-doc.component.html',
  styleUrls: ['./dialog-view-doc.component.scss']
})
export class DialogViewDocComponent implements OnInit {

  documentID: String;
  versionNumber: number
  fileUrl: string;
  imageViewer: boolean = false;
  googleViewer: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogViewDocComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private documentSvc: DocumentService,
    private snackBar: MatSnackBar
  ) { }


  ngOnChanges(changes: SimpleChanges) {
    this.documentID = this.data.payload;
    //this.fetchServiceData();
    if(changes.documentID.currentValue != changes.documentID.previousValue){
      this.ngOnInit()
    }

  }

  ngOnInit(): void {
    this.documentID = this.data.payload
    this.viewDocument();
  }

  viewDocument() {
    //console.log("doc id " + this.documentID + " Object " + this.documentType);
    var googleDriveTypes = ["application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.openxmlformats-officedocument.wordprocessing"]
    var imageViewerTypes = ["image/tiff", "application/pdf", "image/png", "image/jpeg", "image/jpg"]
    this.documentSvc.viewVersionDocument(this.documentID, this.data.version)
      .subscribe(
        resp => {
            var url = resp.slice(5);
            if (resp.includes("google")) {
              this.fileUrl = "https" + url;
            } else {
              this.fileUrl = url;
            }
            this.googleViewer = true;

          
        },
        );
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
