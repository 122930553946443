<div class="mt-1" *ngIf="data.type == 'NDA Confidentiality IP' || data.type == 'Acceptable Use Policy' ">
    <div class="card-body">
        <!-- <strong>Please make sure the documents are properly signed and dated! Address</strong> -->
        <ul>
            <li><strong>Please make sure the documents are properly signed and dated.</strong></li><li><strong>Please make sure the address entered is the office address.</strong></li>
          </ul> 
        <button mat-raised-button (click)="onClose()" class="backButton m-2" style="float: right; background-color:#03277c; color:white; margin-top: 5%;margin-left:5%">Confirm</button>
    </div>
</div>

<div class="mt-1" *ngIf="data.type == 'Background'">
    <div class="card-body">
        <!-- <strong>Please make sure the documents are properly signed and dated! Address</strong> -->
        <ul>
            <li><strong>Please make sure the document is signed, dated and the validation result box is checked</strong></li>
          </ul> 
        <button mat-raised-button (click)="onClose()" class="backButton m-2" style="float: right; background-color:#03277c; color:white; margin-top: 5%;margin-left:5%">Confirm</button>
    </div>
</div>

<div class="mt-1" *ngIf="data.type == 'Contractor Privacy Statement'">
    <div class="card-body">
        <!-- <strong>Please make sure the documents are properly signed and dated! Address</strong> -->
        <ul>
            <li><strong>Please make sure the documents are properly signed and dated.</strong></li>
          </ul> 
        <button mat-raised-button (click)="onClose()" class="backButton m-2" style="float: right; background-color:#03277c; color:white; margin-top: 5%;margin-left:5%">Confirm</button>
    </div>
</div>