import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../_models';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { CategoryService } from '../_services/category.service';
import { DocumentService } from '../_services/document.services';
import { FormService } from '../_services/forms.service';
import { UserService } from '../_services/user.service';
import { UsersAndGroupsService } from '../_services/users-and-groups.service';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-work-item',
  templateUrl: './work-item.component.html',
  styleUrls: ['./work-item.component.scss']
})
export class WorkItemComponent implements OnInit {

  docResultsList: MatTableDataSource<any>
  documentCols: String[] = [];
  isNewResource: boolean = false
  isConfirmPPMD: boolean = false
  isOnboarding: boolean = false
  isBackground: boolean = false
  currentUser: User
  sortedData: any;
  allTasks: any
  tasks: any 
  taskValues: any
  tasksPresent:boolean = false
  resourceDetails: any
  isFieldGlassAdmin: boolean = false
  

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private formService: FormService,
    private usersGroupsService: UsersAndGroupsService
  ) { 
    this.currentUser = this.authenticationService.currentUserValue
  }


  sort
  @ViewChild(MatSort, { static: false }) set content(content: any) {
    this.sort = content;
    if (this.sort) {
      this.docResultsList.sort = this.sort;
    }
  }

  paginator;
  @ViewChild(MatPaginator, { static: false }) set pageContent(content: any) {
    this.paginator = content;
    if (this.paginator) {
      this.docResultsList.paginator = this.paginator;
    }
  }
  ngAfterViewInit() {
    if(this.docResultsList){  
    this.docResultsList.sort = this.sort;
    }
  } 
  ngOnInit(): void {

    this.taskValues = []

    this.formService.getTask(this.currentUser['email'].trim().toLowerCase()).subscribe(resp=>{

    this.tasks = resp
    this.allTasks = resp['tasks']
    if(this.allTasks){
      if(this.allTasks.length>0){
        this.sortedData = this.allTasks.slice();
        this.allTasks.forEach(element => {
          if(element['Task Name'] == "BGVTask"){
            element['Task Name'] = "Background Verification"
          }
          if(element['Task Name'] == "NewResourceForm"){
            element['Task Name'] = "New Resource Form"
          }
          if(element['Task Name'] == "FieldGlassAdmin"){
            element['Task Name'] = "Fieldglass Admin Verification"
          }
          if(element['Task Name'] == "OnboardingForm"){
            element['Task Name'] = "Onboarding Specialist Verification"
          }
    
        });
      }
    }
    

    this.documentCols = resp['columns'];
    this.docResultsList = new MatTableDataSource(this.allTasks);
    this.docResultsList.sort = this.sort;
    this.docResultsList.paginator = this.paginator;
    })
  }

  getCurrentStep($event, element){

    if(element['Task Name'] == 'New Resource Form'){
      this.isNewResource = true
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.resourceDetails = element
    }

    else if(element['Task Name'] == 'Confirm PPMD Approval'){

      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = true
      this.isFieldGlassAdmin = false
      this.resourceDetails = element
      
    }
    else if(element['Task Name'] == 'Background Verification'){

      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = true
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.resourceDetails = element
      
    }
    else if(element['Task Name'] == 'Onboarding Specialist Verification'){

      this.isNewResource = false
      this.isOnboarding = true
      this.isBackground = false
      this.isFieldGlassAdmin = false
      this.isConfirmPPMD = false
      this.resourceDetails = element
      
    }

    else if(element['Task Name'] == 'Fieldglass Admin Verification'){

      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = true
      this.resourceDetails = element
      
    }
  }


  backNewResource(hide: boolean) {
    if (hide) {
      this.isNewResource = true
    }
    else {
      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.ngOnInit()
    }
  }

  backBackground(hide: boolean) {
    if (hide) {
      this.isBackground = true
    }
    else {
      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isFieldGlassAdmin = false
      this.isConfirmPPMD = false
      this.ngOnInit()
    }
  }
  backConfirmPPMD(hide: boolean) {
    if (hide) {
      this.isConfirmPPMD = true
    }
    else {
      this.isNewResource = false
      this.isFieldGlassAdmin = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.ngOnInit()
    }
  }
  backOnboarding(hide: boolean) {
    if (hide) {
      this.isOnboarding = true
    }
    else {
      this.isNewResource = false
      this.isOnboarding = false
      this.isFieldGlassAdmin = false
      this.isBackground = false
      this.isConfirmPPMD = false
    }
  }

  backFieldglass(hide: boolean) {
    if (hide) {
      this.isFieldGlassAdmin = true
    }
    else {
      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.ngOnInit()
    }
  }

  sortData(sort: Sort) {
    const data = this.allTasks.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Resource First Name': return compare(a['Resource First Name'], b['Resource First Name'], isAsc);
        case 'Resource Last Name': return compare(a['Resource Last Name'], b['Resource Last Name'], isAsc);
        case 'Task Name': return compare(a['Task Name'], b['Task Name'], isAsc);
        case 'Task Start Date': return compare(a['Task Start Date'], b['Task Start Date'], isAsc);
        case 'Email': return compare(a['Email'], b['Email'], isAsc);
        case 'Onboarding Specialist': return compare(a['Onboarding Specialist'], b['Onboarding Specialist'], isAsc);
        case 'Project Name': return compare(a['Project Name'], b['Project Name'], isAsc);
        case 'Resource Project Start Date': return compare(a['Resource Project Start Date'], b['Resource Project Start Date'], isAsc);
        default: return 0;
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}