<div class="mt-1">
    <div class="card-body text-center">
        <strong>{{message}}</strong>
    </div>
</div>
<div *ngIf="isCommentRequired" class="mt-1">
    <form #commentForm="ngForm">
        <textarea name="commentBox" placeholder="Add Comment" class="col-12" style="font-size: 14px;"
            [(ngModel)]="comment" required></textarea>
    </form>
</div>
<div>
    <button mat-raised-button (click)="onClose()" class="backButton m-2" style="float: right">No</button>
    <button *ngIf="data.type=='Delete Document'" mat-raised-button (click)="deleteDocument()" class="actionButton m-2"
        style="float: right">Yes</button>
    <button *ngIf="data.type=='Delete Version'" mat-raised-button (click)="deleteVersion()" class="actionButton m-2"
        style="float: right">Yes</button>
    <button *ngIf="data.type=='Activate Version'" mat-raised-button (click)="activateVersion()" class="actionButton m-2"
        style="float: right">Yes</button>
    <button *ngIf="data.type=='Delete Category'" mat-raised-button (click)="deleteCategory()" class="actionButton m-2"
        style="float: right">Yes</button>
</div>