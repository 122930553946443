import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models';
import { UsersAndGroupsService } from './users-and-groups.service';
import { AlertService } from './alert.service';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json, application/XML',
      "X-ApplicationSystem": "vault",
      'Access-Control-Allow-Credentials': "true",
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
      "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, X-ApplicationSystem",

    }),
    observe: 'response' as 'body'
  };

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>
    private isUserAdmin: BehaviorSubject<any>;
    public currentUser: Observable<User>;
    public isAdmin: Observable<any>;
    private userGroupsSubject: BehaviorSubject<any>;
    public userGroups: Observable<any>;

    constructor(
        private http: HttpClient,
        private userGroupService: UsersAndGroupsService,
        private alertService: AlertService
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.userGroupsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userGroups')));
        this.userGroups = this.userGroupsSubject.asObservable();
        this.isUserAdmin = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('isAdmin')));
        this.isAdmin = this.isUserAdmin.asObservable();
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }

    public get isAdminValue() {
        return this.isUserAdmin.value;
    }

    public get userGroupsValue() {
        return this.userGroupsSubject.value;
    }

    login(username, password): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/auth/sign-in`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                this.userGroupService.getGroups().subscribe(groups => {
                    localStorage.setItem('userGroups', JSON.stringify(groups))
                    this.userGroupsSubject.next(groups);
                    console.log("GROUPS", groups)
                    groups.forEach(group => {
                        switch (group) {
                            case "dms-admin": localStorage.setItem('isAdmin', "true"),this.isUserAdmin.next("true"), console.log("here");
                                break;                          
                        }
                        
                    })
                    
                })
                return user;
            }));
    }

    getUserDetails(request): Observable<any> {
        localStorage.setItem('currentUser', JSON.stringify(request));
        this.currentUserSubject.next(request);
        return this.http.get<any>(`${environment.apiUrl}/auth/userdetails`)
        .pipe(map(user => {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            this.userGroupService.getGroups().subscribe(groups => {
                localStorage.setItem('userGroups', JSON.stringify(groups))
                this.userGroupsSubject.next(groups);
                groups.forEach(group => {
                    console.log("GROUP VALUE", group)
                    switch (group) {
                        case "dms-admin": localStorage.setItem('isAdmin', "true"),this.isUserAdmin.next("true"), console.log("here");
                            break;                          
                    }

                    console.log("IS ADMIN IN AUTHENTICATION", this.isAdmin)
                    console.log("IS ADMIN IN AUTHENTICATION", this.isUserAdmin)
                    
                })
            }),
            error => {
                var errMsg = error.error
            }
            return user;
        }, catchError(this.handleError<any>('getUserDetails'))));
}

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userGroups')
        localStorage.removeItem('isAdmin')
        this.currentUserSubject.next(null);
        this.isUserAdmin.next(null);
        this.userGroupsSubject.next(null);
    }

    refreshToken() {
        var req: JSON = <JSON><unknown>{
            "AuthType": "RefreshToken",
            "RefreshToken": {
                "username": this.currentUserValue.username,
                "refreshToken": this.currentUserValue.refresh_token
            }
        }
        return this.http.post<any>(`${environment.apiUrl}/auth/refresh`, req)
            .pipe(map(user => {
                this.currentUserValue.token = user.token
                localStorage.setItem('currentUser', JSON.stringify(this.currentUserValue))
                this.currentUserSubject.next(this.currentUserValue)
                //console.log("Got the token " + this.currentUserValue)
                return this.currentUserValue;
            }));
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: HttpErrorResponse) => {
          console.error('%s from error' + error); // log to console instead
          if (String(error).includes('.throw is not a function')) {
            this.alertService.error('ERROR: Could not connect to network. Please contact Vault team if the issue persists.');
          } else {
            this.alertService.error(String(error));
          }
          return result;
        };
      }
}