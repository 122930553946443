import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryService } from '../_services/category.service';
import { DocumentService } from '../_services/document.services';

@Component({
  selector: 'app-dialog-confirm-delete',
  templateUrl: './dialog-confirm-delete.component.html',
  styleUrls: ['./dialog-confirm-delete.component.scss']
})
export class DialogConfirmDeleteComponent implements OnInit {


  message: string = null;
  payload: any = null;
  comment: string = null;
  document_id: string = null;
  isCommentRequired: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteComponent>,
    private documentService: DocumentService,
    private categoryService: CategoryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {


    switch (this.data.type) {
      case "Delete Document": this.setDeleteDocument()
        break;
      case "Delete Version": this.setDeleteVersion()
        break;
      case "Activate Version": this.setActivateVersion()
        break;
      case "Delete Category": this.setDeleteCateory()
        break;
      case "Remove Hold": this.setRemoveHold()
        break;
    }
  }

  setRemoveHold() {
    this.message = "Are you sure you wish to remove the hold?"
    this.isCommentRequired = false
  }


  setDeleteCateory() {
    this.message = "Are you sure you wish to delete this Category?"
    this.isCommentRequired = false
  }

  deleteCategory() {
    const payload = this.data.payload
    this.categoryService.deleteCategory(payload).subscribe(resp => {
      this.dialogRef.close(true)
    })
  }

  setDeleteDocument() {
    this.message = "Are you sure you wish to delete this Document?"
    this.document_id = this.data.payload['documentId']
    this.isCommentRequired = true
  }

  deleteDocument() {
    var payload = {}
    if (this.comment != null) {
      payload = {
        comment: this.comment
      }
    }
    this.documentService.deleteDocument(this.document_id, payload).subscribe(resp => {
      //console.log("Response", resp)
      this.dialogRef.close(true)
    })
  }

  deleteVersion() {
    var payload = this.data.payload
    if (this.comment != null) {
      payload = {
        document_id: this.data.payload.document_id,
        version: this.data.payload.version,
        comment: this.comment
      }
    }
    //console.log("Delete Version Payload", payload)
    this.documentService.deleteVersion(payload).subscribe(resp => {
      //console.log("Delete Version response", resp)
      this.dialogRef.close(true)
    })
  }

  setDeleteVersion() {
    this.isCommentRequired = true
    this.message = "Are you sure you wish to delete this version of the document?"
  }

  setActivateVersion() {
    this.isCommentRequired = true
    this.message = "Are you sure you wish to make this the active version?"
  }

  activateVersion() {
    var payload = this.data.payload
    if (this.comment != null) {
      payload = {
        document_id: this.data.payload.document_id,
        version: this.data.payload.version,
        comment: this.comment
      }
    }
    this.documentService.activateVersion(payload).subscribe(resp => {
      this.dialogRef.close(true)
    })
  }

  onClose(): void {
    this.dialogRef.close();
  }


}
