<ng-http-loader [backdrop]="false" [backgroundColor]="'#6da9d4'" [spinner]="spinkit.skCubeGrid" [debounceDelay]="400" [extraDuration]="100" [minDuration]="100" [opacity]=".6" [filteredUrlPatterns]="['documents/export-documents']">
</ng-http-loader>
<!-- nav -->
<mat-toolbar color="primary">
    <!-- <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button> -->
    <span routerLink="/"><span class="application-logo"><img src="assets/images/logo.PNG" alt="DocuEdge"
        height="45"></span>
    <!--<span class="application-title"> | Vault</span>-->
    </span>
    <span class="menu-spacer"></span>
    <div class="menu" *ngIf="configSection" class="ml-5">Configure Repository</div>
    <div class="menu" *ngIf="currentUser && !configSection">

        <!-- 
    <nav class="menu" >
      <ol>
        
        <li class="menu-item">
          <a href="#0">Home</a>
          <ol class="sub-menu">
            <li class="menu-item"><a [routerLink]="'/dashboard'">Dashboard</a></li>
            <li class="menu-item"><a [routerLink]="'/initiate-onboarding'">Initiate Onboarding</a></li>
            <li class="menu-item"><a [routerLink]="'/reference-links'">Reference Links</a></li>
          </ol>
        </li>
        <li class="menu-item"><a href="#0">Work-Item</a></li>
      </ol>

    </nav> -->
        <!-- <a class="menubuttons" [routerLink]="'/home'" routerLinkActive="selected">Home</a> -->
        <a class="menubuttons" *ngIf="isAdmin && currentUser['email']" [routerLink]="'/dashboard'" routerLinkActive="selected">Dashboard</a>
        <a class="menubuttons" *ngIf="currentUser['email']" [routerLink]="'/initiate-onboarding'" routerLinkActive="selected">Initiate Onboarding</a>
        <!-- <a class="menubuttons" *ngIf="currentUser['email']" [routerLink]="'/reference-links'" routerLinkActive="selected">Reference Links</a> -->
        <a class="menubuttons" *ngIf="currentUser['email']" [routerLink]="'/work-item'" routerLinkActive="selected">Work Item</a>
        <a class="menubuttons" *ngIf="currentUser['email']" [routerLink]="'/reports'" routerLinkActive="selected">Reports</a>
        <a class="menubuttons" *ngIf="currentUser['email']" [routerLink]="'/faq'" routerLinkActive="selected">FAQ</a>

        <!-- <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width,initial-scale=1" />

    
    
    <nav id="nav" role="navigation"> <a href="#nav" title="Show navigation">Show navigation</a> <a href="#" title="Hide navigation">Hide navigation</a>
      <ul class="clearfix">
        <li><a href="">Home</a></li>
        <li> <a href=""><span>Blog</span></a>
          <ul>
            <li><a href="">Design</a></li>
            <li><a href="">HTML</a></li>
            <li><a href="">CSS</a></li>
            <li><a href="">JavaScript</a></li>
          </ul>
        </li>
      </ul>
    </nav>
    
    <script src="http://osvaldas.info/examples/main.js"></script>
    
    <script src="http://osvaldas.info/examples/drop-down-navigation-touch-friendly-and-responsive/doubletaptogo.js"></script>
    
    <script>
      $(function() {
        $('#nav li:has(ul)').doubleTapToGo();
      });
    </script> -->
        <!-- <a class="menubuttons" [routerLink]="'/export'" routerLinkActive="selected"> Export Status </a>
        <a class="menubuttons" [routerLink]="'/metadataVirtualFolder'" routerLinkActive="selected"> Metadata View </a> -->
        <!--<a class="menubuttons" [routerLink]="'/upload'" routerLinkActive="selected"> Upload </a>-->
    </div>
    <!--<div class="menu" *ngIf="configSection">
      <a class="settingsbuttons" [routerLink]="'/config/category'" routerLinkActive="selected"> Category Configuration</a>
      <a class="settingsbuttons" [routerLink]="'/config/search'" routerLinkActive="selected"> Search Configuration</a>
      <a class="settingsbuttons" [routerLink]="'/categoryconfig'" routerLinkActive="selected"> Compliance</a>
    </div>-->
    <!-- This fills the remaining space of the current row -->
    <span class="fill-remaining-space"></span>
    <span class="toolbar-buttons" *ngIf="currentUser">
    <!-- <mat-icon routerLink="/config/admin" routerLinkActive="active" title="Configure Repository" *ngIf="!configSection && isAdmin">
      settings</mat-icon> -->
    <mat-icon routerLink="/" routerLinkActive="active" title="Home" *ngIf="configSection">
      home</mat-icon>
      <img src="assets/images/deloitte.png" alt="DocuEdge" height="45">
    <button mat-button #authMenu class="mat-fab auth-menu ml-3" style="text-align: center;" [matMenuTriggerFor]="menu"
      title="{{currentUser.username}} {{currentUser.lastName}}"><mat-icon class="mr-2 mb-1">perm_identity</mat-icon><span
        class="user-initials"></span></button>


    <mat-menu #menu="matMenu">
        <button mat-menu-item>{{currentUser.email}}</button>
        <button mat-menu-item routerLink="/admin" *ngIf="isAdmin" routerLinkActive="active">Settings</button>
        <button mat-menu-item (click)="logout();">Log out</button>
    </mat-menu>
    </span>
</mat-toolbar>

<!-- <mat-sidenav-container class="example-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav mode="over" 
                 [fixedInViewport]="true" fixedTopGap="56">
      <mat-nav-list>
        <a mat-list-item routerLink="." >Browse</a>
        <a mat-list-item routerLink="/search" >Search</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content> <router-outlet></router-outlet>
    
      </mat-sidenav-content>
  </mat-sidenav-container> -->

<!-- <nav class="navbar navbar-expand navbar-dark bg-dark" >
    <button md-icon-button (click)="sidenav.toggle()" class="md-icon-button sidenav-toggle-button" [hidden]="!sidenav.opened">
        <mat-icon aria-label="Menu" class="material-icons">close</mat-icon>
      </button>
    <a class="navbar-brand" href="#">SmartBox | Vault</a>
    <div class="navbar-nav mr-auto" *ngIf="currentUser">
        <a class="nav-item nav-link" routerLink="/">Home</a>
        <a class="nav-item nav-link" routerLink="/search">Search</a>
        <a class="nav-item nav-link" routerLink="/searchscreen">SearchTest</a>
    </div>
    <div class="navbar-nav" *ngIf="currentUser">
        <div class="nav-item" >
            <span class="nav-link" style="color: white;">{{currentUser.username}}</span>
        </div>
        <div class="nav-item" >
            <a class="nav-link" (click)="logout()">Logout</a>
        </div>        
    </div>
</nav> -->
<alert></alert>
<router-outlet></router-outlet>

<!-- <div class="application-version-number">
  version 1.0.0
</div> -->
<!-- main app container -->
<!-- <div class="container mt-3">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 ">
                <alert></alert>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div> -->