import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CategoryService } from 'src/app/_services/category.service';
import { AlertService } from 'src/app/_services/alert.service';
import { User } from 'src/app/_models';
import { DocumentService } from 'src/app/_services/document.services';
import { AuthenticationService } from 'src/app/_services/authentication.service';


@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  showData;

  categoryId: any;
  @Input() fileToUpload: File = null;
  @Input() selectedDocument: any
  @Output() openUpload = new EventEmitter<boolean>();
  category: any;
  azureHeader = {};
  documentTitle: string = null;
  docId: String = null;
  properties = {};
  uploadMessage: Boolean = false;
  toggleUpload: Boolean = true;
  currentUser: User;
  isWorkflow: boolean = false;
  workflowID: any;
  filePath: string = null
  regions: any

  constructor(
    private categorySvc: CategoryService,
    private documentService: DocumentService,
    private alertService: AlertService, 
    //@Inject(MAT_DIALOG_DATA) public data: any
    private authenticationService: AuthenticationService,
  ) {

    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    if (this.fileToUpload.type.includes('pdf') || this.fileToUpload.type.includes('image')) {
      this.filePath = (window.URL || window.webkitURL).createObjectURL(this.fileToUpload)
    }
    this.categoryId = 1
    this.categorySvc.get(this.categoryId)
      .subscribe(category => {
        this.category = category;
      })
  }

  uploadDocument() {
    var systemProperties = null;
    if (this.fileToUpload) {
      systemProperties = {
        "fileName": this.fileToUpload.name,
        "lastModifiedDate": new Date(this.fileToUpload.lastModified),
        "size": String(this.fileToUpload.size),
        "type": this.fileToUpload.type
      }
    }
    //build object to be sent to the service
    if(this.selectedDocument){
      // var uploadReq = {
      //   "docId": this.selectedDocument.documentId,
      //   "category_id": this.category.id,
      //   "meta_data": {}, 
      //   "system_properties": systemProperties
      // }


      this.regions.forEach(region => {
        var meta_data = {"Region": region}

        const formData = new FormData();
      formData.append("docId", this.selectedDocument.documentId);
      formData.append("category_id", String(this.category.id));
      formData.append("meta_data", JSON.stringify(meta_data));
      formData.append("file", this.fileToUpload);
      this.documentService.uploadDocumentVersionDirect(formData).subscribe(resp=>{
        this.uploadMessage = true;
        this.toggleUpload = false;
        
      })
        // this.documentService.uploadDocumentVersion(uploadReq)
        // .subscribe(
        //   resp => {
        //     this.docId = resp["docId"];
        //     //this.documentService.workflowCheck(resp,this.category.name,this.fileToUpload)
  
        //     //console.log("url: ", resp);
        //     if (resp['storageProvider'] == "AWS") {
        //       this.documentService.uploadFileToS3(resp["uploadUrl"], this.fileToUpload)
        //         .subscribe(
        //           resp => {
        //             //reset form
        //             this.documentTitle = null;
        //             for (const data of this.category.meta_data_definition.properties) {
        //               data.value = null;
        //             }
        //             (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
        //             this.uploadMessage = true;
        //             this.toggleUpload = false;
        //           }
        //         ),
        //         error => {
        //           this.alertService.error(error);
        //         }
        //     } else if (resp['storageProvider'] == "Azure") {
        //       this.azureHeader = resp["azureMetadata"]
        //       this.documentService.uploadFileToAzure(resp["uploadUrl"], this.fileToUpload, this.azureHeader)
        //         .subscribe(
        //           resp => {
        //             //reset form
        //             this.documentTitle = null;
        //             for (const data of this.category.meta_data_definition.properties) {
        //               data.value = null;
        //             }
        //             (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
        //             this.uploadMessage = true;
        //             this.toggleUpload = false;
        //           }
        //         ),
        //         error => {
        //           this.alertService.error(error);
        //         }
        //     } else if (resp['storageProvider'] == "GCP") {
        //       console.log("GCP response", resp)
        //       this.documentService.uploadFileToGCP(resp["uploadUrl"], this.fileToUpload)
        //         .subscribe(
        //           resp => {
        //             (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
        //             this.uploadMessage = true;
        //             this.toggleUpload = false;
        //           }
        //         ),
        //         error => {
        //           this.alertService.error(error);
        //         }
        //     }
        //     else {
        //       alert("Invalid Storage Provider! Please try again.")
        //     }
        //   }),
        // error => {
        //   this.alertService.error(error);
        // }
      })
    }
    else if(!this.selectedDocument){
      // var uploadReq2 = {
      //   "title": this.documentTitle,
      //   "category_id": this.category.id,
      //   "meta_data": {}, 
      //   "system_properties": systemProperties
      // }
      this.regions.forEach(region => {
        var meta_data = {"Region": region}
        const formData = new FormData();
      formData.append("title", this.documentTitle);
      formData.append("category_id", String(this.category.id));
      formData.append("meta_data", JSON.stringify(meta_data));
      formData.append("file", this.fileToUpload);
      this.documentService.uploadDocumentDirect(formData).subscribe(resp=>{
        this.uploadMessage = true;
        this.toggleUpload = false;
        
      })
        // this.documentService.uploadDocument(uploadReq2)
        // .subscribe(
        //   resp => {
        //     this.docId = resp["docId"];
        //     //this.documentService.workflowCheck(resp,this.category.name,this.fileToUpload)
  
        //     //console.log("url: ", resp);
        //     if (resp['storageProvider'] == "AWS") {
        //       this.documentService.uploadFileToS3(resp["uploadUrl"], this.fileToUpload)
        //         .subscribe(
        //           resp => {
        //             //reset form
        //             this.documentTitle = null;
        //             for (const data of this.category.meta_data_definition.properties) {
        //               data.value = null;
        //             }
        //             (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
        //             this.uploadMessage = true;
        //             this.toggleUpload = false;
        //           }
        //         ),
        //         error => {
        //           this.alertService.error(error);
        //         }
        //     } else if (resp['storageProvider'] == "Azure") {
        //       this.azureHeader = resp["azureMetadata"]
        //       this.documentService.uploadFileToAzure(resp["uploadUrl"], this.fileToUpload, this.azureHeader)
        //         .subscribe(
        //           resp => {
        //             //reset form
        //             this.documentTitle = null;
        //             for (const data of this.category.meta_data_definition.properties) {
        //               data.value = null;
        //             }
        //             (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
        //             this.uploadMessage = true;
        //             this.toggleUpload = false;
        //           }
        //         ),
        //         error => {
        //           this.alertService.error(error);
        //         }
        //     } else if (resp['storageProvider'] == "GCP") {
        //       console.log("GCP response", resp)
        //       this.documentService.uploadFileToGCP(resp["uploadUrl"], this.fileToUpload)
        //         .subscribe(
        //           resp => {
        //             (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
        //             this.uploadMessage = true;
        //             this.toggleUpload = false;
        //           }
        //         ),
        //         error => {
        //           this.alertService.error(error);
        //         }
        //     }
        //     else {
        //       alert("Invalid Storage Provider! Please try again.")
        //     }
        //   }),
        // error => {
        //   this.alertService.error(error);
        // }
      })
    }

    //populate meta-data

    
    //console.log("FALSE")

  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  onClose(): void {
    //this.dialogRef.close();
    (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
    this.openUpload.emit(false)
  }
}
