import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SearchService } from 'src/app/_services/search.service';
import { ChangeDetectorRef } from '@angular/core';
import { saveAs } from 'file-saver'
import { DocumentService } from 'src/app/_services/document.services';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { User } from 'src/app/_models';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DialogConfirmDeleteComponent } from 'src/app/dialog-confirm-delete/dialog-confirm-delete.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-reference-links',
  templateUrl: './reference-links.component.html',
  styleUrls: ['./reference-links.component.scss']
})
export class ReferenceLinksComponent implements OnInit {

  region: string = "United States of America"
  documentCols: String[] = [];
  //docResultsList: any;
  docResultsList: MatTableDataSource<any>
  isPreview: boolean = false
  documentID: any;
  currentUser: User;
  isAdmin: boolean = false
  isRecordsManager: boolean = false
  isRulesManager: boolean = false
  userGroups: string[]
  isUpload: boolean = false
  fileToUpload: File = null
  openUpload: boolean = false
  selectedDocument: any
  docVersionNumber: any
  documentType: any;
  fileUrl: any
  fileExtention: string;

  constructor(
    private authenticationService: AuthenticationService,
    private docService: DocumentService,
    private apiSvc: SearchService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer
  ) {         
    this.currentUser = this.authenticationService.currentUserValue;
    this.userGroups = authenticationService.userGroupsValue
    this.matIconRegistry.addSvgIcon(
      'file',
      this.domSanitzer.bypassSecurityTrustResourceUrl('../assets/icons/pdf.svg')
    );
  }


  sort
  @ViewChild(MatSort, { static: false }) set content(content: any) {
    this.sort = content;
    if (this.sort) {
      this.docResultsList.sort = this.sort;
    }
  }

  paginator;
  @ViewChild(MatPaginator, { static: false }) set pageContent(content: any) {
    this.paginator = content;
    if (this.paginator) {
      this.docResultsList.paginator = this.paginator;
    }
  }

  ngOnInit(): void {

    this.authenticationService.getUserDetails(this.currentUser).subscribe(resp=>{

    })
    this.userGroups.forEach(group => {
      switch (group) {
          case "dms-rules-admin": this.isRulesManager = true;
              break;
          case "dms-admin": this.isAdmin = true;
              break;
          case "dms-records-management": this.isRecordsManager = true;
              break
      }
  })
    this.documentCols = ["title", "action"];
    this.fetchRegion(this.region)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.documentID = changes.documentID.currentValue;
    //this.fetchServiceData();
  }

  fetchRegion(region){
    var searchReq = {
      "searchformId": 1,
      "offset": 0,
      "limit": 200,
      "storage_class": "S3",
      "criteria": [
        {
          "bindingName": "region",
          "value": region,
          "case_insensitive" : false
      }  
      ]
    };
    this.apiSvc.getSearchResults(searchReq).subscribe(resp => {

      this.docResultsList = new MatTableDataSource(resp['results']);
      this.docResultsList.sort = this.sort;
      this.docResultsList.paginator = this.paginator;
      this.ref.detectChanges();
    })

    
  }
  getDocDetails($event, documentID){

  }

  uploadDocument(){
    this.isUpload = true
  }

  deleteDocument($event, element){
    let dialogRef = this.dialog.open(DialogConfirmDeleteComponent,
      { width: '500px', data: { type: "Delete Document", payload: element } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit()
      }
    })
  }

  openUploadForm(file, element) {
    this.selectedDocument = element
    if (file.length > 0) {
      this.fileToUpload = file[0]
      this.openUpload = true;
    }
  }

  viewDocument($event, element){
    this.docVersionNumber = element.VersionNumber
    this.documentType = element.meta_data.system.type
    this.documentID = element.documentId
    this.isPreview = true
  }

  downloadDocument($event, element){
    this.documentID = element.documentId
    this.docVersionNumber = element.VersionNumber
    this.documentType = element.meta_data.system.type
    
    if(element.meta_data.system.type == 'application/pdf'){

      this.fileExtention = ".pdf"
    }
    if(element.meta_data.system.type == 'image/tiff'){
      this.fileExtention = ".tiff"
    }
    if(element.meta_data.system.type == 'image/png'){
      this.fileExtention = ".png"
    }
    if(element.meta_data.system.type == 'image/jpeg'){
      this.fileExtention = ".jpeg"
    }
    if(element.meta_data.system.type == 'image/jpg'){
      this.fileExtention = ".jpg"
    }
    if(element.meta_data.system.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
      this.fileExtention = ".docx"
    }
    if(element.meta_data.system.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'){
      this.fileExtention == ".pptx"
    }
    if(element.meta_data.system.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      this.fileExtention = ".xlsx"
    }
//     this.docService.viewVersionDocument(this.documentID, this.docVersionNumber)
//     .subscribe(
//       resp => {

//         var blob = new Blob([resp]);
//         var url = window.URL.createObjectURL(blob); // Create a URL object that provides access to the blob data
//         var aElement = document.createElement("a"); // Create a <a> element to bind that url object
//         aElement.href = url;
//         aElement.target = '_blank';
//         aElement.download = 'Doc'; // Set the downloaded file's name
//         document.body.appendChild(aElement);

//         aElement.click(); // Emulate clicking the link element to start download

//         document.body.removeChild(aElement);
//         window.URL.revokeObjectURL(url); // Release the url object 

//       });
    this.docService.downloadPdf(element.documentId)
      .then(blob=> {
        var data = new Blob([blob]);
        if(this.currentUser['firstName']=="" || this.currentUser['lastName']=="" ){
          saveAs(blob, this.currentUser['username']+"_"+element.documentName+this.fileExtention);
        }
        else{
          saveAs(blob, this.currentUser['firstName']+"_"+this.currentUser['lastName']+"_"+element.documentName+this.fileExtention);
        }
         
      })

      
  }

  back(hide: boolean) {
    if (hide) {
      this.openUpload = true
    }
    else {
      this.openUpload = false
      this.ngOnInit()
    }
  }
}
