import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-date-check',
  templateUrl: './dialog-date-check.component.html',
  styleUrls: ['./dialog-date-check.component.scss']
})
export class DialogDateCheckComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
