<div>
    <mat-drawer-container [hasBackdrop]="false" autosize style="height: calc(100vh - 50px); width:100%">
        <mat-drawer #drawer mode="side" opened style="display: block;">
            <!-- <mat-toolbar>
                <mat-toolbar-row> -->
            <div *ngIf="isAdmin" class="clearfix mt-1 mr-1"><button mat-stroked-button (click)="activateAddForm(false)" class="float-right">
                    <mat-icon>add</mat-icon> New
                </button>
            </div>
            <div>
                <div class="input-group-sm mt-2 input-group col-12">
                    <input class="form-control" type="text" (keyup)="applyFilterOnForm($event)" placeholder="Filter">
                </div>
            </div>

            <mat-nav-list style="padding: 0 0;">
                <a *ngFor="let form of forms" style="height: 30px; padding:0 0;" mat-list-item (click)="selectForm(form.name, form.id)" [class.selected]="selectedForm === form">
                    <div>
                    <button style="height: 30px; font-size: 11pt;" mat-button class="form-names">
                        {{form.name}}</button>
                    </div>
                </a>
            </mat-nav-list>
        </mat-drawer>

        <mat-drawer-content>
            <div class="mat-sidenav-content ">

                <div class="container-fluid">
                    <div class="row">
                        <div class="card p-2 m-2" *ngIf="!selectedForm && !addFormButton">
                            <div class="card-body p-2"><strong>To edit a Form:</strong><br> 1.Please select a Form you wish to edit and update.<br> 2.Fill in the data to the appropriate fields and update.
                                <br>
                                <br><br>
                                <strong>To create a New Form:</strong><br> 1.Click on "+New".<br> 2.Fill in the appropriate data in their respective fields and save.</div>
                        </div>
                        <div class="col">
                            <div class="card p-2 m-2" *ngIf="selectedForm">
                                <div class="sticky-top">
                                    <div class="Tabletitle">Form - {{formName}}
                                        <!-- <mat-icon *ngIf="!isDeletedForm" class="clone-icon" title="Clone Form" (click)="cloneForm(form)">content_copy</mat-icon> -->
                                        
                                    </div>
                                </div>
                                <div *ngIf="!addFormButton">
                                    <div class="clear-fix" *ngIf="!isDeletedForm">
                                        <!-- <button mat-stroked-button class="float-right mr-1" (click)="cloneForm()"> Clone Category
                                        </button> -->
                                    </div>
                                    <div>&nbsp;</div>
                                    <div class=" mt-3 alert alert-danger" *ngIf="errorMsg">
                                        {{errorMsg}}
                                    </div>
                                    <div class=" mt-2" *ngIf="!updatedForm && !isDeletedForm">
                                        <form [formGroup]="updateMetaDataForm">
                                            <div class="input-group mb-3 input-group col-6">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Name</span>
                                                </div>
                                                <input type="text" class="form-control" id="formname" name="formname" [(ngModel)]="this.fetchedFormName" formControlName="Name">
                                            </div>

                                            <div class="card p-2 m-3 mb-2">
                                                <div formArrayName="updateFormProperties">
                                                    <div class="form-group row">
                                                        <span class="form-group ml-3 form-label-105">Properties</span>
                                                        <div class="col mb-2">
                                                            <button mat-stroked-button class="float-right" title="Add Form"
                                                            (click)="addNewUpdateProperty()">
                                                                <mat-icon>add</mat-icon>Add Property
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div *ngFor="let item of updateFormPropertiesArray.controls; let i = index " [formGroupName]="i">
                                                        <div class="row">
                                                            <div class="col-11">
                                                                <div class="card p-2 mb-2">
                                                                    <div class="input-group row">
                                                                        <div class="col-3">
                                                                            <div class="input-group-sm mb-3 input-group col">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">Name</span>
                                                                                </div>
                                                                                <input class="form-control" name="metaName" type="text" formControlName="name">
                                                                            </div>
                                                                        </div>
            
                                                                        <div class="col-3">
                                                                            <div class="input-group-sm mb-3 input-group col">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">Required</span>
                                                                                </div>
                                                                                <!-- <input class="form-control" type="text"
                                                                                formControlName="required"> -->
                                                                                <mat-slide-toggle class="pl-2 pr-2 pt-1" formControlName="required"></mat-slide-toggle>
                                                                            </div>
                                                                        </div>
            
                                                                        <div class="col-3">
                                                                            <div class="input-group-sm mb-3 input-group col">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">Displayable</span>
                                                                                </div>
                                                                                <mat-slide-toggle class="pl-2 pr-2 pt-1" formControlName="display_in_list"></mat-slide-toggle>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-3">
                                                                            <div class="input-group-sm mb-3 input-group col">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">Data Type</span>
                                                                                </div>
                                                                                <select class="form-control" id="metaDataType" formControlName="data_type">
                                                                                    <option value="str"> String</option>
                                                                                    <option value="int"> Integer</option>
                                                                                    <option value="date"> Date</option>
                                                                                    <option value="object"> Object</option>
                                                                                    <option value="bool"> Boolean</option>
                                                                                    <option value="str array">Multiple Values</option>
                                                                                    <option value="options" disabled>Options</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>  
                                                                    </div>
            
                                                                    <div class="input-group row">
                                                                        <div class="col-3">
                                                                            <div class="input-group-sm mb-3 input-group col">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">Document</span>
                                                                                </div>
                                                                                <mat-slide-toggle  class="pl-2 pr-2 pt-1"
                                                                                    formControlName="is_document">
                                                                                </mat-slide-toggle>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-3" *ngIf = "updateMetaDataForm.value.updateFormProperties[i].is_document">
                                                                            <div class="input-group-sm mb-3 input-group col">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">Category</span>
                                                                                </div>
                                                                                <mat-select  formControlName="category_id" id="{{category_id}}" name="{{category_id}}" attr.for="{{category_id}}" class="form-control" required >
                                                                                    <mat-option *ngFor="let category of categories" [value]="category.id">
                                                                                        {{category.name}}</mat-option>
                                                                                </mat-select>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-3">
                                                                            <div class="input-group-sm mb-3 input-group col">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">Databaselist</span>
                                                                                </div>
                                                                                <mat-slide-toggle class="pl-2 pr-2 pt-1" formControlName="is_databaselist">
                                                                                </mat-slide-toggle>
                                                                            </div>
                                                                        </div>

                                                                        <!-- <div class="col-3" *ngIf = "updateMetaDataForm.value.updateFormProperties[i].is_databaselist">
                                                                            <div class="input-group-sm mb-3 input-group col">
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text">Key</span>
                                                                                </div>
                                                                                <mat-select  formControlName="key" id="key+i" name="key+i" attr.for="key+i" class="form-control" >
                                                                                    <mat-option *ngFor="let key of keys" [value]="key.key">
                                                                                        {{key.key}}</mat-option>
                                                                                </mat-select>
                                                                            </div>
                                                                        </div> -->
            
       
                                                                    </div>
            
                                                                </div>
                                                            </div>
                                                            <div class="col-1">
                                                                <mat-icon class="mat-icon-clear" title="Delete Property" (click)="deleteUpdateMetaDataForm(i)" style="transform: scale(.75);" *ngIf="updateFormPropertiesArray.controls.length > 1">
                                                                    delete
                                                                </mat-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="border p-2 mt-2" formGroupName="Permissions">
                                                <label for="permission" class="font-weight-bold ml-2">Permissions:
                                                </label>
                                                <div class="row">

                                                    <div *ngIf="copiedGroups" class="col-12">
                                                        <div class="input-group-sm input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">Copy Permissions from
                                                                    {{permissionName}} to</span>
                                                            </div>
                                                            <mat-select [(ngModel)]="commonPermissions" [ngModelOptions]="{standalone: true}" (ngModelChange)="commonPermissions" multiple class="form-control">
                                                                <div *ngFor="let permission of permissionsList |stringFilter:searchCtrl:'name'">
                                                                    <mat-option *ngIf="permissionName!=permission" [value]="permission">
                                                                        {{permission}}
                                                                    </mat-option>
                                                                </div>
                                                            </mat-select>
                                                            <mat-icon (click)="closeCopyGroups()">close</mat-icon>
                                                        </div>
                                                        <button mat-stroked-button (click)="cloneGroups()" style="margin: 2px;float: right;">Copy</button>
                                                    </div>


                                                    <div class="col-4">
                                                        <div class="input-group-sm mb-1 input-group">
                                                            <div class="input-group-prepend"><span class="input-group-text form-label-50">Add
                                                                    Form Data</span></div>
                                                            <mat-select [(ngModel)]="addFormDataPerm" formControlName="add_form_data" multiple name="add_form_data" class="form-control">

                                                                <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                    {{user}}
                                                                </mat-option>
                                                            </mat-select>

                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="input-group-sm mb-1 input-group ">
                                                            <div class="input-group-prepend"><span class="input-group-text form-label-50">Get
                                                                    Form Data</span></div>
                                                            <mat-select [(ngModel)]="getFormDataPerm" multiple name="get_form_data" formControlName="get_form_data" class="form-control">

                                                                <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                    {{user}}
                                                                </mat-option>
                                                            </mat-select>

                                                        </div>
                                                    </div>

                                                    <div class="col-4">
                                                        <div class="input-group-sm mb-1 input-group">
                                                            <div class="input-group-prepend"><span class="input-group-text form-label-50">Update
                                                                    Form Data</span></div>
                                                            <mat-select [(ngModel)]="updateFormDataPerm" multiple name="update_form_data" formControlName="update_form_data" class="form-control">

                                                                <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                    {{user}}
                                                                </mat-option>
                                                            </mat-select>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">

                                                    <div class="col-4">
                                                        <div class="input-group-sm mb-1 input-group ">
                                                            <div class="input-group-prepend"><span class="input-group-text form-label-50">Delete
                                                                    Form Data</span></div>
                                                            <mat-select [(ngModel)]="deleteFormDataPerm" multiple name="delete_form_data" formControlName="delete_form_data" class="form-control">

                                                                <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                    {{user}}
                                                                </mat-option>
                                                            </mat-select>

                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="input-group-sm mb-1 input-group">
                                                            <div class="input-group-prepend"><span class="input-group-text form-label-50">Create
                                                                    Form</span></div>
                                                            <mat-select [(ngModel)]="createFormPerm" multiple name="create_form" formControlName="create_form" class="form-control">

                                                                <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                    {{user}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="input-group-sm mb-1 input-group">
                                                            <div class="input-group-prepend"><span class="input-group-text form-label-50">Get
                                                                    Form</span></div>
                                                            <mat-select [(ngModel)]="getFormPerm" multiple name="get_form" formControlName="get_form" class="form-control">

                                                                <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                    {{user}}
                                                                </mat-option>
                                                            </mat-select>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">

                                                    <div class="col-4">
                                                        <div class="input-group-sm mb-1 input-group ">
                                                            <div class="input-group-prepend"><span class="input-group-text form-label-50">Update
                                                                    Form</span></div>
                                                            <mat-select [(ngModel)]="updateFormPerm" multiple name="update_form" formControlName="update_form" class="form-control">

                                                                <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                    {{user}}
                                                                </mat-option>
                                                            </mat-select>

                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="input-group-sm mb-1 input-group">
                                                            <div class="input-group-prepend"><span class="input-group-text form-label-50">Delete
                                                                    Form</span></div>
                                                            <mat-select [(ngModel)]="deleteFormPerm" multiple name="delete_form" formControlName="delete_form" class="form-control">

                                                                <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                    {{user}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="pt-2">
                                                <button mat-raised-button (click)="saveUpdateFormData(updateMetaDataForm.value)" class="actionButton " [disabled]="!updateMetaDataForm.valid">Update</button>
                                            </div>
                                        </form>
                                    </div>

                                    <div class="card mt-5" *ngIf="updatedForm">
                                        <div class="card-body">
                                            <div>Form <strong>{{fetchedFormName}} </strong>has been updated.</div>
                                            <button class="buttonclass" style="margin: 1em;" mat-raised-button (click)="back()">Back</button>
                                        </div>
                                    </div>

                                    <div class="card" *ngIf="isDeletedForm">
                                        <div class="card-body">
                                            <div>Form <strong>{{fetchedFormName}} </strong>has been deleted.</div>
                                            <button class="buttonclass" style="margin: 1em;" mat-raised-button (click)="onCloseCreate()">Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-2 m-2" *ngIf="addFormButton">
                                <div class="sticky-top" *ngIf="!isSubForm">
                                    <div class="Tabletitle">Create Form</div>
                                </div>
                                <div class="sticky-top" *ngIf="isSubForm">
                                    <div class="Tabletitle">Create Sub Form</div>
                                </div>
                                <div class=" mt-3 alert alert-danger" *ngIf="errorMsg">
                                    {{errorMsg}}
                                </div>
                                <div class=" mt-2" *ngIf="!createdForm">
                                    <form [formGroup]="metaDataForm">
                                        <div class="input-group mb-3 input-group col-6">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Name</span>
                                            </div>
                                            <input type="text" class="form-control" id="formname" name="formname" [(ngModel)]="catname" formControlName="Name">
                                        </div>

                                        <div class="card p-2 m-3 mb-2">
                                            <div formArrayName="formProperties">
                                                <div class="form-group row">
                                                    <span class="form-group ml-3 form-label-105">Properties</span>
                                                    <div class="col mb-2">
                                                        <button mat-stroked-button class="float-right" title="Add Form"
                                                        (click)="addNewProperty()">
                                                            <mat-icon>add</mat-icon>Add Property
                                                        </button>
                                                    </div>
                                                </div>
                                                <div *ngFor="let item of formPropertiesArray.controls; let i = index " [formGroupName]="i">
                                                    <div class="row">
                                                        <div class="col-11">
                                                            <div class="card p-2 mb-2">
                                                                <div class="input-group row">
                                                                    <div class="col-3">
                                                                        <div class="input-group-sm mb-3 input-group col">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">Name</span>
                                                                            </div>
                                                                            <input class="form-control" name="metaName" type="text" formControlName="name">
                                                                        </div>
                                                                    </div>
        
                                                                    <div class="col-3">
                                                                        <div class="input-group-sm mb-3 input-group col">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">Required</span>
                                                                            </div>
                                                                            <!-- <input class="form-control" type="text"
                                                                            formControlName="required"> -->
                                                                            <mat-slide-toggle class="pl-2 pr-2 pt-1" formControlName="required"></mat-slide-toggle>
                                                                        </div>
                                                                    </div>
        
                                                                    <div class="col-3">
                                                                        <div class="input-group-sm mb-3 input-group col">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">Displayable</span>
                                                                            </div>
                                                                            <mat-slide-toggle class="pl-2 pr-2 pt-1" formControlName="display_in_list"></mat-slide-toggle>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-3">
                                                                        <div class="input-group-sm mb-3 input-group col">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">Data Type</span>
                                                                            </div>
                                                                            <select class="form-control" id="metaDataType" formControlName="data_type">
                                                                                <option value="str"> String</option>
                                                                                <option value="int"> Integer</option>
                                                                                <option value="date"> Date</option>
                                                                                <option value="object"> Object</option>
                                                                                <option value="bool"> Boolean</option>
                                                                                <option value="str array">Multiple Values</option>
                                                                                <option value="options" disabled>Options</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>  
                                                                </div>
        
                                                                <div class="input-group row">
                                                                    <div class="col-3">
                                                                        <div class="input-group-sm mb-3 input-group col">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">Document</span>
                                                                            </div>
                                                                            <mat-slide-toggle  class="pl-2 pr-2 pt-1"
                                                                                formControlName="is_document">
                                                                            </mat-slide-toggle>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3" *ngIf = "metaDataForm.value.formProperties[i].is_document">
                                                                        <div class="input-group-sm mb-3 input-group col">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">Category</span>
                                                                            </div>
                                                                            <mat-select  formControlName="category_id" id="{{category_id}}" name="{{category_id}}" class="form-control" required>
                                                                                <mat-option *ngFor="let category of categories" [value]="category.id">
                                                                                    {{category.name}}</mat-option>
                                                                            </mat-select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-3">
                                                                        <div class="input-group-sm mb-3 input-group col">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">Databaselist</span>
                                                                            </div>
                                                                            <mat-slide-toggle class="pl-2 pr-2 pt-1" formControlName="is_databaselist">
                                                                            </mat-slide-toggle>
                                                                        </div>
                                                                    </div>

                                                                    <!-- <div class="col-3" *ngIf = "metaDataForm.value.formProperties[i].is_databaselist">
                                                                        <div class="input-group-sm mb-3 input-group col">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">Key</span>
                                                                            </div>
                                                                            <mat-select  formControlName="key" id="key+i" name="key+i" attr.for="key+i" class="form-control" >
                                                                                <mat-option *ngFor="let key of keys" [value]="key.key">
                                                                                    {{key.key}}</mat-option>
                                                                            </mat-select>
                                                                        </div>
                                                                    </div> -->

                                                                    <!-- <div class="col-3" *ngIf = "metaDataForm.value.formProperties[i].is_databaselist">
                                                                        <div class="col mb-2">
                                                                            <button mat-stroked-button class="float-right" title="Add Value"
                                                                            (click)="addNewValueProperty()">
                                                                                <mat-icon>add</mat-icon>Add Value
                                                                            </button>
                                                                        </div>
                                                                        <div *ngFor="let item of valueArray.controls; let j = index " [formGroupName]="j" >
                                                                            <div *ngIf = "i==j">
                                                                                <div class="input-group-sm mb-3 input-group col">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">Value</span>
                                                                                    </div>
                                                                                    <input formControlName="values" id="{{values+j}}" name="{{values+j}}" attr.for="{{values+j}}" class="form-control">
                                                                                </div>
                                                                            </div>
                                                                                                                                                       
                                                                        </div>
                                                                    </div> -->
        
   
                                                                </div>
        
                                                            </div>
                                                        </div>
                                                        <div class="col-1">
                                                            <mat-icon class="mat-icon-clear" title="Delete Property" (click)="deleteUpdateMetaDataForm(i)" style="transform: scale(.75);" *ngIf="updateFormPropertiesArray.controls.length > 1">
                                                                delete
                                                            </mat-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="border p-2" formArrayName="formProperties">
                                            <label for="metaData" class="font-weight-bold ml-2">Properties: </label>
                                            <button mat-stroked-button class="float-right" (click)="addNewProperty()">
                                                <mat-icon>add</mat-icon> Property
                                            </button>
                                            <div cdkDropList (cdkDropListDropped)="dropUpdate($event)">
                                                <div class="example-box" *ngFor="let item of formPropertiesArray.controls; let i = index " [formGroupName]="i" cdkDrag>
                                                    <div class="input-group-sm mb-1 input-group col">
                                                        <mat-icon style="cursor: move;">drag_indicator</mat-icon>
                                                        <div class="input-group-prepend"><span class="input-group-text">Name</span></div>
                                                        <input class="form-control" name="metaName" type="text" formControlName="name">
                                                        <div class="input-group-prepend"><span class="input-group-text">Required</span></div>
                                                        <mat-slide-toggle class="pl-2 pr-2 pt-1" formControlName="required"></mat-slide-toggle>
                                                        <div class="input-group-prepend"><span class="input-group-text">Document</span></div>
                                                        <mat-slide-toggle class="pl-2 pr-2 pt-1" formControlName="is_document"></mat-slide-toggle>
                                                        <div class="input-group-prepend"><span class="input-group-text">Displayable</span></div>
                                                        <mat-slide-toggle class="pl-2 pr-2 pt-1" formControlName="display_in_list"></mat-slide-toggle>
                                                        <div class="input-group-prepend"><span class="input-group-text">Data Type</span></div>
                                                        <select class="form-control" id="metaDataType" formControlName="data_type">
                                                            <option value="str"> String</option>
                                                            <option value="int"> Integer</option>
                                                            <option value="date"> Date</option>
                                                            <option value="object"> Object</option>
                                                            <option value="bool"> Boolean</option>
                                                            <option value="str array">Multiple Values</option>
                                                            <option value="options" disabled>Options</option>
                                                        </select>
                                                        <mat-icon class="mat-icon-clear" title="Delete Property" (click)="deleteMetaDataForm(i)" style="transform: scale(.75);" *ngIf="formPropertiesArray.controls.length > 1">
                                                            delete
                                                        </mat-icon>
                                                    </div>
                                                </div>
                                            </div>

                                        </div> -->

                                        <div class="border p-2 mt-2" formGroupName="Permissions">
                                            <label for="permission" class="font-weight-bold ml-2">Permissions:
                                            </label>
                                            <div class="row">

                                                <div *ngIf="copiedGroups" class="col-12">
                                                    <div class="input-group-sm input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">Copy Permissions from
                                                                {{permissionName}} to</span>
                                                        </div>
                                                        <mat-select [(ngModel)]="commonPermissions" [ngModelOptions]="{standalone: true}" (ngModelChange)="commonPermissions" multiple class="form-control">
                                                            <mat-option>
                                                                <ngx-mat-select-search [(ngModel)]="searchCtrl" [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'" name="search" [ngModelOptions]="{standalone: true}">
                                                                </ngx-mat-select-search>
                                                            </mat-option>
                                                            <div *ngFor="let permission of permissionsList |stringFilter:searchCtrl:'name'">
                                                                <mat-option *ngIf="permissionName!=permission" [value]="permission">
                                                                    {{permission}}
                                                                </mat-option>
                                                            </div>
                                                        </mat-select>
                                                        <mat-icon (click)="closeCopyGroups()">close</mat-icon>
                                                    </div>
                                                    <button mat-stroked-button (click)="cloneGroups()" style="margin: 2px;float: right;">Copy</button>
                                                </div>


                                                <div class="col-4">
                                                    <div class="input-group-sm mb-1 input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text form-label-50">Add
                                                                Form Data</span></div>
                                                        <mat-select [(ngModel)]="addFormDataPerm" formControlName="add_form_data" multiple name="add_form_data" class="form-control">
                                                            <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                {{user}}
                                                            </mat-option>
                                                        </mat-select>

                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="input-group-sm mb-1 input-group ">
                                                        <div class="input-group-prepend"><span class="input-group-text form-label-50">Get
                                                                Form Data</span></div>
                                                        <mat-select [(ngModel)]="getFormDataPerm" multiple name="get_form_data" formControlName="get_form_data" class="form-control">
                                                            <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                {{user}}
                                                            </mat-option>
                                                        </mat-select>

                                                    </div>
                                                </div>

                                                <div class="col-4">
                                                    <div class="input-group-sm mb-1 input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text form-label-50">Update
                                                                Form Data</span></div>
                                                        <mat-select [(ngModel)]="updateFormDataPerm" multiple name="update_form_data" formControlName="update_form_data" class="form-control">
                                                            <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                {{user}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">

                                                <div class="col-4">
                                                    <div class="input-group-sm mb-1 input-group ">
                                                        <div class="input-group-prepend"><span class="input-group-text form-label-50">Delete
                                                                Form Data</span></div>
                                                        <mat-select [(ngModel)]="deleteFormDataPerm" multiple name="delete_form_data" formControlName="delete_form_data" class="form-control">

                                                            <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                {{user}}
                                                            </mat-option>
                                                        </mat-select>

                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="input-group-sm mb-1 input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text form-label-50">Create
                                                                Form</span></div>
                                                        <mat-select [(ngModel)]="createFormPerm" multiple name="create_form" formControlName="create_form" class="form-control">

                                                            <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                {{user}}
                                                            </mat-option>
                                                        </mat-select>

                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="input-group-sm mb-1 input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text form-label-50">Get
                                                                Form</span></div>
                                                        <mat-select [(ngModel)]="getFormPerm" multiple name="get_form" formControlName="get_form" class="form-control">

                                                            <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                {{user}}
                                                            </mat-option>
                                                        </mat-select>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">

                                                <div class="col-4">
                                                    <div class="input-group-sm mb-1 input-group ">
                                                        <div class="input-group-prepend"><span class="input-group-text form-label-50">Update
                                                                Form</span></div>
                                                        <mat-select [(ngModel)]="updateFormPerm" multiple name="update_form" formControlName="update_form" class="form-control">

                                                            <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                {{user}}
                                                            </mat-option>
                                                        </mat-select>

                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="input-group-sm mb-1 input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text form-label-50">Delete
                                                                Form</span></div>
                                                        <mat-select [(ngModel)]="deleteFormPerm" multiple name="delete_form" formControlName="delete_form" class="form-control">

                                                            <mat-option *ngFor="let user of usersList | stringFilter:searchCtrl:'name'" [value]="user">
                                                                {{user}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div *ngIf="!selectedForm && !cloneChild">
                                            <div class="pt-2">
                                                <button mat-raised-button (click)="saveFormData(metaDataForm.value)" [disabled]="!metaDataForm.valid" class="actionButton" mat-button>Save</button>
                                            </div>
                                        </div>

                                        <div *ngIf="selectedForm && !cloneChild">
                                            <div class="pt-2">
                                                <button mat-raised-button (click)="saveSubFormData(metaDataForm.value)" class="actionButton" [disabled]="!metaDataForm.valid" mat-button>Save</button>
                                            </div>
                                        </div>

                                        <div *ngIf="cloneChild">
                                            <div class="pt-2">
                                                <button mat-raised-button (click)="saveSubFormData(metaDataForm.value)" class="actionButton" [disabled]="!metaDataForm.valid" mat-button>Save</button>
                                            </div>
                                        </div>

                                    </form>






                                </div>

                                <div class="card mt-5" *ngIf="createdForm">
                                    <div class="card-body">
                                        <div>Form <strong>{{fetchedFormName}}</strong> has been created.</div>
                                        <button class="buttonclass" style="margin: 1em;" mat-raised-button (click)="back()">Back</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>