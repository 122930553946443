<!-- <app-image-viewer *ngIf="imageViewer" [images]="[fileUrl]"
[idContainer]="'idOnHTML'"
[loadOnInit]="true">
</app-image-viewer> -->
    <iframe title="Document"  [src]="fileUrl | safe" width="100%" autosize
           style="height: calc(100vh - 50px);">
    </iframe>




<!--<web-viewer [documentURL]="fileUrl" *ngIf="fileUrl"></web-viewer>-->
<!--
<embed 
    src=safeUrl()
    width="250"
    height="200">
    -->