<div class="container-fluid mt-5">
    <mat-card class="mx-auto" style="width:350px">
        <mat-card-header>
            <mat-card-title>Login</mat-card-title>
        </mat-card-header>
        <div class="login">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <button  mat-raised-button style="background-color: #03277c;  border:double; color:rgb(255, 255, 255);" [disabled]="loading">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Login
                    </button>
                    <!-- <button mat-raised-button style="background-color: #03277c; width: 20%; height:40px; border:double; color:rgb(255, 255, 255); float:right">Login</button> -->
                    <!--<a routerLink="/register" class="btn btn-link">Register</a>-->
                </div>
            </form>
        </div>
    </mat-card>
</div>