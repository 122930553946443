import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../_models';
import { AuthenticationService } from '../../_services/authentication.service';
import { FormService } from 'src/app/_services/forms.service';
import { DatePipe } from '@angular/common'
import { UsersAndGroupsService } from 'src/app/_services/users-and-groups.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogErrorComponent } from 'src/app/dialog-error/dialog-error.component';
import { environment } from '../../../environments/environment';
import { DialogConfirmOSComponent } from 'src/app/forms/onboarding-form/dialog-confirm-os/dialog-confirm-os.component';

@Component({
  selector: 'app-initiate-onboarding',
  templateUrl: './initiate-onboarding.component.html',
  styleUrls: ['./initiate-onboarding.component.scss']
})
export class InitiateOnboardingComponent implements OnInit {
  isWorkflowActive: boolean = false
  currentUser: User;
  formUUID: any;
  OSValues : any
  isInitiateForAnotherResource: boolean= false
  submitted = false;
  registerForm: FormGroup;
  registerFormFormMyself: FormGroup
  date: any;
  projectStartDate: any;
  isOnboardingInitiated: boolean = false
  userGroups: string[] = []
  onboardedEmail: string;
  onboardingSpecialistValues: any
  workflowStatus: any;
  isAdmin: boolean = false
  currentDate: any
  alreadyOnboarded :boolean = null
  memberFirmValues: any
  values: any
  form: any

  nobgv: boolean = true;


  mailText:string
  environment: string
  fieldglassTQValues: any;
  reinitiated: boolean = false
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private formService: FormService,
    public datepipe: DatePipe,
    private userGroupService: UsersAndGroupsService,
  private fb: FormBuilder
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    
    this.userGroups = authenticationService.userGroupsValue;
    this.registerForm = this.fb.group({
      "Email": ['', [Validators.required, Validators.email,]],
      "Resource First Name": ['', [Validators.required]],
      "Resource Last Name": ['', [Validators.required]],
      "Onboarding Specialist": ['', [Validators.required]],
      "Resource Project Start Date": ['', [Validators.required]],
      "Member Firm": ['', [Validators.required]],
      "Laptop required": ['', [Validators.required]],
      "Access to VPN required": ['', [Validators.required]],
      "Badge required": ['', [Validators.required]],
      "Fieldglass TQ": ['', [Validators.required]]
  });

  this.registerFormFormMyself = this.fb.group({
    "Onboarding Specialist": ['', [Validators.required]],
    "Resource Project Start Date": ['', [Validators.required]],
    "Member Firm": ['', [Validators.required]],
    "Laptop required": ['', [Validators.required]],
    "Access to VPN required": ['', [Validators.required]],
    "Badge required": ['', [Validators.required]],
    "Fieldglass TQ": ['', [Validators.required]]
})
   }

  ngOnInit(): void {

    if(`${environment.formUrl}` == "https://paypalonboarding.docuedgeapidev.deloitte.com"){
      this.environment = "Dev"
    }

    else if(`${environment.formUrl}` == "https://paypalonboarding.docuedgeapi.deloitte.com"){
      this.environment = "Prod"
    }

    this.authenticationService.userGroups.subscribe(x=>{
      this.isAdmin = this.authenticationService.isAdminValue;
      this.groupCheck().then(()=>{
        if(x){
        this.isAdmin = this.authenticationService.isAdminValue;
          this.userGroups = x
          if(this.currentUser['email']){
            this.formService.getWorkflowStatus(this.currentUser['email'].trim().toLowerCase()).subscribe(resp=>{
              if(resp[0]['is_workflow_active']==true&&(this.isAdmin == false || this.isAdmin == null)){
                this.alreadyOnboarded = true
              }
              else {
                this.alreadyOnboarded = false
              }
              if(resp[0]['is_workflow_active'] == false){
                this.reinitiated = true
              }
              else{
                this.reinitiated = false
              }
            })
          }
        }
      })
      
      
      
    })
    this.currentDate = new Date();
    this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.mailText = "mailto:paypalonboarding@docuedgeapidev.deloitte.com"
    this.projectStartDate = new Date();
    this.values = {}
    this.projectStartDate = this.datepipe.transform(this.projectStartDate, 'yyyy-MM-dd');
      this.formService.getFormName('Initiate Onboarding Form').subscribe(form=>{
        this.form = form
        this.form.form_field_definition.properties.forEach(element => {
        if(element.name == "Onboarding Specialist"){
          this.onboardingSpecialistValues = element.values
        }
        if(element.name == "Member Firm"){
          this.memberFirmValues = element.values
        }
        if(element.name == "Fieldglass TQ"){
          this.fieldglassTQValues = element.values
        }
        })

        if(this.onboardingSpecialistValues){
          this.OSValues = []
          this.onboardingSpecialistValues.forEach(element => {
            var osValue = element.split("_")
            var OS = {
              "Name" : osValue[0]+ " " + osValue[1],
              "Email": osValue[2]
            }
            this.OSValues.push(OS)

          });
        }
      } 
      
        )

    


      
      
    this.registerForm.value.email = null
  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerFormFormMyself.controls; }

  initiateOnboarding(){
    
    this.isInitiateForAnotherResource = true
  }

  groupCheck(){
    return new Promise<void>((resolve)=>{
      if(this.userGroups){
        this.userGroups.forEach(group => {
          switch (group) {
              case "dms-admin": this.isAdmin = true;
                  break;
          }
      })
      }
      
    resolve()
    })
  }

  saveForm(){
    this.submitted = true;

    

    // stop here if form is invalid
    if (this.registerForm.invalid) {
       return;
    }
  }

  getFieldGlassDependencies(value, field){ 
    console.log("HERE")
    
    
    if(field=='Fieldglass TQ'){
      console.log("Here in fielglass") 
    this.formService.getKey(value).subscribe(resp=>{
      this.form.form_field_definition.properties.forEach(element => {
      var keys = Object.keys(resp['value'])
      
      keys.forEach(keyValue => {
        if(element.name == keyValue){
          console.log("keys", keys)
      console.log("ELEMENT", this.registerForm.value[element.name])
      console.log("ELEMENT Value", resp['value'][keyValue])

      this.registerForm.get(element['name']).setValue(resp['value'][keyValue])
      this.registerFormFormMyself.get(element['name']).setValue(resp['value'][keyValue])

          //this.registerForm.value[element['name']] = resp['value'][keyValue]

        }
      });
        
      });
    })}
    }

  onSubmit() {

    console.log("LOWER CASE EMAIL", this.registerForm.value['Email'].trim().toLowerCase())
    this.submitted = true;
    

    
    //stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    
    else if(this.registerForm.value['Badge required'] == false && this.registerForm.value['Access to VPN required'] == false && this.registerForm.value['Laptop required'] == false){
      return;
    }

    else if (!this.registerForm.value['Email'].toLowerCase().includes('@deloitte')) {
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: {message: "Please enter a valid Deloitte email!" }, panelClass: 'custom-modalbox' });
      return;
  }

    else if (this.registerForm.value['Resource Project Start Date'] < this.currentDate) {
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: {message: "Please refrain from entering a past date!" }, panelClass: 'custom-modalbox' });
      return;
  }
  else if (this.registerForm.value['Resource Project Start Date'] > "2050-01-01") {
    let dialogRef = this.dialog.open(DialogErrorComponent,
      { width: '50%', height:'25%', data: {message: "Please enter a valid date!" }, panelClass: 'custom-modalbox' });
    return;
}

else if(this.registerForm.value.Email.trim().toLowerCase() == this.currentUser['email'].trim().toLowerCase()){

  let dialogRef = this.dialog.open(DialogConfirmOSComponent,
    { width: '50%', height:'25%', data: {message: "Are you sure you want to initiate onboarding request for yourself?" }, panelClass: 'custom-modalbox' });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {           
        this.initiateForAnotherResource()   
      }
      else{
        return
      }
    })

}

else{
  this.initiateForAnotherResource()
}


}

initiateForAnotherResource(){

  this.formService.getWorkflowStatus(this.registerForm.value.Email.trim().toLowerCase()).subscribe(resp=>{
    // this.isWorkflowActive = resp
    this.workflowStatus =[]
    this.workflowStatus = resp
    if(resp[0]['is_workflow_active'] == false){
      this.reinitiated = true
    }
    else{
      this.reinitiated = false
    }

    if(resp[0]['is_workflow_active'] == true){
      this.isWorkflowActive = true
      this.onboardedEmail = this.registerForm.value.Email
      return
    }
    else if ((resp[0]['is_workflow_active'] == "No Profile Found" || resp[0]['is_workflow_active'] == false) && this.isAdmin ){
      var dateArray = this.registerForm.value['Resource Project Start Date'].split("-")
      var startdate = dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0]
      
      var osValue = this.registerForm.value['Onboarding Specialist'].split("- ")[1]
      var apiRequest = {
        "form_id": this.form['id'],
        "email": this.registerForm.value.Email.trim().toLowerCase(),
        "formfield_value": {
          "Email": this.registerForm.value['Email'].trim().toLowerCase(),
          "Resource First Name": this.registerForm.value['Resource First Name'],
          "Resource Last Name": this.registerForm.value['Resource Last Name'],          
          "Onboarding Specialist": this.registerForm.value['Onboarding Specialist'],
          "Resource Project Start Date": startdate,
          "Member Firm": this.registerForm.value['Member Firm'],
          "Laptop required": this.registerForm.value['Laptop required'],
          "Access to VPN required": this.registerForm.value['Access to VPN required'],
          "Badge required": this.registerForm.value['Badge required'],
          "Fieldglass TQ": this.registerForm.value['Fieldglass TQ']

        }
      }
      this.date=new Date();
      let latest_date =this.datepipe.transform(this.date, 'MM/dd/yyyy');
      this.formService.populateForm(apiRequest).subscribe(resp=>{
        this.formService.getKey(this.registerForm.value['Member Firm']).subscribe(key=>{


          const memberFirm = this.registerForm.value['Member Firm'];
          if (memberFirm === 'US' || memberFirm === 'USI') {
              this.nobgv = true; // Set nobgv to true for US and USI, otherwise false
          } else {
              this.nobgv = false;
          }

          this.formUUID = resp.profileId
          if(this.reinitiated == true){
            var workflowRequest = {
              "firstname": this.registerForm.value['Resource First Name'],
              "lastname": this.registerForm.value['Resource Last Name'],
              "Email": this.registerForm.value.Email.trim().toLowerCase(),
              "status": true,
              "onboardingdate":  latest_date,
              "formguid": this.formUUID,
              "bgvmailid": key['value']['Background Email'].trim().toLowerCase(),
              "OnboardingSpecialist": this.registerForm.value['Onboarding Specialist'],
              "Environment": this.environment,
              "memberfirm": this.registerForm.value['Member Firm'],
              "projectstartdate": startdate,
              "nobgv": this.nobgv

            }
          }
          else{
            var workflowRequest = {
              "firstname": this.registerForm.value['Resource First Name'],
              "lastname": this.registerForm.value['Resource Last Name'],
              "Email": this.registerForm.value.Email,
              "status": false,
              "onboardingdate":  latest_date,
              "formguid": this.formUUID,
              "bgvmailid": key['value']['Background Email'].trim().toLowerCase(),
              "OnboardingSpecialist": this.registerForm.value['Onboarding Specialist'],
              "Environment": this.environment,
              "memberfirm": this.registerForm.value['Member Firm'],
              "projectstartdate": startdate,
              "nobgv": this.nobgv,
            }
          }
  
        this.formService.initateOnboarding(workflowRequest).subscribe(resp=>{
          this.isOnboardingInitiated = true
          this.submitted = false
          this.isWorkflowActive = false
        })
        })
        
  
      })
    }
  })

}

initiateForMyself(){

  const memberFirm = this.registerFormFormMyself.value['Member Firm'];
  if (memberFirm === 'US' || memberFirm === 'USI') {
    this.nobgv = true; // Set nobgv to true for US and USI, otherwise false
  } else {
    this.nobgv = false;
  }

  this.submitted = true;
this.registerFormFormMyself.value.Email = this.currentUser['email'].trim().toLowerCase()

  //stop here if form is invalid
  if (this.registerFormFormMyself.invalid) {
      return;
  }


  if(this.registerFormFormMyself.value['Badge required'] == false && this.registerFormFormMyself.value['Access to VPN required'] == false && this.registerFormFormMyself.value['Laptop required'] == false){
    return;
  }

  if (this.registerFormFormMyself.value['Resource Project Start Date'] < this.currentDate) {
    let dialogRef = this.dialog.open(DialogErrorComponent,
      { width: '50%', height:'25%', data: {message: "Please refrain from entering a past date!" }, panelClass: 'custom-modalbox' });
    return;
}
if (this.registerFormFormMyself.value['Resource Project Start Date'] > "2050-01-01") {
  let dialogRef = this.dialog.open(DialogErrorComponent,
    { width: '50%', height:'25%', data: {message: "Please enter a valid date!" }, panelClass: 'custom-modalbox' });
  return;
}
      var dateArray = this.registerFormFormMyself.value['Resource Project Start Date'].split("-")
      var startdate = dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0]

      var OSValue = this.registerFormFormMyself.value['Onboarding Specialist'].split("- ")[1]
      if(this.currentUser['firstName'] == "" || this.currentUser['lastName'] == ""){
        var apiRequest1 = {
          "form_id": this.form['id'],
          "email": this.currentUser['email'].trim().toLowerCase(),
          "formfield_value": {
            "Email":this.currentUser['email'].trim().toLowerCase(),
            "Resource First Name": this.currentUser['username'],
            "Resource Last Name": this.currentUser['username'],          
            "Onboarding Specialist": this.registerFormFormMyself.value['Onboarding Specialist'],
            "Resource Project Start Date": startdate,
              "Member Firm": this.registerFormFormMyself.value['Member Firm'],
              "Laptop required": this.registerFormFormMyself.value['Laptop required'],
            "Access to VPN required": this.registerFormFormMyself.value['Access to VPN required'],
            "Badge required": this.registerFormFormMyself.value['Badge required'],
            "Fieldglass TQ": this.registerFormFormMyself.value['Fieldglass TQ']
          }
        }
      }
      else{
        var apiRequest1 = {
          "form_id": this.form['id'],
          "email": this.currentUser['email'].trim().toLowerCase(),
          "formfield_value": {
            "Email":this.currentUser['email'].trim().toLowerCase(),
            "Resource First Name": this.currentUser['firstName'],
            "Resource Last Name": this.currentUser['lastName'],          
            "Onboarding Specialist": this.registerFormFormMyself.value['Onboarding Specialist'],
            "Resource Project Start Date": startdate,
              "Member Firm": this.registerFormFormMyself.value['Member Firm'],
              "Laptop required": this.registerFormFormMyself.value['Laptop required'],
            "Access to VPN required": this.registerFormFormMyself.value['Access to VPN required'],
            "Badge required": this.registerFormFormMyself.value['Badge required'],
            "Fieldglass TQ": this.registerFormFormMyself.value['Fieldglass TQ']
          }
        }
      }
      
      this.date=new Date();
      let latest_date =this.datepipe.transform(this.date, 'MM/dd/yyyy');
      this.formService.populateForm(apiRequest1).subscribe(resp=>{
        this.formService.getKey(this.registerFormFormMyself.value['Member Firm']).subscribe(key=>{
          this.formUUID = resp.profileId
          if(this.reinitiated == true){
            var workflowRequest = {
              "firstname": apiRequest1.formfield_value['Resource First Name'],
              "lastname": apiRequest1.formfield_value['Resource Last Name'], 
              "Email": this.currentUser['email'].trim().toLowerCase(),
              "status": true,
              "onboardingdate":  latest_date,
              "formguid": this.formUUID,
              "bgvmailid": key['value']['Background Email'].trim().toLowerCase(),
              "OnboardingSpecialist": this.registerFormFormMyself.value['Onboarding Specialist'],
              "Environment": this.environment,
              "memberfirm": this.registerFormFormMyself.value['Member Firm'],
              "projectstartdate": startdate
            }
          }
          else{
            var workflowRequest = {
              "firstname": apiRequest1.formfield_value['Resource First Name'],
              "lastname": apiRequest1.formfield_value['Resource Last Name'], 
              "Email": this.currentUser['email'].trim().toLowerCase(),
              "status": false,
              "onboardingdate":  latest_date,
              "formguid": this.formUUID,
              "bgvmailid": key['value']['Background Email'].trim().toLowerCase(),
              "OnboardingSpecialist":  this.registerFormFormMyself.value['Onboarding Specialist'],
              "Environment": this.environment,
              "memberfirm": this.registerFormFormMyself.value['Member Firm'],
              "projectstartdate": startdate
              
            }
          }
          
          this.formService.initateOnboarding(workflowRequest).subscribe(resp=>{
            this.isOnboardingInitiated = true
          })
        })
       
  
      })
}

cancel(){
  this.registerForm.reset()
  this.isOnboardingInitiated = false
  this.isInitiateForAnotherResource = false
  this.ngOnInit()
}



}
