import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/_models';
import { SearchCountryField,  CountryISO } from 'ngx-intl-tel-input';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CategoryService } from 'src/app/_services/category.service';
import { DocumentService } from 'src/app/_services/document.services';
import { FormService } from 'src/app/_services/forms.service';
import { UserService } from 'src/app/_services/user.service';
import { UsersAndGroupsService } from 'src/app/_services/users-and-groups.service';
import { AlertService } from 'src/app/_services/alert.service';
import { DialogConfirmUploadComponent } from 'src/app/dialog-confirm-upload/dialog-confirm-upload.component';
import { DatePipe } from '@angular/common';
import { DialogViewDocComponent } from 'src/app/dialog-view-doc/dialog-view-doc.component';
import { DialogFilePreviewComponent } from '../dialog-file-preview/dialog-file-preview.component';
import { DialogErrorComponent } from 'src/app/dialog-error/dialog-error.component';




@Component({
  selector: 'app-new-resource-form',
  templateUrl: './new-resource-form.component.html',
  styleUrls: ['./new-resource-form.component.scss']
})
export class NewResourceFormComponent implements OnInit {
  	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	// TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
  @Input() resourceDetails = new EventEmitter<any>()
  currentUser: User
  form: any
  searchCtrl: string
  resourceForm: FormGroup
  values: any[];
  properties: any;
  resume: File = null;
  // NDA: File = null;
  // AUP:File = null;
  // CSP: File = null;
  docId: any
  // NDAdocId: any
  backgroundEmail: string
  osEmail: string
  resumedocId:any
  // CSPdocId: any
  // AUPdocId: any
  resumeLoaded: boolean = false
  // NDALoaded: boolean = false
  // CSPLoaded: boolean = false
  // AUPLoaded: boolean = false
  resumeUploaded: boolean = false
  // NDAUploaded: boolean = false
  // CSPUploaded: boolean = false
  // AUPUploaded: boolean = false
  resumeCategory: string;
  // NDACategory: string;
  // AUPCategory: string;
  // CSPCategory: number
  projectStartDate: any
  currentDate; any
  isPPMDApprovalRequired: boolean = false
  isNewResourceSubmitted: boolean = false
  formSaveMessage: boolean = false
  @Output() hideNewResource = new EventEmitter<boolean>();

  constructor(    
    private documentService: DocumentService,
    private authenticationService: AuthenticationService,
    private categoryService: CategoryService,
    private userService: UserService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private fb: FormBuilder,
    private alertService: AlertService,
    private formService: FormService,
    private usersGroupsService: UsersAndGroupsService,) {

      this.currentUser = this.authenticationService.currentUserValue
     }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');

    this.projectStartDate = new Date();
    this.projectStartDate = this.datepipe.transform(this.projectStartDate, 'yyyy-MM-dd');
    
    this.formService.getProfile(this.resourceDetails['profileid']).subscribe(fields=>{
      this.formService.getFormName('New Resource Form').subscribe(form=>{
        this.form = form
        
        this.values = Object.assign(fields['form_value']['properties'])
        this.getFieldGlassDependencies(this.values['Fieldglass TQ'], 'Fieldglass TQ')
        this.checkDocumentCategory()
        // if(this.values['NDA Confidentiality IP'] != null){
        //   this.NDAdocId = this.values['NDA Confidentiality IP']
        //   this.NDALoaded = true
        // }
        if(this.values['Resume'] != null){
          this.resumedocId = this.values['Resume']
          this.resumeLoaded = true
        }
        // if(this.values['Contractor Privacy Statement'] != null){
        //   this.CSPdocId = this.values['Contractor Privacy Statement']
        //   this.CSPLoaded = true
        // }
        // if(this.values['Acceptable Use Policy'] != null){
        //   this.AUPdocId = this.values['Acceptable Use Policy']
        //   this.AUPLoaded = true
        // }
        this.values['Resource Project Start Date'] = this.datepipe.transform(this.values['Resource Project Start Date'], 'yyyy-MM-dd')
        this.values['Contract End Date'] = this.datepipe.transform(this.values['Contract End Date'], 'yyyy-MM-dd')
        this.properties = {}
      } 
        )
    })

      
  

  }

  // checkPDFFields(file){
  //   console.log(file);
  // }

  checkDocumentCategory(){
    this.form.form_field_definition.properties.forEach(element => {
      if(this.values[element.name]==" " && element.data_type == "str"){
        this.values[element.name] = null
      }
      // if(element.name == "NDA Confidentiality IP"){
      //   this.NDACategory = element.category_id
      // }
      if(element.name == "Resume"){
        this.resumeCategory = element.category_id
      }
      // if(element.name == "Contractor Privacy Statement"){
      //   this.CSPCategory = element.category_id
      // }
      // if(element.name == "Acceptable Use Policy"){
      //   this.AUPCategory = element.category_id
      // }
    });
  }

  back() {
    this.hideNewResource.emit(false);
  }

  handleFileInput(files: FileList, element) {
       
    // if(element == "NDA Confidentiality IP"){
    //   this.NDALoaded = true
    //   this.NDA = files.item(0);
    //   let dialogRef = this.dialog.open(DialogFilePreviewComponent,
    //     { width: '600px', height:'80%', data: { type: element, payload: element, file:this.NDA }, panelClass: 'custom-modalbox' });
    //     dialogRef.afterClosed().subscribe(result => {
    //       if (result) {
    //         this.checkPDFFields(this.AUP);
    //         this.uploadDocument(element,this.NDA)
    //         // this.ngOnInit()
            
    //       }
    //     })
    // }
    if(element == "Resume"){
      this.resumeLoaded = true
      this.resume = files.item(0);
      let dialogRef = this.dialog.open(DialogFilePreviewComponent,
        { width: '600px', height:'80%', data: { type: element, payload: element, file:this.resume }, panelClass: 'custom-modalbox' });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
         
            this.uploadDocument(element,this.resume)
            // this.ngOnInit()
            
          }
        })
    }
    // else if (element == "Contractor Privacy Statement"){
    //   this.CSPLoaded = true
    //   this.CSP = files.item(0);
    //   let dialogRef = this.dialog.open(DialogFilePreviewComponent,
    //     { width: '600px', height:'80%', data: { type: element, payload: element, file:this.CSP }, panelClass: 'custom-modalbox' });
    //     dialogRef.afterClosed().subscribe(result => {
    //       if (result) {
    //         this.uploadDocument(element,this.CSP)
    //         // this.ngOnInit()
            
    //       }
    //     })
    // }
    // else if (element == "Acceptable Use Policy"){
    //   this.AUPLoaded = true
    //   this.AUP = files.item(0);
    //   let dialogRef = this.dialog.open(DialogFilePreviewComponent,
    //     { width: '600px', height:'80%', data: { type: element, payload: element, file:this.AUP }, panelClass: 'custom-modalbox' });
    //     dialogRef.afterClosed().subscribe(result => {
    //       if (result) {
    //         this.uploadDocument(element,this.AUP)
    //         // this.ngOnInit()
            
    //       }
    //     })
    // }

   
    
  }

  uploadDocument(fileName,file){

    // if(fileName == 'NDA Confidentiality IP'){

    //   var meta_data = {
    //     "Username": this.resourceDetails['Email'],
    //     "Document Type": fileName
    //   }
    
    //   const formData = new FormData();
    //   formData.append("title", fileName);
    //   formData.append("category_id", String(this.NDACategory));
    //   formData.append("meta_data", JSON.stringify(meta_data));
    //   formData.append("file", file);
    // this.documentService.uploadDocumentDirect(formData).subscribe(resp=>{

    //   this.NDAdocId = resp['body']["docId"];
    //   this.properties['NDA Confidentiality IP'] = this.NDAdocId
    //   this.NDAUploaded = false
    //   this.formSaveMessage = false
    //   this.populateForm("save")
    
    
    // })
    // }

    if(fileName == 'Resume'){

      var meta_data = {
        "Username": this.resourceDetails['Email'],
        "Document Type": fileName
      }
    
      const formData = new FormData();
      formData.append("title", fileName);
      formData.append("category_id", String(this.resumeCategory));
      formData.append("meta_data", JSON.stringify(meta_data));
      formData.append("file", file);
    this.documentService.uploadDocumentDirect(formData).subscribe(resp=>{

      this.resumedocId = resp['body']["docId"];
      this.properties['Resume'] = this.resumedocId
      this.resumeUploaded = true
      this.formSaveMessage = false
      this.populateForm("save")
    
    
    })
    }
    // if(fileName == 'Contractor Privacy Statement'){
    //   var meta_data = {
    //     "Username": this.resourceDetails['Email'],
    //     "Document Type": fileName
    //   }
    
    //   const formData = new FormData();
    //   formData.append("title", fileName);
    //   formData.append("category_id", String(this.CSPCategory));
    //   formData.append("meta_data", JSON.stringify(meta_data));
    //   formData.append("file", file);
    // this.documentService.uploadDocumentDirect(formData).subscribe(resp=>{

    //   this.CSPdocId = resp['body']["docId"];
    //   this.properties['Contractor Privacy Statement'] = this.CSPdocId
    //   this.CSPUploaded = false
    //   this.formSaveMessage = false
    //   this.populateForm("save")
      
    
    // })
    // }
    // if(fileName == 'Acceptable Use Policy'){
    //   var meta_data = {
    //     "Username": this.resourceDetails['Email'],
    //     "Document Type": fileName
    //   }
    
    //   const formData = new FormData();
    //   formData.append("title", fileName);
    //   formData.append("category_id", String(this.AUPCategory));
    //   formData.append("meta_data", JSON.stringify(meta_data));
    //   formData.append("file", file);
    // this.documentService.uploadDocumentDirect(formData).subscribe(resp=>{

    //   this.AUPdocId = resp['body']["docId"];
    //   this.properties['Acceptable Use Policy'] = this.AUPdocId
    //   this.AUPUploaded = false
    //   this.formSaveMessage = false
    //   this.populateForm("save")
      
    
    // })
    // }
    
  }
  saveForm(action){
    
  
    this.values['Onboarding Specialist'] = this.values['Onboarding Specialist'].trim().toLowerCase()
    this.values['Email'] = this.values['Email'].trim().toLowerCase()
    console.log("VALUESS", this.values)
    if(action == "submit"){
      if ( this.values['Email'].includes(' ')) {
        let dialogRef = this.dialog.open(DialogErrorComponent,
          { width: '50%', height:'25%', data: {message: "Please enter a valid resource email address!" }, panelClass: 'custom-modalbox' });
        return;
    }
  
      if (this.values['Onboarding Specialist'].includes(' ')) {
        let dialogRef = this.dialog.open(DialogErrorComponent,
          { width: '50%', height:'25%', data: {message: "Please enter a valid onboarding specialist email address!" }, panelClass: 'custom-modalbox' });
        return;
    }
    }

    
    if (this.values['Contract End Date'] < this.currentDate) {
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: {message: "Please enter a valid contract end date!" }, panelClass: 'custom-modalbox' });
      return;
  }
    if (this.values['Contract End Date'] > "2050-01-01") {
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: {message: "Please enter a valid contract end date!" }, panelClass: 'custom-modalbox' });
      return;
  }
    if (this.values['Resource Project Start Date'] < this.currentDate) {
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: {message: "Please enter a valid resource project date!" }, panelClass: 'custom-modalbox' });
      return;
  }
    if (this.values['Resource Project Start Date'] > "2050-01-01") {
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: {message: "Please enter a valid resource project date!" }, panelClass: 'custom-modalbox' });
      return;
  }

  if (this.values['Entity'] == 'Subcontractor' && this.values['Comment']=='') {

    let dialogRef = this.dialog.open(DialogErrorComponent,
      { width: '50%', height:'25%', data: {message: "if subcontractor, please include subcontractor name & Deloitte subcontractor coordinator in the comments" }, panelClass: 'custom-modalbox' });
    return;
}
this.form.form_field_definition.properties.forEach((obj, index) => {
  console.log("HEREEEEE")
   if (obj.data_type === "date") {
    if (this.values[obj.name] == '' || this.values[obj.name] == null) {
      //this.properties[obj.name] = undefined
      delete this.properties[obj.name]
    } else {
      var dateArray = this.values[obj.name].split("-")
      var date = dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0]
      this.properties[obj.name] = date;
    }
  }
  else if (obj.data_type == "bool") {
    
    if (this.values[obj.name] == '' || this.values[obj.name] == null) {
      //this.properties[obj.name] = undefined
      delete this.properties[obj.name]
    }
    if (this.values[obj.name] == true) {
      this.properties[obj.name] = this.values[obj.name]
    }
    else if (this.values[obj.name]==false){
      this.properties[obj.name] = false
    }
    else if (this.values[obj.name]=="false"){
      this.properties[obj.name] = false
    }
    else if (this.values[obj.name]=="true"){
      this.properties[obj.name] = true
    }
  }
  else if (obj.data_type === "str") {
    if (this.values[obj.name] == '' || this.values[obj.name] == null) {
      this.properties[obj.name] = ' '
    }
    else {
      this.properties[obj.name] = this.values[obj.name]
    }
  }
  else {
    this.properties[obj.name] = this.values[obj.name];
  }

  if (obj.name === "Resume"){
    this.properties[obj.name] = this.resumedocId
  }
  console.log(index+1,this.form.form_field_definition.properties.length);
  if(index+1==this.form.form_field_definition.properties.length){
    this.formSaveMessage = true
    this.populateForm(action)
  }


})
  }


  viewDocument(document){
    var documentId = document
    this.documentService.getDocumentDetails(documentId).subscribe(resp => {
      let dialogRef = this.dialog.open(DialogViewDocComponent,
        { width: '50%', height:'95%', data: { type: "view", payload: documentId, version: resp.activeVersion }, panelClass: 'custom-modalbox' });
    })
    
  }



getFieldGlassDependencies(value, field){

if(field=='Entity' && (value == "Subcontractor" )){
  let dialogRef = this.dialog.open(DialogErrorComponent,
    { width: '50%', height:'25%', data: {message: "If subcontractor, please include subcontractor name & Deloitte subcontractor coordinator in the comments" }, panelClass: 'custom-modalbox' });
}

if(field=='Entity' && (value == "US" || value == "USI")){
  this.formService.getKey(value).subscribe(resp=>{
    this.backgroundEmail = resp['value']['Background Email'].trim().toLowerCase()

    this.form.form_field_definition.properties.forEach(element => {
    var keys = Object.keys(resp['value'])
    keys.forEach(keyValue => {
      if(element.name == keyValue){
        this.values[element['name']] = resp['value'][keyValue]
      }
    });
      
    });
  })
}

if(field=='Fieldglass TQ'){
this.formService.getKey(value).subscribe(resp=>{
  this.form.form_field_definition.properties.forEach(element => {
  var keys = Object.keys(resp['value'])
  keys.forEach(keyValue => {
    if(element.name == keyValue){
      this.values[element['name']] = resp['value'][keyValue]
      if (element.data_type == "date") {
        
        if (this.values[element.name] == '' || this.values[element.name] == null) {

        } else {
          var dateArray = this.values[element.name].split("/")

          var dateValue = dateArray[2]+ "-"+ dateArray[0]+ "-" + dateArray[1]

          this.values[element.name] = dateValue
        }
      }
    }
  });
    
  });
})}
}

populateForm(action){
  if (this.properties['Laptop required'] == false){
    delete this.properties['Address Line 1']
    delete this.properties['Address Line 2']
    delete this.properties['City']
    delete this.properties['State']
    delete this.properties['Country']
    delete this.properties['Zip Code']
    delete this.properties['Mobile Number']

  }
  if (this.properties['Resume'] == false){
    delete this.properties['Resume']

  }

  if(action == 'save'){
    if(this.properties['Mobile Number'] == null){
      delete this.properties['Mobile Number']
    }
    if(this.properties['Phone Number'] == null){
      delete this.properties['Phone Number']
    }
    var populateReqSave = {
      "email": this.resourceDetails['Email'],
      "form_id": this.form['id'],
      "formfield_value":this.properties,
      "submit_form": false
    }
    this.formService.populateForm(populateReqSave).subscribe(form=>{
      this.formService.getProfile(this.resourceDetails['profileid']).subscribe(fields=>{
        this.values['Resume'] = fields['form_value']['properties']['Resume']
        // this.values['NDA Confidentiality IP'] = fields['form_value']['properties']['NDA Confidentiality IP']
        // this.values['Contractor Privacy Statement'] = fields['form_value']['properties']['Contractor Privacy Statement']
        // this.values['Acceptable Use Policy'] = fields['form_value']['properties']['Acceptable Use Policy']
      })

      if(this.formSaveMessage){
        let dialogRef = this.dialog.open(DialogErrorComponent,
          { width: '40%', height:'25%', data: {message: "The form has been saved!" }, panelClass: 'custom-modalbox' });
      }     
  })
  }
  // if(this.NDAdocId && this.CSPdocId && this.AUPdocId && action == 'submit'){
    if(action == 'submit'){
    var populateReq = {
      "email": this.resourceDetails['Email'],
      "form_id": this.form['id'],
      "formfield_value":this.properties
    }

    // if(this.properties['Member Firm'] == "Australia"){
    //   this.isPPMDApprovalRequired = true
    // }
    // else{
    //   this.isPPMDApprovalRequired = false
    // }
    this.formService.populateForm(populateReq).subscribe(form=>{
        this.formService.getKey(this.properties['Member Firm']).subscribe(key=>{
          this.backgroundEmail = key['value']['Background Email'].trim().toLowerCase()
          var workflowRequest = {
            "UserID": this.resourceDetails['Email'],
            "bgvmailid": this.backgroundEmail, 
            "Owner": this.resourceDetails['Email'],
            "FormID": form.profileId,
            "PPMDRequired": false,
            "OnboardingSpecialist": this.properties['Onboarding Specialist'],
            "projectstartdate": this.properties['Resource Project Start Date'],
            "memberfirm": this.properties['Member Firm']
          }
      this.formService.submitNewResourceWorkflow(workflowRequest, this.resourceDetails['taskid']).subscribe(resp=>{
        this.isNewResourceSubmitted = true
      })
          
        })
      
      

      

    })
  }
}

}
