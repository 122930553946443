
<mat-card style = "background-color:#042c8a; text-align: center; color: white">
    <strong>Background Verification Form</strong>

</mat-card>

<div *ngIf="!isNewResourceSubmitted">
<div *ngIf="form">
<form #initiateForm="ngForm" style="margin-top: 2%;">
    <div *ngFor="let field of form.form_field_definition.properties; index as i;" style="display: inline-block; width:25%;">
        <div *ngIf="field.required==true" >
            <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values  && field.name != 'Email'" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'"
                    type="text" [(ngModel)]="values[field.name]"
                    required>
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values  && field.name == 'Email'" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'"
                    type="text" [(ngModel)]="values[field.name]" readonly
                    required>
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false  && values['Laptop required'] == true && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}* <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'"
                    type="text" [(ngModel)]="values[field.name]"
                    required>
            </div>
            <div *ngIf="field.data_type=='int' && field.is_document==false  && field.display_in_list == true && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}} *</span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'"
                     type="number"
                    [(ngModel)]="values[field.name]" required>
            </div>
            <div *ngIf="field.data_type=='int' && field.is_document==false && values['Laptop required'] == true && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}} *</span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="number"
                    [(ngModel)]="values[field.name]" required>
            </div>
            <div *ngIf="field.data_type=='date' && field.is_document==false  && !field.values && field.name == 'Background Check Date'" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="date" min="{{latestDate}}" max="{{currentDate}}" [(ngModel)]="values[field.name]" [ngClass]="values[field.name]?'':'empty-input'"
                    required>
            </div>
            <div *ngIf="field.data_type=='date' && field.is_document==false  && !field.values && field.name == 'Resource Project Start Date'" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}} *</span>
                </div>
                <input class="form-control" [ngClass]="values[field.name]?'':'empty-input'" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="date" min="{{currentDate}}" [(ngModel)]="values[field.name]" (ngModelChange)="values[field.name]" (change)="getDate(values[field.name])"
                     required>
            </div>
            <div *ngIf="field.data_type=='bool' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}</span>
                </div>
                <mat-slide-toggle class="ml-2" name="{{field.name}}" [(ngModel)]="values[field.name]">
                </mat-slide-toggle>
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==true && values[field.name] != null" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <!-- <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span> -->
                    
                    <input style="display: none" type="file" (change)="handleFileInput($event.target.files, field.name)" #fileInput>
                    <div class="dropdown" >
                        <button class="dropdown-toggle" mat-raised-button type="button" style = "font-size:x-small; width: 175px;background-color:rgb(8, 39, 141); color: rgb(255, 255, 255)"data-toggle="dropdown"><strong>{{field.name}} *</strong>
                        <span class="caret"></span></button>
                        <!-- <span class="input-group-text dropdown-toggle" style="width:188px; font-size:x-small; min-height:100%" data-toggle="dropdown">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon><span class="caret"></span></span> -->
                        <div class="dropdown-content">
                            <div><button class="btn btn-primary" mat-raised-button type="button" (click)="viewDocument(values[field.name])"  style="margin-top:0%;width: 165%; font-size:x-small"><strong>View Document</strong></button></div>
                            <div><button class="btn btn-primary" mat-raised-button type="button" (click)="fileInput.value='';fileInput.click()" style="margin-top:1%; width: 165%; font-size:x-small"><strong>Upload Document</strong></button></div>
                        </div>
                      </div>
                </div>
                
                    <!-- <input type="file" class="form-control" style="font-size:x-small; min-height: 34px" (change)="handleFileInput($event.target.files, field.name)" required> -->
                    <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 37px"
                    type="text" [(ngModel)]="values[field.name]" readonly 
                    required>
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==true && values[field.name] == null" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <!-- <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span> -->
                    <input style="display: none" type="file" (change)="handleFileInput($event.target.files, field.name)" #fileInput>
                    <div class="dropdown" >
                        <button class="dropdown-toggle" mat-raised-button type="button" style = "font-size:x-small; width: 170px;background-color:#042c8a; color: rgb(255, 255, 255)"data-toggle="dropdown"><strong>{{field.name}} *</strong>
                        <span class="caret"></span></button>
                        <!-- <span class="input-group-text dropdown-toggle" style="width:188px; font-size:x-small; max-height:90%" data-toggle="dropdown">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon><span class="caret"></span></span> -->
                        <div class="dropdown-content">
                            <div><button class="btn btn-primary" mat-raised-button type="button" (click)="fileInput.value='';fileInput.click()" style="margin-top:1%; width: 165%; font-size:x-small"><strong>Upload Document</strong></button></div>
                        </div>
                      </div>
                </div>
                
                    <!-- <input type="file" class="form-control" style="font-size:x-small; min-height: 34px" (change)="handleFileInput($event.target.files, field.name)" required> -->
                    <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 37px; "
                    type="text" [(ngModel)]="values[field.name]" readonly [ngClass]="values[field.name]?'':'empty-input'"
                    required>
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false && field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}} *</span>
                </div>
                <mat-select 
                class="form-control" [(ngModel)]="values[field.name]" style="font-size:x-small; min-height: 31px" [ngModelOptions]="{standalone: true}"> 
                <mat-option
                   *ngFor="let value of field.values" [value]="value">
                    {{value}}
                </mat-option>
            </mat-select>
            </div>
        </div>
        <div *ngIf="field.required==false" >
            <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values && field.name == 'Comment' " class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}<mat-icon  *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <textarea class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                type="text" [(ngModel)]="values[field.name]" readonly></textarea>
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values && field.name == 'Email' " class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}<mat-icon  *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                    type="text" [(ngModel)]="values[field.name]" readonly
                    >
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values && field.name != 'Comment' && field.name != 'Email'" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}<mat-icon  *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                    type="text" [(ngModel)]="values[field.name]"
                    >
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false  && values['Laptop required'] == true && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}</span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                    type="text" [(ngModel)]="values[field.name]"
                    >
            </div>
            <div *ngIf="field.data_type=='int' && field.is_document==false  && field.display_in_list == true && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}</span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="number"
                    [(ngModel)]="values[field.name]" >
            </div>
            <div *ngIf="field.data_type=='int' && field.is_document==false && values['Laptop required'] == true && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}</span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="number"
                    [(ngModel)]="values[field.name]">
            </div>
            <div *ngIf="field.data_type=='date' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}</span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="date" [(ngModel)]="values[field.name]"
                    >
            </div>
            <div *ngIf="field.data_type=='bool' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}</span>
                </div>
                <mat-slide-toggle class="ml-2" name="{{field.name}}" [(ngModel)]="values[field.name]">
                </mat-slide-toggle>
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==true" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}</span>
                </div>
                    <input type="file" class="form-control" style="font-size:x-small; min-height: 31px" (change)="handleFileInput($event.target.files, field.name)">
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false && field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}</span>
                </div>
                <mat-select style="font-size:x-small; min-height: 31px"
                class="form-control" [(ngModel)]="values[field.name]" [ngModelOptions]="{standalone: true}"> 
                <mat-option
                   *ngFor="let value of field.values" [value]="value">
                    {{value}}
                </mat-option>
            </mat-select>
            </div>
        </div>
    </div>
    <div>
        <button mat-button class="actionButton float-right m-2 mt-3" style="font-size:x-small; background-color:rgb(53, 53, 53); color:rgb(255, 255, 255)"(click)="back()">Back</button>
        <button class="actionButton float-right m-2 mt-3" style="font-size:x-small" (click)="saveForm('save')" mat-raised-button type="submit"
            >Save</button>
        <button class="actionButton float-right m-2 mt-3" style="font-size:x-small; background: #d0ebff; color: black" (click)="saveForm('submit')" mat-raised-button type="submit"[disabled]="!initiateForm.valid || BAFLoaded == false || this.values['Background Check Date'] == null"
            >Submit</button>
        <!-- <button class="actionButton float-right m-2" mat-raised-button type="submit" (click)="saveForm()" 
            >Save</button> -->

    </div>
</form>
</div>
</div>

<div *ngIf="isNewResourceSubmitted">
    <mat-card class="mt-7" style = "background-color:#B5D9F3; text-align: center; margin-top: 10%; margin-left:25%; margin-right:25%; ">
        <div style="padding-top: 3%;"><strong>Your form has been submitted</strong></div>
        <button mat-button style="background-color: #03277c; color:white; margin-left:80%" (click)="back()">Ok</button>
    </mat-card>
</div>

<div *ngIf="!isNewResourceSubmitted" style = "text-align: center; margin-top: 10%; margin-left:25%; margin-right:25%; font-size:small">
<i>*Click <a  (click)="downloadSampleFilled()"  style="color: blue; cursor:pointer"><u>here</u></a> to download a sample background verification form</i>
</div>
<div *ngIf="!isNewResourceSubmitted" style = "text-align: center; margin-top: 1%; margin-left:25%; margin-right:25%; font-size:small">
<i>*Click <a (click)="downloadSample()" style="color: blue; cursor:pointer"><u>here</u></a> to download the latest background verification form to be uploaded</i>
</div>




