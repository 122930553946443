<div>
    <mat-tab-group>
        <!-- <mat-tab label="Forms">
            <ng-template matTabContent>
                <div>
                    <app-forms></app-forms>
                </div>
            </ng-template>
        </mat-tab> -->
        <mat-tab label="Report Load">
            <ng-template matTabContent>
                <div>
                    <app-report-load></app-report-load>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
