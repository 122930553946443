import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { HttpModule } from '@angular/http';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppRoutingModule } from './app-routing.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { JwtInterceptor, ErrorInterceptor, SafePipe, StripDisplayPipe } from './_helpers';
import { DatePipe } from '@angular/common';
import { StringFilterPipe } from './_helpers/string-filter.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { HomeComponent } from './home/home.component';
import { MatToolbarModule } from '@angular/material/toolbar'
import { LoginComponent } from './login/login.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule} from '@angular/material/menu';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { DashboardComponent} from './home/dashboard/dashboard.component';
import { ReferenceLinksComponent } from './home/reference-links/reference-links.component';
import { InitiateOnboardingComponent } from './home/initiate-onboarding/initiate-onboarding.component';
import { WorkItemComponent } from './work-item/work-item.component';
import { AdminComponent } from './admin/admin.component';
import { FileViewComponent } from './file-view/file-view.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsComponent } from './admin/forms/forms.component';
import { AlertComponent } from './_components';

import * as FileSaver from 'file-saver';
import { DialogConfirmDeleteComponent } from './dialog-confirm-delete/dialog-confirm-delete.component';
import { DialogUploadComponent } from './reference-links/dialog-upload/dialog-upload.component';
import { FilePreviewComponent } from './home/reference-links/file-preview/file-preview.component';
import { UploadComponent } from './home/reference-links/upload/upload.component';
import { NewResourceFormComponent } from './forms/new-resource-form/new-resource-form.component';
import { BackgroundFormComponent } from './forms/background-form/background-form.component';
import { ConfirmPPMDFormComponent } from './forms/confirm-ppmd-form/confirm-ppmd-form.component';
import { OnboardingFormComponent } from './forms/onboarding-form/onboarding-form.component';
import { DialogConfirmUploadComponent } from './dialog-confirm-upload/dialog-confirm-upload.component';
import { FaqComponent } from './faq/faq.component';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { DialogViewDocComponent } from './dialog-view-doc/dialog-view-doc.component';
import { UploadDocumentVersionComponent } from './forms/onboarding-form/upload-document-version/upload-document-version.component';
import { FieldGlassFormComponent } from './forms/field-glass-form/field-glass-form.component';
import { ReportsComponent } from './reports/reports.component';
import { ConfirmOSComponent } from './confirm-os/confirm-os.component';
import { DialogConfirmOSComponent } from './forms/onboarding-form/dialog-confirm-os/dialog-confirm-os.component';
import { TabDashboardComponent } from './tab-dashboard/tab-dashboard.component';
import { TaskQueuesComponent } from './tab-dashboard/task-queues/task-queues.component';
import { DialogErrorComponent } from './dialog-error/dialog-error.component';
import { DialogFilePreviewComponent } from './forms/dialog-file-preview/dialog-file-preview.component';
import { DialogDateCheckComponent } from './dialog-date-check/dialog-date-check.component';
import { ReportLoadComponent } from './admin/report-load/report-load.component'


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    DashboardComponent,
    ReferenceLinksComponent,
    InitiateOnboardingComponent,
    WorkItemComponent,
    AdminComponent,
    FileViewComponent,
    SafePipe,
    StripDisplayPipe,
    StringFilterPipe,
    FormsComponent,
    DialogConfirmDeleteComponent,
    DialogUploadComponent,
    FilePreviewComponent,
    UploadComponent,
    NewResourceFormComponent,
    BackgroundFormComponent,
    ConfirmPPMDFormComponent,
    OnboardingFormComponent,
    DialogConfirmUploadComponent,
    AlertComponent,
    FaqComponent,
    SsoLoginComponent,
    DialogViewDocComponent,
    UploadDocumentVersionComponent,
    FieldGlassFormComponent,
    ReportsComponent,
    ConfirmOSComponent,
    DialogConfirmOSComponent,
    TabDashboardComponent,
    TaskQueuesComponent,
    DialogErrorComponent,
    DialogFilePreviewComponent,
    DialogDateCheckComponent,
    ReportLoadComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgHttpLoaderModule.forRoot(),
    MatToolbarModule,
    DragDropModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    Ng2TelInputModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTreeModule,
    NgxIntlTelInputModule,
    MatNativeDateModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSelectModule,
    NgxMatSelectSearchModule

    


  ],
  exports:[SafePipe],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
  bootstrap: [AppComponent]
})
export class AppModule { }
