<div style="height:calc(100vh)">
<mat-card *ngIf="!openUpload" style = "background-color:#042c8a; text-align: center; color: white">
    <strong>Fieldglass Admin Verification Form</strong>

</mat-card>

<div *ngIf="!isNewResourceSubmitted && !openUpload">
<div *ngIf="form">
<form #initiateForm="ngForm" style="margin-top: 1%;">
    <div *ngFor="let field of form.form_field_definition.properties; index as i;" style="display: inline-block; width:25%;">
        <div *ngIf="field.required==true" >
            <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}}* <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                    type="text" [(ngModel)]="values[field.name]" readonly
                    required>
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false  && values['Laptop required'] == true && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                    type="text" [(ngModel)]="values[field.name]" readonly
                    required>
            </div>
            <div *ngIf="field.data_type=='int' && field.is_document==false  && field.display_in_list == true && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="number"
                    [(ngModel)]="values[field.name]" readonly required>
            </div>
            <div *ngIf="field.data_type=='int' && field.is_document==false && values['Laptop required'] == true && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="number"
                    [(ngModel)]="values[field.name]" readonly required>
            </div>

            <div *ngIf="field.data_type=='object' && field.is_document==false && field.display_in_list == true && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" readonly style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="text"
                    [(ngModel)]="values[field.name]['internationalNumber']" readonly required>
                <!-- <ngx-intl-tel-input [cssClass]="'custom'" readonly
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [maxLength]="10"
                [phoneValidation]="true"
                [separateDialCode]="true"
                name="phone" name="{{field.name}}"
                [(ngModel)]="values[field.name]" required>
            </ngx-intl-tel-input> -->
            </div>
            <div *ngIf="field.data_type=='date' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" readonly style="font-size:x-small; min-height: 31px"
                     type="date" [(ngModel)]="values[field.name]"
                    required>
            </div>
            <div *ngIf="field.data_type=='bool' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} *<mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                    type="text" [(ngModel)]="values[field.name]" readonly
                    required>
                <!-- <mat-select class="form-control" name="{{field.name}}" readonly [(ngModel)]="values[field.name]" style="font-size:x-small; min-height: 31px" required>
                  <mat-option  [value]=true >
                    Yes
                  </mat-option>
                  <mat-option  [value]=false >
                    No
                  </mat-option>
                </mat-select> -->

            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==true" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                    <input type="file" class="form-control" readonly style="font-size:x-small; min-height: 31px" (change)="handleFileInput($event.target.files, field.name)">
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false && field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                    type="text" [(ngModel)]="values[field.name]" readonly
                    required>
                <!-- <mat-select 
                class="form-control" [(ngModel)]="values[field.name]" readonly style="font-size:x-small; min-height: 31px" [ngModelOptions]="{standalone: true}" required (selectionChange)="getFieldGlassDependencies(values[field.name], field.name)" (ngModelChange)="values[field.name]"> 
                <mat-option>
                    <ngx-mat-select-search [(ngModel)]="searchCtrl"
                        [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'"
                        name="{{field.name}}" id="{{field.name}}" [ngModelOptions]="{standalone: true}" >
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option
                   *ngFor="let value of field.values |stringFilter:searchCtrl:'{{field.name}}'" [value]="value">
                    {{value}}
                </mat-option>
            </mat-select> -->
            </div>           
        </div>
        <div *ngIf="field.required==false" >
            <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values && field.name != 'Review Comment'" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}}<mat-icon  *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" readonly style="font-size:x-small; min-height: 31px"
                type="text" [(ngModel)]="values[field.name]"
                >
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false  && values['Laptop required'] == 'Yes' && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} *<mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" readonly style="font-size:x-small; min-height: 31px"
                    type="text" [(ngModel)]="values[field.name]"
                    required>
            </div>

            <div *ngIf="field.data_type=='int' && field.is_document==false  && field.display_in_list == true && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="number" readonly
                    [(ngModel)]="values[field.name]" >
            </div>
            <div *ngIf="field.data_type=='int' && field.is_document==false && values['Laptop required'] == 'Yes' && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} *<mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" readonly style="font-size:x-small; min-height: 31px"
                     type="number"
                    [(ngModel)]="values[field.name]" required>
            </div>
            <div *ngIf="field.data_type=='object' && field.is_document==false && values['Laptop required'] == 'Yes' && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} *<mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="text"
                    [(ngModel)]="values[field.name]['internationalNumber']" readonly required>
                <!-- <ngx-intl-tel-input [cssClass]="'custom'" readonly
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [maxLength]="10"
                [phoneValidation]="true"
                [separateDialCode]="true"
                name="phone" name="{{field.name}}"
                [(ngModel)]="values[field.name]" required>
            </ngx-intl-tel-input> -->
            </div>
            <div *ngIf="field.data_type=='date' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <input class="form-control" readonly name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="date" [(ngModel)]="values[field.name]"
                    >
            </div>
            <div *ngIf="field.data_type=='bool' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <!-- <mat-slide-toggle class="ml-2" name="{{field.name}}" [(ngModel)]="values[field.name]">
                </mat-slide-toggle> -->
                <!-- <mat-select class="form-control" readonly name="{{field.name}}" style="font-size:x-small; min-height: 31px" [(ngModel)]="values[field.name]" >
                    <mat-option  [value]=true >
                      Yes
                    </mat-option>
                    <mat-option  [value]=false >
                      No
                    </mat-option>
                  </mat-select> -->

                  <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                    type="text" [(ngModel)]="values[field.name]" readonly
                    required>
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==true" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}}</span><mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon>
                </div>
                    <input type="file" class="form-control" readonly (change)="handleFileInput($event.target.files, field.name)" style="font-size:x-small; min-height: 31px">
            </div>
            <div *ngIf="field.data_type=='str' && field.is_document==false && field.values" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                <mat-select style="font-size:x-small; min-height: 31px" readonly
                class="form-control" [(ngModel)]="values[field.name]" [ngModelOptions]="{standalone: true}" (selectionChange)="getFieldGlassDependencies(values[field.name], field.name)" (ngModelChange)="values[field.name]"> 
                <mat-option>
                    <ngx-mat-select-search [(ngModel)]="searchCtrl"
                        [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'"
                        name="{{field.name}}" id="{{field.name}}" [ngModelOptions]="{standalone: true}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option
                   *ngFor="let value of field.values |stringFilter:searchCtrl:'{{field.name}}'" [value]="value">
                    {{value}}
                </mat-option>
            </mat-select>
            </div>
        </div>
    </div>
    <div style="padding-left:1%;">
        <div  style = "float:right;">

            <button mat-button class="float-right ml-3" style= "font-size:x-small; background-color:rgb(53, 53, 53); color:white" (click)="back()">Back</button>
            <button *ngIf="resourceDetails['claimed'] == false" class="float-right  ml-3" style = "font-size:x-small" (click)="claimTask()" mat-raised-button type="submit"
            >Claim Task</button>
            <button *ngIf="resourceDetails['claimed'] == true" class="float-right  ml-3" style = "font-size:x-small" (click)="releaseTask()" mat-raised-button type="submit"
            >Release Task</button>
        <button *ngIf="resourceDetails['claimed'] == true" class="actionButton float-right " style = "font-size:x-small" (click)="saveForm('approve')" mat-raised-button type="submit"
            >Submit</button>
            <!-- <button class="btn btn-primary dropdown-toggle" mat-raised-button type="button" style = "font-size:x-small; width: 150px;background-color:rgb(0, 0, 0); color: rgb(255, 255, 255)"data-toggle="dropdown"><strong>Actions</strong>
            <span class="caret"></span></button>
            <div class="dropdown-content">
                <div><button class="btn btn-primary" mat-raised-button type="button" type="submit" [disabled]="!initiateForm.valid || (values['Fieldglass TQ'] == null || values['SOW Worker Role'] == null || values['PayPal Role'] == null || values['Work Site'] == null || values['Entity'] == null ||  values['Function'] == null || values['Member Firm'] == null)" (click)="saveForm('approve')" style="margin-top:1%; width: 100%; font-size:x-small"><strong>Approve</strong></button></div>
                <div><button class="btn btn-primary" mat-raised-button type="button" (click)="back()" style="margin-top:1%; width: 150px; font-size:x-small"><strong>Cancel</strong></button></div>
            </div> -->
          </div>
          <div *ngIf="allDocuments.form_field_definition">
    <div *ngFor="let document of allDocuments.form_field_definition.properties; index as i;" style="display: inline-block;">
        
        <div *ngIf = "document['name'] == 'Resume' && values['Temp Worker'] == 'Yes'">
            <input style="display: none" type="file" (change)="openUploadForm($event.target.files, document)" #fileInput>
            <div class="dropdown" >
                <button class="btn btn-primary dropdown-toggle" mat-raised-button type="button" style = "font-size:x-small; width: 170px;background-color:rgb(8, 39, 141); color: rgb(255, 255, 255)"data-toggle="dropdown"><strong>{{document.name}}</strong>
                <span class="caret"></span></button>
                <div class="dropdown-content">
                    <div><button class="btn btn-primary" mat-raised-button type="button" (click)="viewDocument(document)"  style="margin-top:0%;width: 135%; font-size:x-small"><strong>View Document</strong></button></div>
                    <div><button class="btn btn-primary" mat-raised-button type="button" (click)="downloadDocument(document)"  style="margin-top:1%;width: 135%; font-size:x-small"><strong>Download Document</strong></button></div>
                </div>
              </div>
        </div>

        <div *ngIf = "document['name'] != 'Resume' && document['display_in_list']">
            <input style="display: none" type="file" (change)="openUploadForm($event.target.files, document)" #fileInput>
            <div class="dropdown" >
                <button class="btn btn-primary dropdown-toggle" mat-raised-button type="button" style = "font-size:x-small; width: 170px;background-color:rgb(8, 39, 141); color: rgb(255, 255, 255)"data-toggle="dropdown"><strong>{{document.name}}</strong>
                <span class="caret"></span></button>
                <div class="dropdown-content">
                    <div><button class="btn btn-primary" mat-raised-button type="button" (click)="viewDocument(document)"  style="margin-top:0%;width: 135%; font-size:x-small"><strong>View Document</strong></button></div>
                    <div><button class="btn btn-primary" mat-raised-button type="button" (click)="downloadDocument(document)"  style="margin-top:1%;width: 135%; font-size:x-small"><strong>Download Document</strong></button></div>
                </div>
              </div>
        </div>
        
        
    </div>
</div>

</div>
</form>
</div>
</div>

<div *ngIf="isNewResourceSubmitted && !openUpload">
    <mat-card class="mt-7" style = "background-color:#B5D9F3; text-align: center; margin-top: 10%; margin-left:25%; margin-right:25%; height:100px;">
        <div style="padding-top: 3%;"><strong>{{message}}</strong></div>
        <button mat-button style="background-color: #03277c; color:white" class="float-right"(click)="back()">Ok</button>
    </mat-card>
</div>

</div>

<div *ngIf="openUpload">
    <app-upload-document-version  [fileToUpload]="fileToUpload" [selectedDocument]="selectedDocument" (openUpload)="backfromUpload($event)"></app-upload-document-version >
</div>





