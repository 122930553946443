import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Category, CategoryNode, NewCategory } from '../_models';

@Injectable({ providedIn: 'root' })
export class CategoryService {
    constructor(private http: HttpClient) { }

    getAll(): Observable<Category[]> {
        return this.http.get<Category[]>(`${environment.apiUrl}/categories?offset=0&limit=1000`);
    }

    get(categoryId): Observable<Category> {
        return this.http.get<Category>(`${environment.apiUrl}/categories/` + categoryId)
            .pipe(
            );
    }

    getTree(): Observable<CategoryNode[]> {
        return this.http.get<CategoryNode[]>(`${environment.apiUrl}/categories/tree`)
            .pipe()
    }

    getCategoriesEdit(): Observable<Category[]> {
        return this.http.get<Category[]>(`${environment.apiUrl}/categories/edit?offset=0&limit=500`);
    }

    createCategory(req: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/categories/create`, req).pipe(map(obj => { }),
            (catchError(this.errorHandler))

        )
    }
    updateCategory(req: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/categories/update`, req)
            .pipe(
                map(obj => { }

                )
            )
    }

    deleteCategory(payload) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: payload
        }
        return this.http.delete(`${environment.apiUrl}/categories/delete`, options).pipe()
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error);
    }
}