import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CategoryService } from 'src/app/_services/category.service';
import { DocumentService } from 'src/app/_services/document.services';
import { FormService } from 'src/app/_services/forms.service';
import { SearchCountryField,  CountryISO } from 'ngx-intl-tel-input';
import { UserService } from 'src/app/_services/user.service';
import { UsersAndGroupsService } from 'src/app/_services/users-and-groups.service';
import { AlertService } from 'src/app/_services/alert.service';
import { DatePipe } from '@angular/common';
import { DialogViewDocComponent } from 'src/app/dialog-view-doc/dialog-view-doc.component';
import { DialogConfirmOSComponent } from './dialog-confirm-os/dialog-confirm-os.component';
import { DialogFilePreviewComponent } from '../dialog-file-preview/dialog-file-preview.component';
import { DialogErrorComponent } from 'src/app/dialog-error/dialog-error.component';

@Component({
  selector: 'app-onboarding-form',
  templateUrl: './onboarding-form.component.html',
  styleUrls: ['./onboarding-form.component.scss']
})
export class OnboardingFormComponent implements OnInit {
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	// TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  @Input() resourceDetails = new EventEmitter<any>()
  currentUser: User
  form: any
  resourceForm: FormGroup
  values: any[];
  properties: any;
  NDA: File = null;
  AUP:File = null;
  CSP: File = null;
  resume: File = null
  docId: any
  NDAdocId: any
  CSPdocId: any
  resumedocId: string
  isPreview: boolean = false
  AUPdocId: any
  resumeUploaded: boolean = false
  NDAUploaded: boolean = false
  CSPUploaded: boolean = false
  AUPUploaded: boolean = false
  isPPMDApprovalRequired: boolean = false
  isNewResourceSubmitted: boolean = false
  isViewDocument: boolean = false
  openUpload: boolean = false
  allDocuments: any = []
  docValues: any;
  message: string = null
  selectedDocument: any
  fieldglassEmail: string
  fileToUpload: File = null
  backgroundEmail: string
  currentDate: any

  @Output() hideNewResource = new EventEmitter<boolean>();

  constructor(    
    private documentService: DocumentService,
    private authenticationService: AuthenticationService,
    private categoryService: CategoryService,
    public datepipe: DatePipe,
    private userService: UserService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private alertService: AlertService,
    private formService: FormService,
    private usersGroupsService: UsersAndGroupsService,) {

      this.currentUser = this.authenticationService.currentUserValue
     }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');

    console.log("RESOURCE DETAILS", this.resourceDetails)
    this.formService.getProfile(this.resourceDetails['profileid']).subscribe(fields=>{
      this.formService.getFormName('Onboarding Specialist Form').subscribe(form=>{
        this.formService.getFormName('Onboarding Specialist Documents').subscribe(documents=>{
          this.allDocuments = documents
          this.docValues = Object.assign(fields['form_value']['properties'])
        })
        this.form = form

        this.values = Object.assign(fields['form_value']['properties'])
        this.checkFields()
        this.values['Resource Project Start Date'] = this.datepipe.transform(this.values['Resource Project Start Date'], 'yyyy-MM-dd');
        this.values['Background Check Date'] = this.datepipe.transform(this.values['Background Check Date'], 'yyyy-MM-dd');
        this.values['Contract End Date'] = this.datepipe.transform(this.values['Contract End Date'], 'yyyy-MM-dd');
        this.properties = {}
      } 
        )
    })

      
  

  }

  back() {
    this.hideNewResource.emit(false);
  }

  checkFields(){
    this.form.form_field_definition.properties.forEach(element => {
      if(this.values[element.name]==" " && element.data_type == "str"){
        this.values[element.name] = null
      }
    });
  }


  getFieldGlassDependencies(value, field){
    if(field=='Entity' && (value == "Subcontractor" )){
        let dialogRef = this.dialog.open(DialogErrorComponent,
          { width: '50%', height:'25%', data: {message: "If subcontractor, please include subcontractor name & Deloitte subcontractor coordinator in the comments" }, panelClass: 'custom-modalbox' });
      }

    if(field=='Entity' && (value == "US" || value == "USI") ){
      this.formService.getKey(value).subscribe(resp=>{
        this.form.form_field_definition.properties.forEach(element => {
        var keys = Object.keys(resp['value'])
        keys.forEach(keyValue => {
          if(element.name == keyValue){
            this.values[element['name']] = resp['value'][keyValue]
          }
        });
          
        });
      })
    }
    
    if(field=='Fieldglass TQ'){
    this.formService.getKey(value).subscribe(resp=>{
      this.form.form_field_definition.properties.forEach(element => {
      var keys = Object.keys(resp['value'])
      keys.forEach(keyValue => {
        if(element.name == keyValue){
          this.values[element['name']] = resp['value'][keyValue]
          if (element.data_type == "date") {
            if (this.values[element.name] == '' || this.values[element.name] == null) {
    
            } else {
              var dateArray = this.values[element.name].split("/")
              var dateValue = dateArray[0].split(" ")
              this.values[element.name] = dateValue[0]
            }
          }
        }
      });
        
      });
    })}
    }

  saveForm(action){
this.form.form_field_definition.properties.forEach((obj, index) => {
   if (obj.data_type === "date") {
    if (this.values[obj.name] == '' || this.values[obj.name] == null) {
      this.properties[obj.name] = undefined
    } else {
      var dateArray = this.values[obj.name].split("-")
      var date = dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0]
      this.properties[obj.name] = date;
    }
  }
  else if (obj.data_type == "bool") {
    this.properties[obj.name] = false
    if (this.values[obj.name]) {
      this.properties[obj.name] = this.values[obj.name]
    }
  }
  else if (obj.data_type === "str") {
    if (this.values[obj.name] == '' || this.values[obj.name] == null) {
      this.properties[obj.name] = ' '
    }
    else {
      this.properties[obj.name] = this.values[obj.name]
    }
  }
  else {
    this.properties[obj.name] = this.values[obj.name];
  }

  if(index + 1 == this.form.form_field_definition.properties.length ){
    this.populateForm(action)
   }
 })
 

 

  }

  viewDocument(document){
    this.isPreview = true
    var documentId = this.values[document['name']]
    this.documentService.getDocumentDetails(documentId).subscribe(resp => {
      let dialogRef = this.dialog.open(DialogViewDocComponent,
        { width: '50%', height:'95%', data: { type: "view", payload: documentId, version: resp.activeVersion }, panelClass: 'custom-modalbox' });
    })
    
  }

  openConfirm(action){
    this.values['Onboarding Specialist'] = this.values['Onboarding Specialist'].trim().toLowerCase()
    this.values['Email'] = this.values['Email'].trim().toLowerCase()
    if(action == "approve"||action=="resource"||action=="background"|| action=="reject"){
      if ( this.values['Email'].includes(' ')) {
        let dialogRef = this.dialog.open(DialogErrorComponent,
          { width: '50%', height:'25%', data: {message: "Please enter a valid resource email address!" }, panelClass: 'custom-modalbox' });
        return;
    }
  
      if ( this.values['Onboarding Specialist'].includes(' ')) {
        let dialogRef = this.dialog.open(DialogErrorComponent,
          { width: '50%', height:'25%', data: {message: "Please enter a valid onboarding specialist email address!" }, panelClass: 'custom-modalbox' });
        return;
    }
    }

    if(action=="resource"||action=="background"){  
      if (this.values['Comment'] == '' || this.values['Comment'] == null) {
        let dialogRef = this.dialog.open(DialogErrorComponent,
          { width: '50%', height:'25%', data: {message: "Please enter a comment citing the reason why the application is sent back!" }, panelClass: 'custom-modalbox' });
        return;
    }
    }

    if((action == "resource" || action == "background") && this.values['Onboarding Specialist'] == null || this.values['Onboarding Specialist'] == ''){
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: { action: action, message: "Please enter an onboarding specialist!" }, panelClass: 'custom-modalbox' });
      return;
    }
    if(action == "reject" && (this.values['Onboarding Specialist'] == null || this.values['Onboarding Specialist'] == '')){
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: { action: action, message: "Please enter an onboarding specialist!" }, panelClass: 'custom-modalbox' });
      return;
    }
    if(action == "reject"&& (this.values['Resource First Name'] == null || this.values['Resource First Name'] == '')){
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: { action: action, message: "Please enter a resource first name!" }, panelClass: 'custom-modalbox' });
      return;
    }
    if(action == "reject" && (this.values['Resource Last Name'] == null || this.values['Resource Last Name'] == '')){
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'25%', data: { action: action, message: "Please enter a resource last name!" }, panelClass: 'custom-modalbox' });
      return;
    }


    if (this.values['Contract End Date'] < this.currentDate) {
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'20%', data: {message: "Please refrain from entering a past date!" }, panelClass: 'custom-modalbox' });
      return;
  }
  if (this.values['Contract End Date'] > "2050-01-01") {
    let dialogRef = this.dialog.open(DialogErrorComponent,
      { width: '50%', height:'20%', data: {message: "Please enter a valid contract end date!" }, panelClass: 'custom-modalbox' });
    return;
    }
    if (this.values['Background Check Date'] > this.currentDate) {
      let dialogRef = this.dialog.open(DialogErrorComponent,
        { width: '50%', height:'20%', data: {message: "Please refrain from entering a future background check date!" }, panelClass: 'custom-modalbox' });
      return;
  }
if (this.values['Background Check Date'] > this.values['Resource Project Start Date']) {
  let dialogRef = this.dialog.open(DialogErrorComponent,
    { width: '50%', height:'20%', data: {message: "Please refrain from entering a background check date greater than project start date!" }, panelClass: 'custom-modalbox' });
  return;
}
  if (this.values['Resource Project Start Date'] < this.currentDate) {
    let dialogRef = this.dialog.open(DialogErrorComponent,
      { width: '50%', height:'20%', data: {message: "Please refrain from entering a past date!" }, panelClass: 'custom-modalbox' });
    return;
}
  if (this.values['Resource Project Start Date'] > "2050-01-01") {
    let dialogRef = this.dialog.open(DialogErrorComponent,
      { width: '50%', height:'20%', data: {message: "Please enter a valid resource project date!" }, panelClass: 'custom-modalbox' });
    return;
}
if (this.values['Entity'] == 'Subcontractor' && this.values['Comment']=='') {
  let dialogRef = this.dialog.open(DialogErrorComponent,
    { width: '50%', height:'25%', data: {message: "if subcontractor, please include subcontractor name & Deloitte subcontractor coordinator in the comments" }, panelClass: 'custom-modalbox' });
  return;
}
    if(action == "approve"){
      let dialogRef = this.dialog.open(DialogConfirmOSComponent,
        { width: '50%', height:'25%', data: { action: action, message: "Are you sure you wish to approve the form for the resource?" }, panelClass: 'custom-modalbox' });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.saveForm("approve")
            // this.ngOnInit()
            
          }
        })
    }
    if(action == "save"){
      let dialogRef = this.dialog.open(DialogConfirmOSComponent,
        { width: '50%', height:'25%', data: { action: action, message: "Are you sure you wish to save this form?" }, panelClass: 'custom-modalbox' });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.saveForm("save")
            // this.ngOnInit()
            
          }
        })
    }
    else if(action == "resource"){
      let dialogRef = this.dialog.open(DialogConfirmOSComponent,
        { width: '50%', height:'25%', data: { action: action, message: "Are you sure you wish to send this form back to resource?" }, panelClass: 'custom-modalbox' });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.saveForm("resource")
            // this.ngOnInit()
            
          }
        })
    }
    else if(action == "reject"){
      let dialogRef = this.dialog.open(DialogConfirmOSComponent,
        { width: '50%', height:'25%', data: { action: action, message: "Are you sure you wish to cancel this onboarding request?" }, panelClass: 'custom-modalbox' });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.saveForm("reject")
            // this.ngOnInit()
            
          }
        })
    }
    else if(action == "background"){
      let dialogRef = this.dialog.open(DialogConfirmOSComponent,
        { width: '50%', height:'25%', data: { action: action, message: "Are you sure you wish to send this form back for background verification?" }, panelClass: 'custom-modalbox' });
       dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.saveForm("background")
            
          }
        })
    }

    
  }

  onCountryChange($event){

  }

  getNumber($event){

  }

  telInputObject($event){

  }

  hasError($event){
    
  }
  openUploadForm(file, element) {
    // var systemProperties = {
      
    //   "fileName": element.name,
    //   "lastModifiedDate": new Date(file[0].lastModified),
    //   "size": String(file[0].size),
    //   "type": file[0].type
    // } 
    // var uploadRequest = {
    //   "docId": this.values[element['name']],
    //   "category_id": Number(element.category_id),
    //   "meta_data": {
    //   "Username": this.resourceDetails['Email'],
    //   "Document Type": element.name
    // },
    // "system_properties": systemProperties

    // }

    var meta_data = {
      "Username": this.resourceDetails['Email'],
      "Document Type": element.name
    }
  
    const formData = new FormData();
    formData.append("docId", this.values[element['name']]);
    formData.append("category_id", String(element.category_id));
    formData.append("meta_data", JSON.stringify(meta_data));
    formData.append("file", file[0]);
    this.selectedDocument = formData
    let dialogRef = this.dialog.open(DialogFilePreviewComponent,
      { width: '600px', height:'80%', data: { type: element.name, payload: element.name, file:file[0] }, panelClass: 'custom-modalbox' });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.uploadDocVersion()
         
        }
      })
    
  }

  downloadDocument(document){
    var documentId = this.values[document['name']]
    this.documentService.downloadPdf(documentId)
      .then(blob=> {
        saveAs(blob, this.values['Resource Last Name']+"_"+this.values['Resource First Name']+"_"+document['name']+'.pdf');
        
      })
  }

  backfromUpload(hide: boolean) {
    if (hide) {
      this.openUpload = true
    }
    else {
      this.openUpload = false
      this.ngOnInit()
    }
  }

populateForm(action){
  if (this.properties['Laptop required'] == false){
    delete this.properties['Address Line 1']
    delete this.properties['Address Line 2']
    delete this.properties['City']
    delete this.properties['State']
    delete this.properties['Country']
    delete this.properties['Zip Code']
    delete this.properties['Mobile Number']

  }

  if(action == "save"){
    if(this.properties['Mobile Number'] == null){
      delete this.properties['Mobile Number']
    }
    if(this.properties['Phone Number'] == null){
      delete this.properties['Phone Number']
    }

    var populateReqSave = {
      "email": this.resourceDetails['Email'],
      "form_id": this.form['id'],
      "formfield_value": this.properties,
      "submit_form": false
    } 

    this.formService.populateForm(populateReqSave).subscribe(resp=>{

        let dialogRef = this.dialog.open(DialogErrorComponent,
          { width: '40%', height:'25%', data: {message: "The form has been saved!" }, panelClass: 'custom-modalbox' });

    
      this.ngOnInit()
    })

  }

  

  if(action != "save"){
    if(this.properties['Member Firm'] == "Australia"){
      this.isPPMDApprovalRequired = false
    }
    else{
      this.isPPMDApprovalRequired = false
    }

      
    var populateReq = {
      "email": this.resourceDetails['Email'],
      "form_id": this.form['id'],
      "formfield_value": this.properties
    } 

    if(action == "approve"){
      var populateReqApprove = {
        "email": this.resourceDetails['Email'],
        "form_id": this.form['id'],
        "formfield_value": this.properties
      } 
      this.formService.populateForm(populateReqApprove).subscribe(resp=>{
        this.formService.getKey(this.properties['Member Firm']).subscribe(key=>{
          this.backgroundEmail = key['value']['Background Email'].trim().toLowerCase()
          this.fieldglassEmail = key['value']['Fieldglass Admin Email']
          if(action == 'approve'){
            var workflowRequest = {
              "UserID": this.resourceDetails['Email'],
              "Owner": this.currentUser['email'],
              "OSStatus": "reviewed",
              "Email": this.resourceDetails['Email'],
              "FormID": resp.profileId,
              "OnboardingSpecialist": this.properties['Onboarding Specialist'],
              "FieldGlassAdmin": this.fieldglassEmail
              
            }
            this.formService.submitNewResourceWorkflow(workflowRequest, this.resourceDetails['taskid']).subscribe(workflowresp=>{
             
                this.isNewResourceSubmitted = true
                this.message = "The onboarding request has been approved"
              
              
            })
          }           
        })    
      })

    }

    if(action == 'reject'){

      var populateReqReject = {
        "email": this.resourceDetails['Email'],
        "form_id": this.form['id'],
        "formfield_value": this.properties,
        "submit_form": false
      } 
      this.formService.populateForm(populateReqReject).subscribe(resp=>{
        this.formService.getKey(this.properties['Member Firm']).subscribe(key=>{
          this.backgroundEmail = key['value']['Background Email'].trim().toLowerCase()
          this.fieldglassEmail = key['value']['Fieldglass Admin Email']
          if(action == 'reject'){
            var workflowRequestReject = {
              "OSStatus": "TERMINATE",
              "Email": this.resourceDetails['Email'],
              "OnboardingSpecialist": this.properties['Onboarding Specialist'],
              "lastname": this.properties['Resource Last Name'],
              "firstname": this.properties['Resource First Name']
            }
            this.formService.submitNewResourceWorkflow(workflowRequestReject, this.resourceDetails['taskid']).subscribe(workflowstatus=>{
              this.formService.changeWorkFlowStatus(resp.profileId).subscribe(status=>{
                this.isNewResourceSubmitted = true
              this.message = "The onboarding request has been cancelled"
              })
            })
          }          
        })    
      })

    }

    if(action == "resource"){

      var populateReqResource = {
        "email": this.resourceDetails['Email'],
        "form_id": this.form['id'],
        "formfield_value": this.properties,
        "submit_form": false
      } 
      this.formService.populateForm(populateReqResource).subscribe(resp=>{
        this.formService.getKey(this.properties['Member Firm']).subscribe(key=>{
          this.backgroundEmail = key['value']['Background Email'].trim().toLowerCase()
          this.fieldglassEmail = key['value']['Fieldglass Admin Email']
          if(action == 'resource'){
            var workflowRequestresource = {
              "UserID": this.resourceDetails['Email'],
              "bgvmailid": this.backgroundEmail, 
              "Owner": this.resourceDetails['Email'],
              "FormID": resp.profileId,
              "PPMDRequired": this.isPPMDApprovalRequired,
              "OnboardingSpecialist": this.properties['Onboarding Specialist'] ,
              "OSStatus": "RESOURCE"
            }
            this.formService.submitNewResourceWorkflow(workflowRequestresource, this.resourceDetails['taskid']).subscribe(resp=>{
              this.isNewResourceSubmitted = true
              this.message = "The onboarding request has been sent back to the resource"
            })
          }          
        })    
      })

    }

    if(action == "background"){

      var populateReqBGV = {
        "email": this.resourceDetails['Email'],
        "form_id": this.form['id'],
        "formfield_value": this.properties,
        "submit_form": false
      } 
      this.formService.populateForm(populateReqBGV).subscribe(resp=>{
        this.formService.getKey(this.properties['Member Firm']).subscribe(key=>{
          this.backgroundEmail = key['value']['Background Email'].trim().toLowerCase()
          this.fieldglassEmail = key['value']['Fieldglass Admin Email']
          if(action == 'background'){
            var workflowRequestBGV = {
              "UserID": this.resourceDetails['Email'],
              "bgvmailid": this.backgroundEmail, 
              "Owner": this.backgroundEmail,
              "FormID": resp.profileId,
              "PPMDRequired": this.isPPMDApprovalRequired,
              "OnboardingSpecialist": this.properties['Onboarding Specialist'] ,
              "OSStatus": "BGV"
            }
            this.formService.submitNewResourceWorkflow(workflowRequestBGV, this.resourceDetails['taskid']).subscribe(resp=>{
              this.isNewResourceSubmitted = true
              this.message = "The onboarding request has been sent back to background verification team"
            })
          }        
        })    
      })

    }

  }


  
}

uploadDocVersion(){
  this.documentService.uploadDocumentVersionDirect(this.selectedDocument).subscribe(resp=>{
  })
}
}
