<mat-card style = "background-color:#03277c; text-align: center; color: white">
    <strong>Reports</strong>
</mat-card>

<mat-card style = "background-color:whitesmoke; color: rgb(0, 0, 0)">
    <form [formGroup]="searchForm" >
        <div class="input-group-sm input-group col-4" style="margin-left: 29%; ">
            <div class="input-group-prepend">
                <span class="input-group-text form-label-105" style="font-size:small">Report Type</span>
            </div>
            <mat-select placeholder = "Please select the report type..." formControlName="report"(selectionChange)="getFieldGlassDependencies(searchForm.value.report)" 
                                                class="form-control">
                                                <mat-option *ngFor = "let type of reportType"[value]="type"
                                                    >
                                                    {{type}}</mat-option>
                                            </mat-select>
        </div>

            <div formArrayName="search" >
            <div class="row mt-2">
                
                <div *ngFor="let item of getForm(searchForm); let i=index" class="col-6" style="margin-left: 29%;" [formGroupName]="i">
               
                        <div class="row" >
                                <div class="input-group row" style="margin-left: 4px">
                                    <div class="col-4" style="padding:0">
                                        <div class="input-group-sm mb-3 input-group col">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" style="font-size:small">Key</span>
                                            </div>
                                            <mat-select formControlName="keyword" 
                                                class="form-control">
                                                <mat-option *ngFor = "let keyword of keyValues" [value]="keyword" 
                                                    >
                                                    {{keyword['Name']}}</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>

                                    <div class="col-7 p-0">
                                        <div *ngIf = "searchForm.value.search[i].keyword['Type'] != 'date'" class="input-group-sm mb-3 input-group col">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" style="font-size:small">Filter Value</span>
                                            </div>
                                            <input class="form-control"  type="text" formControlName="filter">
                                        </div>
                                        <div *ngIf = "searchForm.value.search[i].keyword['Type'] == 'date'" class="input-group-sm mb-3 input-group col">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" style="font-size:small">Start Date</span>
                                            </div>
                                            <input class="form-control"  type="date" formControlName="startDate">

                                            <!-- <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                                                <input matStartDate formControlName="start" placeholder="Start date">
                                                <input matEndDate formControlName="end" placeholder="End date">
                                              </mat-date-range-input>
                                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                              <mat-date-range-picker #picker></mat-date-range-picker> -->
                                            
                                        </div>
                                        <div *ngIf = "searchForm.value.search[i].keyword['Type'] == 'date'" class="input-group-sm mb-3 input-group col">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" style="font-size:small">End Date</span>
                                            </div>
                                            <input class="form-control"  type="date" formControlName="endDate">
                                            
                                        </div>
                                    </div>
                                    <div class="col-1 ">
                                        <mat-icon (click)="deleteForm(i)"
                                            *ngIf="formArray.controls.length > 1">delete
                                        </mat-icon>
                                    </div>
                                    <div class="col-1 mr-1" *ngIf="i == 0">
                                        <button mat-stroked-button title="Add Filter"
                                            (click)="addNewForm()">
                                            <mat-icon>add</mat-icon> Filter
                                        </button>
                                    </div>
                                </div>
                                

                        </div>
                        
                    
                </div>

                
            </div>
            </div>

        <button  mat-raised-button class="actionButton" style="margin-left:95%"
         type="submit" [disabled]="searchForm.value.report == ''" (click) = "checkEmptySearch()" 
       >Search</button>
    </form>


</mat-card>

<div class="container-fluid">
    <div style="margin-top: 1em;" *ngIf="noSearchResults">
        <strong> No results to be displayed</strong>
        <div style="float:right">
            (Total Count: {{docCount}})
        </div>
    </div>

    <div class = "row" *ngIf="searchResults.length > 0" id="searchResults">
        <div class="filtercontainer">

            <div style="float:right;margin-top: 1em;">
                (Total Count: {{docCount | number}})
            </div>
        </div>
    </div>

    <div  *ngIf="searchResults.length > 0" id="searchResults">
        <div class="col" *ngIf="!hideDocList">

            <div  *ngIf="searchResults.length > 0" class="row xAxis-scroll-auto">
                <table  mat-table [dataSource]="searchResultsList" matSort aria-describedby="doc-table">

                    <div *ngFor = "let column of searchResultsCols;index as i">
                        <ng-container matColumnDef="{{searchResultsCols[i]}}" *ngIf = "column != 'Task Name'">
                            <th id="{{searchResultsCols[i]}}" mat-header-cell *matHeaderCellDef mat-sort-header ="{{searchResultsCols[i]}}"> {{column}}
                            </th>
                            <td mat-cell *matCellDef="let element">
                          {{element[column]}}
                            </td>
                        </ng-container>
                    </div>
                    <tr mat-header-row *matHeaderRowDef="searchResultsCols"></tr>
                    <tr mat-row *matRowDef="let row; columns: searchResultsCols;">
                    </tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5,10, 20, 50]" [pageSize]="10" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
