import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-file-preview',
  templateUrl: './dialog-file-preview.component.html',
  styleUrls: ['./dialog-file-preview.component.scss']
})
export class DialogFilePreviewComponent implements OnInit {
  filePath: string = null

  constructor(
    public dialogRef: MatDialogRef<DialogFilePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.file.type.includes('pdf') || this.data.file.type.includes('image')) {
      this.filePath = (window.URL || window.webkitURL).createObjectURL(this.data.file)
    }
  }

  onClose(): void {
    (window.URL || window.webkitURL).revokeObjectURL(this.filePath)
    this.dialogRef.close(false)
  }

  uploadDocument(){
    this.dialogRef.close(true)
  }
}
