<div class="mt-1">
    <div class="card-body">
        <!-- <strong>Please make sure the documents are properly signed and dated! Address</strong> -->
        <strong>{{data.message}}</strong>
          <div class="mt-2">
            <button mat-raised-button (click)="onClose()" class="m-2" style="float: right; background-color:#000000; color:white; margin-top: 5%">No</button>
            <button mat-raised-button (click)="onCloseSave()" class="m-2" style="float: right; background-color:#03277c; color:white; margin-top: 5%">Yes</button>
            
          </div>
        
    </div>
</div>
