<mat-tab>

</mat-tab>
<div *ngIf="currentUser['email']">
<mat-card style = "background-color:#042c8a; text-align: center; color: white">
    <strong>Welcome to PayPal Onboarding Process</strong>

</mat-card>

<mat-card class="mt-7" style = "background-color:#B5D9F3;  margin-top: 10%; margin-right:5%; margin-left:5%;  height:100%;" *ngIf="!isOnboardingInitiated && isAdmin && form">
    <div style="text-align: center;">
    <strong><i>Initiate onboarding request for another resource</i></strong>
</div>
<div>
    <div class = "mt-2">

        <form [formGroup]="registerForm" style="margin-top: 2%;" (ngSubmit)="onSubmit()">
            <div *ngFor="let field of form.form_field_definition.properties; index as i;" style="display: inline-block; width:50%;" >
                <div *ngIf="field.required==true" >
                    <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width: 170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px;"
                            type="text" formControlName="{{field.name}}"
                            required>
                            <div *ngIf="submitted && f.Email.errors && field.name == 'Email'" style="text-align:center" class="text-danger col-11" >
                                <div style = "font-size:x-small; display:block; text-align:center"*ngIf="f.Email.errors.required">Email is required</div>
                                <div style = "font-size:x-small; display:block; text-align:center" *ngIf="f.Email.errors.email">Email must be a valid email address</div>
                            </div>
                            <div *ngIf="submitted && f['Resource First Name'].errors && field.name == 'Resource First Name'" style="text-align:center" class="text-danger col-11" >
                                <div style = "font-size:x-small; display:block; text-align:center"*ngIf="f['Resource First Name'].errors.required">First name is required</div>
                            </div>
                            <div *ngIf="submitted && f['Resource Last Name'].errors && field.name == 'Resource Last Name'" style="text-align:center" class="text-danger col-11" >
                                <div style = "font-size:x-small; display:block; text-align:center;"*ngIf="f['Resource First Name'].errors.required">Last name is required</div>
                            </div>
                    </div>       
                    <div *ngIf="field.data_type=='date' && field.is_document==false  && !field.values && field.name == 'Resource Project Start Date'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                             min="{{projectStartDate}}" type="date" formControlName="{{field.name}}"
                            required>
                            <div *ngIf="submitted && f['Resource Project Start Date'].errors && field.name == 'Resource Project Start Date'" style="text-align:center" class="text-danger col-11" >
                                <div style = "font-size:x-small; display:block; text-align:center"*ngIf="f['Resource Project Start Date'].errors.required">Project start date is required</div>
                            </div>
                            
                    </div>
                    <div *ngIf="field.data_type=='str' && field.is_document==false && field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:93%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                    <mat-select style="font-size:x-small; min-height: 31px; text-align:left" class="form-control"  name="{{field.name}}" formControlName="{{field.name}}" (selectionChange)="getFieldGlassDependencies(registerForm.value[field.name], field.name)" required>
                        <mat-option *ngFor="let value of field.values" [value]="value" >
                          {{value}}
                        </mat-option>
                      </mat-select>
                    <div *ngIf="submitted && f['Member Firm'].errors && field.name == 'Member Firm'" style="text-align:center" class="text-danger col-11" >
                        <div style = "font-size:x-small; display:block; text-align:center"*ngIf="f['Member Firm'].errors.required">Member firm is required</div>
                    </div>
                    <div *ngIf="submitted && f['Fieldglass TQ'].errors && field.name == 'Fieldglass TQ'" style="text-align:center" class="text-danger col-11" >
                        <div style = "font-size:x-small; display:block; text-align:center"*ngIf="f['Fieldglass TQ'].errors.required">Fieldglass TQ is required</div>
                    </div>
                    <div *ngIf="submitted && f['Onboarding Specialist'].errors && field.name == 'Onboarding Specialist'" style="text-align:center" class="text-danger col-11" >
                        <div style = "font-size:x-small; display:block; text-align:center"*ngIf="f['Onboarding Specialist'].errors.required">Onboarding specialist is required</div>
                    </div>
                    </div>
                    <div *ngIf="field.data_type=='bool' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} *<mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <mat-select class="form-control" name="{{field.name}}"   formControlName="{{field.name}}" style="font-size:x-small; min-height: 31px" required>
                          <mat-option  [value]=true >
                            Yes
                          </mat-option>
                          <mat-option  [value]=false >
                            No
                          </mat-option>
                        </mat-select>
                        <div *ngIf="submitted && f['Laptop required'].errors && field.name == 'Laptop required'" style="text-align:center" class="text-danger col-11" >
                            <div style = "font-size:x-small; display:block; text-align:center"*ngIf="f['Laptop required'].errors.required">Please select if laptop is required</div>
                        </div>
                        <div *ngIf="submitted && f['Access to VPN required'].errors && field.name == 'Access to VPN required'" style="text-align:center" class="text-danger col-11" >
                            <div style = "font-size:x-small; display:block; text-align:center"*ngIf="f['Access to VPN required'].errors.required">Please select if access to VPN is required</div>
                        </div>
                        <div *ngIf="submitted && f['Badge required'].errors && field.name == 'Badge required'" style="text-align:center" class="text-danger col-11" >
                            <div style = "font-size:x-small; display:block; text-align:center"*ngIf="f['Badge required'].errors.required">Please select if badge is required</div>
                        </div>
        
                    </div>
                </div>
            </div>
            

            <div *ngIf="submitted && isWorkflowActive && onboardedEmail == registerForm.value.Email " style="text-align:center" class="text-danger col-11" >
                <div style = "font-size:x-small; display:block; text-align:center"*ngIf="isWorkflowActive">Onboarding has already been initiated for this user</div>
            </div>

            <div *ngIf="submitted && registerForm.value['Badge required'] == false && registerForm.value['Access to VPN required'] == false && registerForm.value['Laptop required'] == false " style="text-align:center; margin-left:5%" class="text-danger col-11" >
                <div style = "font-size:x-small; display:block; text-align:center">If you do not require laptop, badge or access to VPN, please connect with your Onboarding Specialist or Manager before initiating an onboarding request</div>
            </div>
                <button class="actionButton mt-1" style = "font-size:x-small; margin-left:95%;"  mat-raised-button type="submit" 
                    >Submit</button>
            
        </form>


        <!-- <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-11">
                <div class="input-group-prepend"><div class="input-group-text form-label-50">Please enter email of the resource</div></div>
                <input type="text" formControlName="email" class="form-control form-label-50 mr-1"  />             
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
            </div>
            <div  style ="padding-left:10%" class="input-group-sm mb-1 input-group col-11"><div class="input-group-prepend"><div class="input-group-text form-label-50"style ="width:230px">Resource First Name</div></div>
            <input type="text" formControlName="first_name" class="form-control form-label-50 mr-1"  />
        </div>
        <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-11"><div class="input-group-prepend"><div class="input-group-text form-label-50" style ="width:230px">Resource Last Name</div></div>
            <input type="text" formControlName="last_name"  class="form-control form-label-50 mr-1"  />
        </div>
        <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-11"><div class="input-group-prepend"><div class="input-group-text form-label-50" style ="width:230px">Onboarding Specialist</div></div>
        <input type="text" formControlName="onboarding_specialist"  class="form-control form-label-50 mr-1"  />
    </div>

    <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-11"><div class="input-group-prepend"><div class="input-group-text form-label-50" style ="width:230px">Resource Project Start Date</div></div>
        <input type="date" min = "{{projectStartDate}}" formControlName="resource_project_start_date"  class="form-control form-label-50 mr-1"  />
    </div>
            
            <button mat-button style="margin-top: 2%;background-color: #03277c; width: 20%; height:40px;  color:rgb(255, 255, 255); float:right">Confirm</button>
        </form> -->
    </div>
</div>

</mat-card>

<mat-card class="mt-7" style = "background-color:#B5D9F3;  margin-top: 10%; margin-left:15%; margin-right:15%; height:100%;" *ngIf="!isOnboardingInitiated && !isAdmin && !alreadyOnboarded && form" >
    <div style="text-align: center;">
    <strong>Initiate an onboarding request</strong>
</div>
<form [formGroup]="registerFormFormMyself" style="margin-top: 2%; margin-right:3%"(ngSubmit)="onSubmit(initiateForMyself())">
    <div style="display: inline-block; width:50%;">
        <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-12"><div class="input-group-prepend"><div class="input-group-text form-label-50" style ="width:170px; font-size:x-small">Fieldglass TQ*<mat-icon style = "float: right; transform: scale(.7);" title="Deloitte resource responsible for all onboarding">help</mat-icon></div></div>
        <mat-select style="min-height: 34px; text-align:left; font-size:x-small" class="form-control form-label-50 mr-1"  formControlName="Fieldglass TQ" (selectionChange)="getFieldGlassDependencies(registerFormFormMyself.value['Fieldglass TQ'], 'Fieldglass TQ')" required>
            <mat-option *ngFor="let value of fieldglassTQValues" [value]="value" >
              {{value}}
            </mat-option>
          </mat-select>
        <div *ngIf="submitted && g['Fieldglass TQ'].errors" style="text-align:center" class="text-danger col-11" >
            <div style = "font-size:x-small; display:block; text-align:center"*ngIf="g['Fieldglass TQ'].errors.required">Fieldglass TQ is required</div>
        </div>
    </div>
    </div>
    <div style="display: inline-block; width:50%;">
        <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-12"><div class="input-group-prepend"><div class="input-group-text form-label-50" style ="width:170px; font-size:x-small">Onboarding Specialist*<mat-icon style = "float: right; transform: scale(.7);" title="Deloitte resource responsible for all onboarding">help</mat-icon></div></div>
        <mat-select style="min-height: 34px; text-align:left; font-size:x-small" class="form-control form-label-50 mr-1"  formControlName="Onboarding Specialist" required>
            <mat-option *ngFor="let value of onboardingSpecialistValues" [value]="value" >
              {{value}}
            </mat-option>
          </mat-select>
        <div *ngIf="submitted && g['Onboarding Specialist'].errors" style="text-align:center" class="text-danger col-11" >
            <div style = "font-size:x-small; display:block; text-align:center"*ngIf="g['Onboarding Specialist'].errors.required">Onboarding specialist is required</div>
        </div>
    </div>
    </div>

<div style="display: inline-block; width:50%;">
    <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-12"><div class="input-group-prepend"><div class="input-group-text form-label-50" style ="width:170px; font-size:x-small">Member Firm*<mat-icon style = "float: right; transform: scale(.7);" title="Select which country the Deloitte firm the resource aligns with">help</mat-icon></div></div>
    <mat-select style="min-height: 34px; text-align:left; font-size:x-small" class="form-control form-label-50 mr-1"  formControlName="Member Firm" required>
        <mat-option *ngFor="let value of memberFirmValues" [value]="value" >
          {{value}}
        </mat-option>
      </mat-select>
    <div *ngIf="submitted && g['Member Firm'].errors" style="text-align:center" class="text-danger col-11" >
        <div style = "font-size:x-small; display:block; text-align:center"*ngIf="g['Member Firm'].errors.required">Member firm is required</div>
    </div>
</div>
</div>
<div style="display: inline-block; width:50%;">
    <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-12"><div class="input-group-prepend"><div class="input-group-text form-label-50" style ="width:170px; font-size:x-small">Resource Project Start Date*<mat-icon style = "float: right; transform: scale(.7);" title="The date expected to start on the project">help</mat-icon></div></div>
    <input type="date" style="font-size:x-small; min-height:34px" min = "{{projectStartDate}}" formControlName="Resource Project Start Date"  class="form-control form-label-50 mr-1"  />
    <div *ngIf="submitted && g['Resource Project Start Date'].errors" style="text-align:center" class="text-danger col-11" >
        <div style = "font-size:x-small; display:block; text-align:center"*ngIf="g['Resource Project Start Date'].errors.required">Resource project start date is required</div>
    </div>
</div>
</div>





<div style="display: inline-block; width:50%;">
    <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-12"><div class="input-group-prepend"><div class="input-group-text form-label-50" style ="width:170px; font-size:x-small">Laptop required*<mat-icon style = "float: right; transform: scale(.7);" title="Select yes if you will need a PayPal laptop">help</mat-icon></div></div>
    <mat-select style="min-height: 34px; text-align:left; font-size:x-small" class="form-control form-label-50 mr-1"  formControlName="Laptop required" required>
        <mat-option  [value]=true >
            Yes
          </mat-option>
          <mat-option  [value]=false >
            No
          </mat-option>
      </mat-select>
    <div *ngIf="submitted && g['Laptop required'].errors" style="text-align:center" class="text-danger col-11" >
        <div style = "font-size:x-small; display:block; text-align:center"*ngIf="g['Laptop required'].errors.required">Please select if laptop is required</div>
    </div>
</div>
</div>

<div style="display: inline-block; width:50%;">
    <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-12"><div class="input-group-prepend"><div class="input-group-text form-label-50" style ="width:170px; font-size:x-small">Badge required*<mat-icon style = "float: right; transform: scale(.7);" title="Select yes if you will need a PayPal badge">help</mat-icon></div></div>
    <mat-select style="min-height: 34px; text-align:left; font-size:x-small" class="form-control form-label-50 mr-1"  formControlName="Badge required" required>
        <mat-option  [value]=true >
            Yes
          </mat-option>
          <mat-option  [value]=false >
            No
          </mat-option>
      </mat-select>
    <div *ngIf="submitted && g['Badge required'].errors" style="text-align:center" class="text-danger col-11" >
        <div style = "font-size:x-small; display:block; text-align:center"*ngIf="g['Badge required'].errors.required">Please select if badge is required</div>
    </div>
</div>
</div> 
<div style="display: inline-block; width:50%;">
    <div style ="padding-left:10%" class="input-group-sm mb-1 input-group col-12"><div class="input-group-prepend"><div class="input-group-text form-label-50" style ="width:170px; font-size:x-small">Access to VPN required*<mat-icon style = "float: right; transform: scale(.7);" title="Select yes if you will need a PayPal badge">help</mat-icon></div></div>
    <mat-select style="min-height: 34px; text-align:left; font-size:x-small" class="form-control form-label-50 mr-1"  formControlName="Access to VPN required" required>
        <mat-option  [value]=true >
            Yes
          </mat-option>
          <mat-option  [value]=false >
            No
          </mat-option>
      </mat-select>
    <div *ngIf="submitted && g['Access to VPN required'].errors" style="text-align:center" class="text-danger col-11" >
        <div style = "font-size:x-small; display:block; text-align:center"*ngIf="g['Access to VPN required'].errors.required">Please select if access to VPN required is required</div>
    </div>
</div>
</div> 

<div *ngIf="submitted && registerFormFormMyself.value['Badge required'] == false && registerFormFormMyself.value['Access to VPN required'] == false && registerFormFormMyself.value['Laptop required'] == false " style="text-align:center; margin-left:6%" class="text-danger col-11" >
    <div style = "font-size:x-small; display:block; text-align:center">If you do not require laptop, badge or access to VPN, please connect with your Onboarding Specialist or Manager before initiating an onboarding request</div>
</div>

    <button mat-button  style="margin-top: 2%;font-size:x-small;background-color: #03277c; width: 5%;color:rgb(255, 255, 255); margin-left:95%" >Submit</button>
</form>
</mat-card>

<!-- <mat-card class="mt-7" style = "background-color:#B5D9F3; text-align: center; margin-top: 10%; margin-left:25%; margin-right:25%; height:250px;" *ngIf="isOnboardingInitiated">
Onboarding has been initiated!
<button mat-button style="background-color: #03277c; width: 20%; height:40px;  color:rgb(255, 255, 255); float:right" (click)="">Ok</button>
</mat-card> -->

<!-- <mat-card class="mt-7" style = "background-color:#B5D9F3;  margin-top: 10%; margin-left:25%; margin-right:25%;" *ngIf="isOnboardingInitiated && isAdmin">
      <ul style="margin-left:2%">
        <li><strong>You have successfully initiated onboarding for {{registerForm.value['Resource Last Name']}}, {{registerForm.value['Resource First Name']}}!</strong></li>
        <li><strong>Please review all your pending tasks in <a [routerLink]="'/work-item'" routerLinkActive="selected">Work Item</a> Tab </strong></li>
      </ul> 
    <button mat-button style="background-color: #03277c; width: 20%; height:45px; color:rgb(255, 255, 255); margin-left:80%" (click)="cancel()">Ok</button>

</mat-card> -->

<mat-card class="mt-7" style = "background-color:#B5D9F3;  margin-top: 10%; margin-left:25%; margin-right:25%;" *ngIf="isOnboardingInitiated && isAdmin">
    <ul style="margin-left:2%">
        <li><strong>You have successfully initiated onboarding! You will now receive an email from <i><a [href]="mailText">paypalonboarding@docuedgeapidev.deloitte.com</a></i> with further instructions, please refer to the email and complete the rest of the process. </strong></li>
        <li><strong>As a preparation for the next step, download all the documents from the  <a [routerLink]="'/reference-links'" routerLinkActive="selected">Reference Links</a> Tab</strong></li>
        <li><strong>By now, you should know your onboarding specialist, if you are unsure, reach out to your Project Manager</strong></li>
        <li><strong>Please review all your pending tasks in <a [routerLink]="'/work-item'" routerLinkActive="selected">Work Item</a> Tab </strong></li>
    </ul> 
  <button mat-button style="background-color: #03277c; width: 20%; height:45px; color:rgb(255, 255, 255); margin-left:80%" (click)="cancel()">Ok</button>

</mat-card>

<mat-card class="mt-7" style = "background-color:#B5D9F3;  margin-top: 10%; margin-left:25%; margin-right:25%; " *ngIf="isOnboardingInitiated && !isAdmin">
    <ul>
        <li><strong>You have successfully initiated onboarding! You will now receive an email from <i><a [href]="mailText">paypalonboarding@docuedgeapidev.deloitte.com</a></i> with further instructions, please refer to the email and complete the rest of the process. </strong></li>
        <li><strong>As a preparation for the next step, download all the documents from the  <a [routerLink]="'/reference-links'" routerLinkActive="selected">Reference Links</a> Tab</strong></li>
        <li><strong>By now, you should know your onboarding specialist, if you are unsure, reach out to your Project Manager</strong></li>
        <li><strong>Please review all your pending tasks in <a [routerLink]="'/work-item'" routerLinkActive="selected">Work Item</a> Tab </strong></li>
      </ul> 
</mat-card>

<mat-card class="mt-7" style = "background-color:#cee9fc;  margin-top: 10%; margin-left:25%; margin-right:25%; " *ngIf="alreadyOnboarded && !isAdmin">
    <ul>
        <li><strong>You have successfully initiated onboarding! You will now receive an email from <i><a [href]="mailText">paypalonboarding@docuedgeapidev.deloitte.com</a></i> with further instructions, please refer to the email and complete the rest of the process. </strong></li>
        <li><strong>As a preparation for the next step, download all the documents from the  <a [routerLink]="'/reference-links'" routerLinkActive="selected">Reference Links</a> Tab</strong></li>
        <li><strong>By now, you should know your onboarding specialist, if you are unsure, reach out to your Project Manager</strong></li>
        <li><strong>Please review all your pending tasks in <a [routerLink]="'/work-item'" routerLinkActive="selected">Work Item</a> Tab </strong></li>
      </ul> 
</mat-card>

</div>