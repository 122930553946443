import { Component, OnInit, ViewChild, SimpleChange, SimpleChanges } from '@angular/core';
import { Router, Event as RouterEvent, NavigationEnd } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { AuthenticationService } from './_services/authentication.service';
import { User } from './_models';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UsersAndGroupsService } from './_services/users-and-groups.service';
import { FormService } from './_services/forms.service';
import { first } from 'rxjs/operators';
import { AlertService } from './_services/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public spinkit = Spinkit;
    mobileQuery: MediaQueryList;
    isActive: boolean = false;
    #settingsVariable: boolean = false;
    private _mobileQueryListener: () => void;
    selectedIndex: number = 1;
    currentUser: any;
    configSection: boolean = false;
    searchText: string;
    openSearch: boolean = false;
    isHome: boolean = false;
    userGroups: string[]
    isAdmin : boolean = false;
    showInitiateOnboarding: boolean =false
    onboardingEligible:boolean = false
    //archive: boolean = (`${environment.archive}` == "true");

    constructor(
        changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
        public router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private formsService: FormService,
        private usersGroupsService: UsersAndGroupsService
        //private apiSvc: SearchService,
    ) {
        this.userGroups = authenticationService.userGroupsValue;
        this.currentUser = this.authenticationService.currentUserValue
        this.isAdmin = this.authenticationService.isAdminValue;

        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                if (event['url'].startsWith('/config/')) {
                    this.configSection = true;
                } 
                
                else {
                    this.configSection = false;
                }
            }
            if (event instanceof NavigationEnd) {
                if (event['url'].startsWith('/home')) {
                    this.isAdmin = this.authenticationService.isAdminValue;
                    this.authenticationService.getUserDetails(this.currentUser).subscribe(resp=>{
                      })
                } 
                if (event['url'].startsWith('/')) {
                    this.isAdmin = this.authenticationService.isAdminValue;
                }
            }
        });
        // this.router.events.subscribe((event: RouterEvent) => {
        //     if (event instanceof NavigationEnd) {

        //     }
        // });
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.authenticationService.isAdmin.subscribe(y=>this.isAdmin = y)
        
    }

    fullTextSearch() {
        console.log(this.searchText)
        this.router.navigateByUrl('/home', { state: { text: this.searchText } });
        this.cancel()
    }

    initiateSearch() {
        this.searchText = ""
        this.openSearch = true
    }

    cancel() {
        this.openSearch = false
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    getUserInitials() {
        if(this.currentUser.firstName != null){
            return (this.currentUser) ? this.currentUser.firstName.slice(0, 1) + this.currentUser.lastName.slice(0, 1) : "";
        }
        else {

        }
    }
    
}
