import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
//import { LoaderService } from '../loader/loader.service';
import { Observable, of, throwError, from } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ISearchForm } from '../_models/search';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "System": "Vault"
  }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private http: HttpClient,
    //private messageService: MessageService,
    private snackBar: MatSnackBar,
    //private loader: LoaderService
  ) { }

  zLoader: number = 0;
  updateSearchRequest

  // display log messages as snackbar popups
  private log(message: string) {
    this.snackBar.open(message, "", { duration: 5000 });

  }

  // showLoader() and hideLoader() implemented with a zLoader counter to handle nested web service calls
  // and only display the loader spinner with the first call and hide it with the last return
  // private showLoader() {
  //   if (this.zLoader === 0)
  //     this.loader.show();
  //   this.zLoader++;
  // }

  // private hideLoader() {
  //   if (this.zLoader > 0)
  //     this.zLoader--;
  //   if (this.zLoader === 0)
  //     this.loader.hide();
  // }

  notify(message: string): void {
    this.snackBar.open(message, "", { duration: 5000 });
  }

  createSearchForm(formData) {
    //console.log(formData)
    return this.http.post<any>(`${environment.apiUrl}/search/forms/create`, formData)
      .pipe(catchError(this.errorHandler))
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error)
  }

  updateSearchForm(formData) {
    return this.http.put<any>(`${environment.apiUrl}/search/forms/update`, formData).pipe(catchError(this.errorHandler))
  }

  deleteSearchForm(id, name) {
    return this.http.delete<any>(`${environment.apiUrl}/search/forms/delete/` + id + `/` + name).pipe(catchError(this.errorHandler))
  }

  private handleErrors(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  getSearchForms(): Observable<ISearchForm[]> {
    //var response: new HttpResponse()
    
    var url = `${environment.apiUrl}/search/forms`;
    //this.showLoader();
    return this.http.get<ISearchForm[]>(url)
      .pipe(
        //tap(_ => this.hideLoader()),
        map(obj => { if (obj instanceof Array) return obj; throw obj; }),
       // catchError(err => this.handleError<ISearchForm[]>('getSearchForms', []))
      );

  }

  getSearchResults(searchReq: any): Observable<any> {
    var url = `${environment.apiUrl}/search`;
    return this.http.post<any>(url, searchReq)
      .pipe(
        //catchError(err => this.handleError<any[]>('getSearchResults', []))
      );
  }

  executeFullText(searchReq: any): Observable<any> {
    var url = `${environment.apiUrl}/search/fulltext`;

    return this.http.post<any>(url, searchReq, httpOptions)
      .pipe(
      );
  }

  saveSearchForm(searchReq: any): Observable<any> {
    var url = `${environment.apiUrl}/search/query/save`;

    return this.http.post<any>(url, searchReq, httpOptions)
      .pipe(
      );
  }

  getSavedSearchForms(formId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/search/query/get/` + formId)
        .pipe(
        );
}
  private handleError<T>(operation = 'operation', result?: T) {
    //alert(JSON.stringify(err));  
    //o: T;
    //alert(typeof o);
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      this.snackBar.open(String(error).replace("error:", "ERROR:  "), "", { duration: 5000 });
      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
      //return result;
    };
  }
}

