<div>
    <div *ngIf="category" class="card m-2">
        <div mat-dialog-title class="heading">Upload Document to {{category.name}}</div>
        <div class="ml-3" *ngIf="toggleUpload">
            <!-- <mat-divider></mat-divider> -->
            <form #initiateForm="ngForm">
                <!-- <mat-form-field appearance="standard">
                    <mat-label>Document Title</mat-label>
                    <input matInput name="documentTitle" [(ngModel)]="documentTitle" required>
                </mat-form-field> -->
                <div class="row" style="height:calc(100vh-93px);" *ngIf="fileToUpload">
                    <div class="col-6" style="overflow-y: auto;height:calc(100vh); width:100%; margin-bottom: 3px;">
                        <div mat-dialog-title style="padding-left: 15px; font-size:medium">Metadata</div>
                        <div class="input-group-sm mt-2 input-group col-12">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Document Type *</span>
                            </div>
                            <input *ngIf="selectedDocument" class="form-control" name="document-title" [ngClass]="selectedDocument.documentName?'':'empty-input'"
                                type="text" [(ngModel)]="selectedDocument.documentName" readonly>
                            <input *ngIf="!selectedDocument" class="form-control" name="document-title" [ngClass]="documentTitle?'':'empty-input'"
                                type="text" [(ngModel)]="documentTitle" required>
                        </div>

                        <div *ngFor="let key of category.meta_data_definition.properties; index as i;">
                            <div *ngIf="key.required==true">
                                <div *ngIf="key.data_type=='str' && key.name == 'Region'" class="input-group-sm mt-2 input-group col-12">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Select region(s)*</span>
                                    </div>
                                    <mat-select class="form-control" name="{{key.name}}"
                                    [ngClass]="category.meta_data_definition.properties[i].value?'':'empty-input'"
                                    type="text" [(ngModel)]="regions"
                                    required style="height:auto"  class="form-control" multiple>
                                        <mat-option value="United States of America">United States of America</mat-option>
                                        <mat-option value="USI">USI</mat-option>
                                        <mat-option value="India">India</mat-option>
                                        <mat-option value="China">China</mat-option>
                                        <mat-option value="UK">UK</mat-option>
                                        <mat-option value="China">China</mat-option>
                                        <mat-option value="Australia">Australia</mat-option>
                                        <mat-option value="Mexico">Mexico</mat-option>
                                        <mat-option value="Poland">Poland</mat-option>
                                        <mat-option value="Singapore">Singapore</mat-option>
                                        <mat-option value="Sweden">Sweden</mat-option>
                                </mat-select>
                                </div>
                            </div>
                            <div *ngIf="key.required==false">
                                <div *ngIf="key.data_type=='str' && key.name == 'Region'" class="input-group-sm mt-2 input-group col-12">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Select region(s)*</span>
                                    </div>
                                    <mat-select class="form-control" name="{{key.name}}"
                                    [ngClass]="category.meta_data_definition.properties[i].value?'':'empty-input'"
                                    type="text" [(ngModel)]="regions"
                                    required style="height:auto"  class="form-control" multiple>
                                        <mat-option value="United States of America">United States of America</mat-option>
                                        <mat-option value="USI">USI</mat-option>
                                        <mat-option value="India">India</mat-option>
                                        <mat-option value="UK">UK</mat-option>
                                        <mat-option value="China">China</mat-option>
                                        <mat-option value="Australia">Australia</mat-option>
                                        <mat-option value="Mexico">Mexico</mat-option>
                                        <mat-option value="Poland">Poland</mat-option>
                                        <mat-option value="Singapore">Singapore</mat-option>
                                        <mat-option value="Sweden">Sweden</mat-option>
                                </mat-select>
                                </div>
                            </div>
                        </div>

                        <div>
                            <button class="mb-3 mr-2 closeButton mt-3" style="float:right" mat-raised-button (click)="onClose()">Discard</button>
                            <button class="mb-3 mr-2 mt-3" style="float:right; background-color: #B5D9F3" mat-raised-button
                                [disabled]="!initiateForm.valid || !fileToUpload" type="submit"
                                (click)="uploadDocument()">Submit</button>&nbsp;
                        </div>
                    </div>
                    <div class="col-6">
                        <div mat-dialog-title style="font-size: medium;">Preview</div>
                        <app-file-preview [filePath]="filePath"></app-file-preview>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="uploadMessage">
            <div class="mt-2" style="text-align: center;">File Upload has been completed successfully.</div>
            <div *ngIf="isWorkflow" class="mt-2" style="text-align: center;">Workflow {{workflowID}} has been initiated.
            </div>
            <button style="margin: 1em;" mat-raised-button (click)="onClose()">Close</button>
        </div>


    </div>
</div>