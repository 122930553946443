<!-- <div class="row"  *ngIf="data.file">
    <div class="col-12">
        <app-file-preview [filePath]="filePath"></app-file-preview>
        <button class="mb-3 mr-2 closeButton mt-3" style="float:right" mat-raised-button (click)="onClose()">Discard</button>
            <button class="mb-3 mr-2 mt-3" style="float:right; background-color: #B5D9F3" mat-raised-button
             type="submit"
                (click)="uploadDocument()">Upload</button>
    </div>
</div> -->

<div *ngIf="filePath">
    <iframe title="Document" [src]="filePath | safe" width="100%" autosize style="height: calc(100vh);">
    </iframe>
</div>
<div *ngIf="!filePath" style="border: 1px solid;height: calc(100vh);">
    Preview unavailable for this document.
</div>
<button class="mb-3 mr-2 closeButton mt-3" style="float:right" mat-raised-button (click)="onClose()">Discard</button>
            <button class="mb-3 mr-2 mt-3" style="float:right; background-color: #B5D9F3" mat-raised-button
             type="submit"
                (click)="uploadDocument()">Upload</button>