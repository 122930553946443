import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Document } from '../_models/document';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Documentcategory } from '../_models/documentcategory';

import { AlertService } from './alert.service';
import { AuthenticationService } from './authentication.service';



@Injectable({ providedIn: 'root' })
export class DocumentService {
    workflowcategories: string[] = ["Workflow Category"]

    constructor(
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private alertService: AlertService,
    ) { }

    uploadDocument(req: any): Observable<any> {
        //console.log("Request " + req.meta_data);
        return this.http.post<any>(`${environment.apiUrl}/documents/upload`, req)
            .pipe(
                //map(obj => { alert(" all good "+obj["docId"]); return obj; }),
                )
    }

    assignOwner(payLoad) {
        return this.http.put<any>(`${environment.apiUrl}/documents/capture/assign`, payLoad)
            .pipe(
                //map(obj => { alert(" all good "+obj["docId"]); return obj; }),
                )
    }

    getAllCaptureDocuments(): Observable<any> {
        var url = `${environment.apiUrl}/documents/capture/get`;
        //var url = "../../assets/data/CaptureDocs.json";
        return this.http.get<Document[]>(url)
            .pipe();
    }

    uploadDocumentDirect(req: any): Observable<any> {
        let currentUser = this.authenticationService.currentUserValue;
        const httpOptions = {
            headers: new HttpHeaders({
              'Authorization': `Bearer ${currentUser.token}`,
              "dms-user": currentUser.username,
              "accept": "application/pdf, application/json, text/plain, */*"
            }),
            observe: 'response' as 'body'
          };
        // return this.http.post<any>(, httpOptions)
        //     .pipe(
        //         //map(obj => { alert(" all good "+obj["docId"]); return obj; }),
        //         )

        return this.http.post<any>(`${environment.apiUrl}/documents/upload/direct`, req, httpOptions)
                .pipe()
    }

    uploadDocumentVersionDirect(req: any): Observable<any> {
        let currentUser = this.authenticationService.currentUserValue;
        const httpOptions = {
            headers: new HttpHeaders({
              'Authorization': `Bearer ${currentUser.token}`,
              "dms-user": currentUser.username,
              "accept": "application/pdf, application/json, text/plain, */*",
              
            }),
            observe: 'response' as 'body'
          };
        // return this.http.post<any>(, httpOptions)
        //     .pipe(
        //         //map(obj => { alert(" all good "+obj["docId"]); return obj; }),
        //         )

        return this.http.post<any>(`${environment.apiUrl}/documents/upload-version/direct`, req, httpOptions)
                .pipe()
    }

    exportDocuments(req: any): Observable<any> {
        this.snackBar.open("Export Job is running in the background. The status of export can be tracked and downloaded from 'Export Status' tab", "Close", {
            duration: 20000
          });
        return this.http.post<any>(`${environment.apiUrl}/documents/export-documents`, req)
            .pipe()
    }

    downloadPdf(documentID) {
        return this.http
                  .get(`${environment.apiUrl}/documents/get-stream/`+documentID, { responseType:'blob' })
          .toPromise();
      }
    getExportStatus(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/documents/export-status?offset=0&limit=1000`)
            .pipe()
    }

    indexDocumentMetadata(req: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/documents/index`, req)
            .pipe()
    }

    viewCaptureDocument(documentId) {
        //console.log("Doc ID", documentId)
        return this.http.get<any>(`${environment.apiUrl}/documents/capture/view/` + documentId)
            .pipe()
    }

    captureDocument(req: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/documents/capture`, req)
            .pipe()
    }

    uploadDocumentVersion(req: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/documents/upload-version`, req)
            .pipe()
    }

    updateDocumentMetadata(req: any): Observable<any> {

        return this.http.put<any>(`${environment.apiUrl}/documents/update`, req)
            .pipe()
    }

    updateDocumentTitle(req: any): Observable<any> {

        return this.http.put<any>(`${environment.apiUrl}/documents/update-title`, req)
            .pipe()
    }

    updateCategoryForDocument(req: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/documents/update/category`, req)
            .pipe()
    }

    uploadFileToS3(uploadUrl, file): Observable<any> {
        console.log("AWS")
        var formData = new FormData();
        Object.keys(uploadUrl.fields).forEach(key => {
            formData.append(key, uploadUrl.fields[key])
        });
        formData.append("file", file);
        //let header = new HttpHeaders();
        //header= header.append('content-type', file.type);        
        return this.http.post<any>(uploadUrl.url, formData)
            .pipe()
    }

    uploadFileToS3Capture(idx, uploadUrl, file): Observable<any> {
        console.log("AWS")
        var formData = new FormData();
        Object.keys(uploadUrl.fields).forEach(key => {
            formData.append(key, uploadUrl.fields[key])
        });
        formData.append("file", file);
        //let header = new HttpHeaders();
        const req = new HttpRequest('POST', uploadUrl.url, formData, {
            reportProgress: true,
            responseType: 'json'
        });
        //header= header.append('content-type', file.type);        

        return this.http.request(req).pipe();
    }

    uploadFileToGCP(uploadUrl, file) {
        var formData = new FormData();
        formData.append('file', file)
        return this.http.post<any>(uploadUrl, formData)
            .pipe()
    }

    uploadFileToGCPCapture(idx, uploadUrl, file) {
        var formData = new FormData();
        formData.append('file', file)
        const req = new HttpRequest('POST', uploadUrl, formData, {
            reportProgress: true,
            responseType: 'json'
        });
        //header= header.append('content-type', file.type);        

        return this.http.request(req).pipe();
        // return this.http.post<any>(uploadUrl, formData)
        //     .pipe()
    }

    uploadFileToAzure(uploadUrl, file, azureMetaData): Observable<any> {
        console.log("Azure")
        var formData = new FormData();
        formData.append("file", file);
        let header = new HttpHeaders();
        header = header.append("x-ms-blob-type", "BlockBlob")
        header = header.append("x-ms-blob-content-type", file.type)
        for (const [k, v] of Object.entries(azureMetaData)) {
            header = header.append(`${k}`, `${v}`)
        }
        return this.http.put(uploadUrl.url, file, { headers: header })
            .pipe()
    }

    uploadFileToAzureCapture(idx, uploadUrl, file, azureMetaData): Observable<any> {
        console.log("Azure")
        var formData = new FormData();
        formData.append("file", file);
        let header = new HttpHeaders();
        header = header.append("x-ms-blob-type", "BlockBlob")
        header = header.append("x-ms-blob-content-type", file.type)
        for (const [k, v] of Object.entries(azureMetaData)) {
            header = header.append(`${k}`, `${v}`)
        }
        const req = new HttpRequest('PUT', uploadUrl.url, file, {
            reportProgress: true,
            responseType: 'json',
            headers: header
        });
        console.log(header)
        return this.http.request(req)
            .pipe()
    }


    getByCategory(categoryId, offset): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/categories/` + categoryId + `/get-documents?offset=` + offset + `&limit=200`)
            .pipe();
    }

    viewDocument(documentId): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/documents/view/` + documentId)
            .pipe()
    }

    viewVersionDocument(documentId, versionNumber) {
        return this.http.get<any>(`${environment.apiUrl}/documents/view-version/` + documentId + `/` + versionNumber)
            .pipe()
    }

    lockDocument(documentId): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/documents/lock/` + documentId)
            .pipe()
    }

    unlockDocument(documentId): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/documents/unlock/` + documentId)
            .pipe()
    }

    getDocumentDetails(documentId: String): Observable<Document> {
        var url = `${environment.apiUrl}/documents/get/` + documentId;
        return this.http.get<Document>(url)
            .pipe();

    }

    getDocumentVersions(documentId) {
        //var url = `${environment.apiUrl}/documents/get/` + documentId + `/versions?include_metadata_versions=true`;
        var url = `${environment.apiUrl}/documents/get/` + documentId + `/versions`;
        return this.http.get(url)
            .pipe(
            );
    }

    notify(message: string): void {
        this.snackBar.open(message, "", { duration: 5000 });
    }

    deleteDocument(documentId, payload) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: payload
        }
        return this.http.delete(`${environment.apiUrl}/documents/delete/` + documentId, options).pipe()
    }

    deleteVersion(payload) {
        //console.log("body", payLoad)
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: payload
        }
        return this.http.delete<any>(`${environment.apiUrl}/documents/delete-version`, options).pipe()
    }

    activateVersion(payload) {
        return this.http.put<any>(`${environment.apiUrl}/documents/activate-version`, payload)
            .pipe()
    }

    getDocumentAudit(documentId) {
        return this.http.get<any>(`${environment.apiUrl}/reports/audit/document/` + documentId).pipe()
    }

    getComments(docID) {
        return this.http.get<any>(`${environment.apiUrl}/documents/comments/` + docID).pipe(        )
    }

    addComment(payload) {
        return this.http.post<any>(`${environment.apiUrl}/documents/comments/add`, payload).pipe(
            
        )
    }

    retrieveGlacierDocuments(req: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/documents/retrieve`, req)
            .pipe()
    }

    getGlacierStatus(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/documents/retrieve-status?offset=0&limit=1000`)
            .pipe()
    }

    private handleError<T>(operation = 'operation', result?: T) {
        //alert(JSON.stringify(err));  
        //o: T;
        //alert(typeof o);
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead
            this.snackBar.open(String(error).replace("error:", "ERROR:  "), "", { duration: 5000 });
            // TODO: better job of transforming error for user consumption
            //this.log(`${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result as T);
            //return result;
        };
    }
}
