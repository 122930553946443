import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqList: any
  filterValue: string;
  navBarFaq: any[]
  faqListSearch: any;
  emailString: string;
  links : any[]= ["link1.com", "link2.com", "link3.com"];

  mailText:string = "";


  @Input()
  title = 'Frequently Asked Questions';
  
  @Input()
  multi = false;

  @Input()
  displayMode = 'default'; // or flat

  constructor() { }

  ngOnInit(): void {
    this.mailText = "mailto:PayPal_Onboarding_Requests@deloitte.com?subject=[Action Required] - FAQ DocuEdge"
    this.faqList = [
      { question: 'What is a TQ Number?', answer: 'A TQ Number is an identifying number that PayPal assigns to each SOW or signed contract. It identifies a specific project in the Fieldglass system.' },
      { question: 'Where can I look up the TQ Number?', answer: 'A list of PayPal TQ numbers can be found by utilizing the TQ Master Look-up Report on MS Teams.' },
      { question: 'Where can I look up the EFA aligned to my TQ?', answer: 'The assigned EFA can be found by utilizing the TQ Master Look-up Report on MS Teams.' },
      { question: 'Where can I look up the Onboarding Specialist aligned to my project?', answer: 'The assigned Onboarding Specialist can be found by utilizing the TQ Master Look-up Report on MS Teams.' },
      { question: 'Why do I need to sign these Onboarding Forms?', answer: 'It is a requirement of PayPal.' },
      { question: 'Why do I need a new background check?', answer: 'PayPal requires a background check to be completed within 6 months of the resource\'s start date in which they have access to PayPal systems.' },
      { question: 'How can I tell if my Fieldglass ID has been activated?', answer: 'The status of your profile will show "open".' },
      { question: 'Where can I look up the Fieldglass Expiration Date?', answer: 'Please utilize the Fieldglass "Advisory" or "Consulting" weekly report.' },
      { question: 'Where can I look up my IdentityHub Expiration Date?', answer: 'Please utilize the Fieldglass "Advisory" or "Consulting" weekly report.' },
      { question: 'How come my Fieldglass Expiration date is later than my IdentityHub date?', answer: 'Your Fieldglass expiration date usually aligns with the SOW end date.  IdentityHub dates can only be extended every 90 days.' },
      { question: 'How can I extend my Fieldglass Expiration Date?', answer: 'As long as the date does not extend past the SOW end date, contact your OS to initiate a request for a Fieldglass Admin to extend your expiration date in Fieldglass.' },
      { question: 'How can I extend my SOW / Contract end date?', answer: 'A change order will need to be submitted.' },
      { question: 'When should I expect to receive my laptop?', answer: '2 weeks from the date your profile has been activated in Fieldglass.' },
      { question: 'My access has expired, who do I contact to get my access reactivated?', answer: 'Contact your project team lead or your PayPal manager.  It is likely that your IdentityHub date has expired.' },
      { question: 'How long will it take to reactivate my access once my access is expired?', answer: '1-2 weeks for reactivation.' },
      { question: 'Do I need to formally onboard if I am only needing Citrix access?', answer: 'To be granted access to any PayPal system, you must be onboarded to Fieldglass.' },
      { question: 'Who do I work with to get my Citrix Access?', answer: 'After onboarding to Fieldglass, please work with your PayPal Manager.' },
      { question: 'My manager told me that my iHUB date was extended, how long does it usually take to show that my iHub date was extended?', answer: '24-48 hours for it to be updated.' },
      { question: 'How long does it take to get my background check completed?', answer: 'It usually takes 2 weeks for background checks to be completed.' }
  ];

  this.faqListSearch = this.faqList
  }

  applyFilterOnFaq(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    if (this.filterValue.length > 0) {
      this.navBarFaq = []
      this.search()
    }
    else {
      this.faqListSearch = this.faqList
    }
  }

  search() {
    this.navBarFaq = []
    this.faqList.filter(form => {
      
      if ((form.question.trim().toLowerCase().indexOf(this.filterValue.trim().toLowerCase()) > -1)) {

        this.navBarFaq.push(form)
      }
      if ((form.answer.trim().toLowerCase().indexOf(this.filterValue.trim().toLowerCase()) > -1)) {

        this.navBarFaq.push(form)
      }
      this.navBarFaq = this.navBarFaq.filter((elem, i, arr) => {
        if (arr.indexOf(elem) === i) {
          return elem
        }
      })

      this.faqListSearch = this.navBarFaq
    })
    
  }

}

