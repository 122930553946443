<mat-card style="background-color:#042c8a; text-align: center; color: white">
    <strong>New Resource Form</strong>

</mat-card>

<div *ngIf="!isNewResourceSubmitted">
    <div *ngIf="form">
        <form #initiateForm="ngForm" style="margin-top: 1%;">
            <div *ngFor="let field of form.form_field_definition.properties; index as i;" style="display: inline-block; width:25%;">
                <div *ngIf="field.required==true">
                    <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values && field.name != 'Email'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'" type="text" [(ngModel)]="values[field.name]" required>
                    </div>
                    <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values && field.name == 'Email'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'" type="text" [(ngModel)]="values[field.name]" readonly required>
                    </div>
                    <div *ngIf="field.data_type=='str' && field.is_document==false  && values['Laptop required'] == true && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'" type="text" [(ngModel)]="values[field.name]" required>
                    </div>
                    <div *ngIf="field.data_type=='object' && field.is_document==false  && field.display_in_list == true && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <!-- <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px"
                     type="number"
                    [(ngModel)]="values[field.name]" required> -->

                    <ngx-intl-tel-input
                    style="position:relative; flex: 1 1 auto; width:1%; max-height: 32.2px;"
                    [ngClass]="values[field.name]?'':'empty-input'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="false"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.India"
                    [minLength]="8"  
                    [maxLength]="13"
                    [phoneValidation]="false"
                    [separateDialCode]="true"
                    name="phone" name="{{field.name}}"
                    [(ngModel)]="values[field.name]" required>
                </ngx-intl-tel-input>
                
                    </div>
                    <div *ngIf="field.data_type=='int' && field.is_document==false && values['Laptop required'] == true && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'" type="number" [(ngModel)]="values[field.name]" required>
                    </div>
                    <div *ngIf="field.data_type=='date' && field.is_document==false  && !field.values && field.name != 'Resource Project Start Date'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'" type="date" [(ngModel)]="values[field.name]" required>
                    </div>

                    <div *ngIf="field.data_type=='date' && field.is_document==false  && !field.values && field.name == 'Resource Project Start Date'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'" min="{{projectStartDate}}" type="date" [(ngModel)]="values[field.name]" required>
                    </div>
                    <div *ngIf="field.data_type=='bool' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} *<mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <mat-select class="form-control" [ngClass]="values[field.name]==false || values[field.name]==true ?'':'empty-input'" style="font-size:x-small; min-height: 31px" name="{{field.name}}" [(ngModel)]="values[field.name]" required>
                            <mat-option [value]=true>
                                Yes
                            </mat-option>
                            <mat-option [value]=false>
                                No
                            </mat-option>
                        </mat-select>

                    </div>
                    <div *ngIf="field.data_type=='str' && field.is_document==true && values[field.name] != null" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <!-- <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span> -->

                            <input style="display: none" type="file" (change)="handleFileInput($event.target.files, field.name)" #fileInput>
                            <div class="dropdown">
                                <button class="dropdown-toggle" mat-raised-button type="button" style="font-size:x-small; width: 170px;background-color:#042c8a; color: rgb(255, 255, 255)" data-toggle="dropdown"><strong>{{field.name}} *</strong>
                        <span class="caret"></span></button>
                                <!-- <span class="input-group-text dropdown-toggle" style="width:188px; font-size:x-small; max-height:90%" data-toggle="dropdown">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon><span class="caret"></span></span> -->
                                <div class="dropdown-content">
                                    <div><button class="btn btn-primary" mat-raised-button type="button" (click)="viewDocument(values[field.name])" style="margin-top:0%;width: 165%; font-size:x-small"><strong>View Document</strong></button></div>
                                    <div><button class="btn btn-primary" mat-raised-button type="button" (click)="fileInput.value='';fileInput.click()" style="margin-top:1%; width: 165%; font-size:x-small"><strong>Upload Document</strong></button></div>
                                </div>
                            </div>
                        </div>

                        <!-- <input type="file" class="form-control" style="font-size:x-small; min-height: 34px" (change)="handleFileInput($event.target.files, field.name)" required> -->
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 37px; " type="text" [(ngModel)]="values[field.name]" readonly required>
                    </div>
                    <div *ngIf="field.data_type=='str' && field.is_document==true && values[field.name] == null" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <!-- <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span> -->
                            <input style="display: none" type="file" (change)="handleFileInput($event.target.files, field.name)" #fileInput>
                            <div class="dropdown">
                                <button class="dropdown-toggle" mat-raised-button type="button" style="font-size:x-small; width: 170px;background-color:#042c8a; color: rgb(255, 255, 255)" data-toggle="dropdown"><strong>{{field.name}} *</strong>
                        <span class="caret"></span></button>
                                <!-- <span class="input-group-text dropdown-toggle" style="width:188px; font-size:x-small; max-height:90%" data-toggle="dropdown">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon><span class="caret"></span></span> -->
                                <div class="dropdown-content">
                                    <div><button class="btn btn-primary" mat-raised-button type="button" (click)="fileInput.value='';fileInput.click()" style="margin-top:1%; width: 165%; font-size:x-small"><strong>Upload Document</strong></button></div>
                                </div>
                            </div>
                        </div>

                        <!-- <input type="file" class="form-control" style="font-size:x-small; min-height: 34px" (change)="handleFileInput($event.target.files, field.name)" required> -->
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 37px; " type="text" [(ngModel)]="values[field.name]" readonly [ngClass]="values[field.name]?'':'empty-input'" required>
                    </div>
                    <!-- <div *ngIf="field.data_type=='str' && field.is_document==true && values[field.name] == null" class="input-group-sm mt-2 input-group col-12">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                </div>
                
                    <input type="file" class="form-control" style="font-size:x-small; min-height: 31px" (change)="handleFileInput($event.target.files, field.name)" required>
            </div> -->
                    <div *ngIf="field.data_type=='str' && field.is_document==false && field.values && field.name == 'Fieldglass TQ'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <mat-select style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'" class="form-control" [(ngModel)]="values[field.name]" [ngModelOptions]="{standalone: true}" required (selectionChange)="getFieldGlassDependencies(values[field.name], field.name)"
                            (ngModelChange)="values[field.name]">
                            <mat-option>
                                <ngx-mat-select-search [(ngModel)]="searchCtrl" [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'" name="{{field.name}}" id="{{field.name}}" [ngModelOptions]="{standalone: true}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let value of field.values |stringFilter:searchCtrl:'{{field.name}}'" [value]="value">
                                {{value}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div *ngIf="field.data_type=='str' && field.is_document==false && field.values && field.name == 'Member Firm'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <mat-select [ngClass]="values[field.name]?'':'empty-input'" style="font-size:x-small; min-height: 31px" class="form-control" name="{{field.name}}" [(ngModel)]="values[field.name]" required>
                            <mat-option *ngFor="let value of field.values" [value]="value">
                                {{value}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div *ngIf="field.required==false">
                    <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values && field.name == 'Comment'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}<mat-icon  *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <textarea class="form-control comment-input" name="{{field.name}}" style="font-size:x-small; min-height: 31px" type="text" [(ngModel)]="values[field.name]"></textarea>
                    </div>
                    <div *ngIf="field.data_type=='str' && field.is_document==false  && field.display_in_list == true && !field.values && field.name != 'Comment'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:100%">{{field.name}}<mat-icon  *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 34px" type="text" [(ngModel)]="values[field.name]">
                    </div>
                    <div *ngIf="field.data_type=='str' && field.is_document==false  && values['Laptop required'] == true && field.display_in_list == false && !field.values && field.name != 'Address Line 2'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} *<mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'" type="text" [(ngModel)]="values[field.name]" required>
                    </div>
                    <div *ngIf="field.data_type=='str' && field.is_document==false  && values['Laptop required'] == true && field.display_in_list == false && !field.values && field.name == 'Address Line 2'" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" type="text" [(ngModel)]="values[field.name]">
                    </div>
                    <div *ngIf="field.data_type=='int' && field.is_document==false  && field.display_in_list == true && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" type="number" [(ngModel)]="values[field.name]">
                    </div>
                    <div *ngIf="field.data_type=='str' && field.is_document==true && values['Temp Worker'] == true && field.display_in_list == false && values[field.name] != null" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <!-- <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span> -->

                            <input style="display: none" type="file" (change)="handleFileInput($event.target.files, field.name)" #fileInput>
                            <div class="dropdown">
                                <button class="dropdown-toggle" mat-raised-button type="button" style="font-size:x-small; width: 170px;background-color:#042c8a; color: rgb(255, 255, 255)" data-toggle="dropdown"><strong>{{field.name}} *</strong>
                        <span class="caret"></span></button>
                                <!-- <span class="input-group-text dropdown-toggle" style="width:188px; font-size:x-small; max-height:90%" data-toggle="dropdown">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon><span class="caret"></span></span> -->
                                <div class="dropdown-content">
                                    <div><button class="btn btn-primary" mat-raised-button type="button" (click)="viewDocument(values[field.name])" style="margin-top:0%;width: 165%; font-size:x-small"><strong>View Document</strong></button></div>
                                    <div><button class="btn btn-primary" mat-raised-button type="button" (click)="fileInput.value='';fileInput.click()" style="margin-top:1%; width: 165%; font-size:x-small"><strong>Upload Document</strong></button></div>
                                </div>
                            </div>
                        </div>

                        <!-- <input type="file" class="form-control" style="font-size:x-small; min-height: 34px" (change)="handleFileInput($event.target.files, field.name)" required> -->
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 37px; " type="text" [(ngModel)]="values[field.name]" readonly required>
                    </div>

                    <div *ngIf="field.data_type=='str' && field.is_document==true && values['Temp Worker'] == true && field.display_in_list == false && values[field.name] == null" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <!-- <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span> -->

                            <input style="display: none" type="file" (change)="handleFileInput($event.target.files, field.name)" #fileInput>
                            <div class="dropdown">
                                <button class="dropdown-toggle" mat-raised-button type="button" style="font-size:x-small; width: 170px;background-color:#042c8a; color: rgb(255, 255, 255)" data-toggle="dropdown"><strong>{{field.name}} *</strong>
                        <span class="caret"></span></button>
                                <!-- <span class="input-group-text dropdown-toggle" style="width:188px; font-size:x-small; max-height:90%" data-toggle="dropdown">{{field.name}} * <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon><span class="caret"></span></span> -->
                                <div class="dropdown-content">
                                    <div><button class="btn btn-primary" mat-raised-button type="button" (click)="fileInput.value='';fileInput.click()" style="margin-top:1%; width: 165%; font-size:x-small"><strong>Upload Document</strong></button></div>
                                </div>
                            </div>
                        </div>

                        <!-- <input type="file" class="form-control" style="font-size:x-small; min-height: 34px" (change)="handleFileInput($event.target.files, field.name)" required> -->
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 37px; " type="text" [(ngModel)]="values[field.name]" [ngClass]="values[field.name]?'':'empty-input'" readonly required>
                    </div>

                    <div *ngIf="field.data_type=='object' && field.is_document==false && values['Laptop required'] == true && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} *<mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>

                        <ngx-intl-tel-input
                        style="position:relative; flex: 1 1 auto; width:1%; max-height: 32.2px;"
                        [ngClass]="values[field.name]?'':'empty-input'"
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="false"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.India"
                        [minLength]="8"  
                        [maxLength]="13" 
                        [phoneValidation]="false"
                        [separateDialCode]="true"
                        name="phone" name="{{field.name}}"
                        [(ngModel)]="values[field.name]" required>
                    </ngx-intl-tel-input>
                    
                    </div>

                    <div *ngIf="field.data_type=='int' && field.is_document==false && values['Laptop required'] == true && field.display_in_list == false && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} *<mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" [ngClass]="values[field.name]?'':'empty-input'" type="number" [(ngModel)]="values[field.name]" required>
                    </div>

                    <div *ngIf="field.data_type=='date' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} <mat-icon style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <input class="form-control" name="{{field.name}}" style="font-size:x-small; min-height: 31px" type="date" [(ngModel)]="values[field.name]">
                    </div>
                    <div *ngIf="field.data_type=='bool' && field.is_document==false  && !field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <!-- <mat-slide-toggle class="ml-2" name="{{field.name}}" [(ngModel)]="values[field.name]">
                </mat-slide-toggle> -->
                        <mat-select class="form-control" name="{{field.name}}" [(ngModel)]="values[field.name]" style="font-size:x-small; min-height: 31px">
                            <mat-option value=true>
                                Yes
                            </mat-option>
                            <mat-option value=false>
                                No
                            </mat-option>
                        </mat-select>
                    </div>

                    <div *ngIf="field.data_type=='str' && field.is_document==false && field.values" class="input-group-sm mt-2 input-group col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="width:170px; font-size:x-small; max-height:90%">{{field.name}} <mat-icon *ngIf="field.help_text" style = "float: right; transform: scale(.7);" title={{field.help_text}}>help</mat-icon></span>
                        </div>
                        <mat-select class="form-control" [(ngModel)]="values[field.name]" [ngModelOptions]="{standalone: true}" (selectionChange)="getFieldGlassDependencies(values[field.name], field.name)" (ngModelChange)="values[field.name]" style="font-size:x-small; min-height: 31px">
                            <mat-option>
                                <ngx-mat-select-search [(ngModel)]="searchCtrl" [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'" name="{{field.name}}" id="{{field.name}}" [ngModelOptions]="{standalone: true}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let value of field.values |stringFilter:searchCtrl:'{{field.name}}'" [value]="value">
                                {{value}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div>
                <button mat-button class="float-right m-2" style="font-size:x-small; background-color: rgb(53, 53, 53); color:white" (click)="back()">Back</button>
                <button class="actionButton float-right m-2" style="font-size:x-small" (click)="saveForm('save')" mat-raised-button type="submit">Save</button>
               


                <button *ngIf="values['Laptop required'] == false || values['Laptop required'] == null" class="actionButton float-right m-2" style="font-size: x-small; background: #d0ebff; color: black" (click)="saveForm('submit')" mat-raised-button type="submit" [disabled]="!initiateForm.valid || values['Fieldglass TQ'] == null || values['Phone Number']['number'].length < 8"
                    [ngClass]="{'error': !initiateForm.valid || values['Fieldglass TQ'] == null || values['Phone Number']['number'].length < 8}">
    Submit
</button>

                <button *ngIf="values['Laptop required'] == true" class="actionButton float-right m-2" style="font-size: x-small; background: #d0ebff; color: black" (click)="saveForm('submit')" mat-raised-button type="submit" [disabled]="!initiateForm.valid || values['Fieldglass TQ'] == null || values['Phone Number']['number'].length < 8 || values['Mobile Number']['number'].length < 8"
                    [ngClass]="{'error': !initiateForm.valid || values['Fieldglass TQ'] == null || values['Phone Number']['number'].length < 8 || values['Mobile Number']['number'].length < 8}">
    Submit
</button>


            </div>
        </form>
    </div>

    <div style="padding-top: 5%;  font-size:small;">
        <i><strong>*Please note that once you select the Fieldglass TQ #, some fields will be auto-populated</strong></i>

    </div>
    <div style=" font-size:small;">
        <i><strong>*Please note that all fields with * are mandatory</strong></i>
    </div>



</div>

<div *ngIf="isNewResourceSubmitted">
    <mat-card class="mt-7" style=" background-color:#B5D9F3; text-align: center; margin-top: 10%; margin-left:25%; margin-right:25%;">
        <div style="padding-top: 3%;"><strong>Your signed documents have been sent for review. You will be notified regarding further actions.</strong></div>
        <button mat-button style="background-color: #03277c; color:white; margin-top:5%; font-size:x-small; margin-left:80%" (click)="back()">Ok</button>
    </mat-card>
</div>