<div>
    <mat-drawer-container [hasBackdrop]="false" autosize style="height: calc(100vh - 50px); width:100%">
        <mat-drawer #drawer mode="side" opened style="display: block; background-color: rgb(78, 78, 78); width:15%">
            <div>    
            <button mat-button class="glow-on-hover" [ngStyle]="{'font-weight': isDashboard === true ? 'bold' : 'normal'}"  style="width: 100%; height:60px; border:double" (click)="getDashboard()">Dashboard<mat-icon class="ml-5">dashboard</mat-icon></button> 
            </div>
            <div>    
                <button mat-button class="glow-on-hover" [ngStyle]="{'font-weight': isInitate === true ? 'bold' : 'normal' }" style=" background-color: rgb(217, 228, 217); width: 100%; height:60px; border:double" (click)="initiateOnboarding()">Initiate Onboarding<mat-icon class="ml-1">touch_app</mat-icon></button>
            </div>
            <div>    
                <button mat-button class="glow-on-hover"[ngStyle]="{'font-weight': isReferenceLinks === true ? 'bold' : 'normal' }" style=" background-color: rgb(217, 228, 217); width: 100%; height:60px; border:double" (click)="getReferenceLinks()">Reference Links<mat-icon class="ml-4">assignment</mat-icon></button>
            </div>      
           
        </mat-drawer>

        <mat-drawer-content>
            <div *ngIf = "isDashboard">
                <app-dashboard></app-dashboard>
            </div>
            <div *ngIf = "isInitate">
                <app-initiate-onboarding></app-initiate-onboarding>
            </div>
            <div *ngIf = "isReferenceLinks">
                <app-reference-links></app-reference-links>
            </div>
            
           
        </mat-drawer-content>
    </mat-drawer-container>
</div>