import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../../_models';
import { AlertService } from '../../_services/alert.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { CategoryService } from '../../_services/category.service';
import { DocumentService } from '../../_services/document.services';
import { FormService } from '../../_services/forms.service';
import { UserService } from '../../_services/user.service';
import { UsersAndGroupsService } from '../../_services/users-and-groups.service';
import {Sort} from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // dataSource = this.docResultsList;

  docResultsList: MatTableDataSource<any>
  // dataSource = this.docResultsList;
  documentCols: String[] = [];
  isNewResource: boolean = false
  isConfirmPPMD: boolean = false
  isFieldGlassAdmin: boolean = false
  isOnboarding: boolean = false
  isBackground: boolean = false
  currentUser: User
  allTasks: any
  tasks: any 
  taskValues: any
  tasksPresent:boolean = false
  resourceDetails: any
  sortedData: any;
  

  @ViewChild(MatTable) table: MatTable<any>;
  

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private formService: FormService,
    private usersGroupsService: UsersAndGroupsService
  ) { 
    this.currentUser = this.authenticationService.currentUserValue
  }


  sort
  @ViewChild(MatSort, { static: false }) set content(content: any) {
    this.sort = content;
    if (this.sort) {
      this.docResultsList.sort = this.sort;
    }
  }

  paginator;
  @ViewChild(MatPaginator, { static: false }) set pageContent(content: any) {
    this.paginator = content;
    if (this.paginator) {
      this.docResultsList.paginator = this.paginator;
    }
  }

  // ngAfterViewInit() {
  //   this.docResultsList.sort = this.sort;
  //   this.sortedData = this.allTasks.slice();
  // } 
  ngOnInit(): void {

    this.taskValues = []

    this.formService.getAllTassks().subscribe(resp=>{
    this.tasks = resp
    this.allTasks = resp['tasks']
    this.sortedData = this.allTasks.slice();
    if(this.allTasks.length>0){
      this.allTasks.forEach(element => {
        if(element['Task Name'] == "BGVTask"){
          element['Task Name'] = "Background Verification"
        }
        if(element['Task Name'] == "FieldGlassAdmin"){
          element['Task Name'] = "Fieldglass Admin Verification"
        }
        if(element['Task Name'] == "NewResourceForm"){
          element['Task Name'] = "New Resource Form"
        }
        if(element['Task Name'] == "OnboardingForm"){
          element['Task Name'] = "Onboarding Specialist Verification"
        }
  
      });
    }

    this.documentCols = resp['columns'];
    // this.documentCols.push('Action');
    this.docResultsList = new MatTableDataSource(this.allTasks);
    this.docResultsList.sort = this.sort;
    this.docResultsList.paginator = this.paginator;
    })
  }

  getCurrentStep($event, element){

    if(element['Task Name'] == 'New Resource Form'){
      this.isNewResource = true
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.resourceDetails = element
    }

    else if(element['Task Name'] == 'Confirm PPMD Approval'){

      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = true
      this.isFieldGlassAdmin = false
      this.resourceDetails = element
      
    }
    else if(element['Task Name'] == 'Background Verification'){

      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = true
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.resourceDetails = element
      
    }
    else if(element['Task Name'] == 'Onboarding Specialist Verification'){

      this.isNewResource = false
      this.isOnboarding = true
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.resourceDetails = element
      
    }
    else if(element['Task Name'] == 'Fieldglass Admin Verification'){

      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = true
      this.resourceDetails = element
      
    }
  }


  backNewResource(hide: boolean) {
    if (hide) {
      this.isNewResource = true
    }
    else {
      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.ngOnInit()
    }
  }

  backBackground(hide: boolean) {
    if (hide) {
      this.isBackground = true
    }
    else {
      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.ngOnInit()
    }
  }
  backConfirmPPMD(hide: boolean) {
    if (hide) {
      this.isConfirmPPMD = true
    }
    else {
      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.ngOnInit()
    }
  }
  backOnboarding(hide: boolean) {
    if (hide) {
      this.isOnboarding = true
    }
    else {
      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.ngOnInit()
    }

  }

  backFieldglass(hide: boolean) {
    if (hide) {
      this.isFieldGlassAdmin = true
    }
    else {
      this.isNewResource = false
      this.isOnboarding = false
      this.isBackground = false
      this.isConfirmPPMD = false
      this.isFieldGlassAdmin = false
      this.ngOnInit()
    }
  }


  sortData(sort: Sort) {
    const data = this.allTasks.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Resource First Name': return compare(a['Resource First Name'], b['Resource First Name'], isAsc);
        case 'Resource Last Name': return compare(a['Resource Last Name'], b['Resource Last Name'], isAsc);
        case 'Task Name': return compare(a['Task Name'], b['Task Name'], isAsc);
        case 'Task Start Date': return compare(a['Task Start Date'], b['Task Start Date'], isAsc);
        case 'Email': return compare(a['Email'], b['Email'], isAsc);
        case 'Onboarding Specialist': return compare(a['Onboarding Specialist'], b['Onboarding Specialist'], isAsc);
        case 'Project Name': return compare(a['Project Name'], b['Project Name'], isAsc);
        case 'Resource Project Start Date': return compare(a['Resource Project Start Date'], b['Resource Project Start Date'], isAsc);
        default: return 0;
      }
    });
  }
  // deleterow(row: any): void {
  //   const index = this.sortedData.indexOf(row, 0);
  //   if (index > -1) {
  //     this.sortedData.splice(index, 1);
  //   }
  //   this.table.renderRows();
  // }
  // Deleterow(index: number) {
  //   const data = this.docResultsList.data;
  //   data.splice((this.paginator.pageIndex * this.paginator.pageSize) + index, 1);

  //   this.docResultsList.data = data;
  // }
  Delete(){
  if(confirm('Are you sure you wish to cancel this Document?')){
    // this.service.Operations
  }
  // this.delete = this.Operations.delete
}

}


// Delete(){
  // if(confirm('Are you sure to delete??')){
  //   this.service.Operations
  // }
//   this.delete = this.Operations.delete
// }



function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
