
<div *ngIf = "!isNewResource && !isOnboarding && !isBackground && !isConfirmPPMD && !isFieldGlassAdmin" >
    <!-- <mat-card style = "background-color:#03277c; text-align: center; color: white">
        <strong>Active Onboarding Applications</strong>
    
    </mat-card> -->
    
    <div *ngIf="allTasks">
    <table mat-table style="margin-top: 4%; width:100%; margin-right:10%" [dataSource]="docResultsList" matSort (matSortChange)="sortData($event)"
    matSortDirection="desc" aria-describedby="document-list" *ngIf="allTasks.length>0">
    <div *ngFor = "let column of documentCols;index as i">
        <ng-container matColumnDef="{{documentCols[i]}}" *ngIf = "column != 'Task Name'">
            <th id="{{documentCols[i]}}" mat-header-cell *matHeaderCellDef mat-sort-header ="{{documentCols[i]}}"> {{column}}
            </th>
            <td mat-cell *matCellDef="let element">
          {{element[column]}}
            </td>
        </ng-container>
        <ng-container matColumnDef="{{documentCols[i]}}" *ngIf = "column == 'Task Name'">
            <th id="{{documentCols[i]}}" mat-header-cell *matHeaderCellDef mat-sort-header ="{{documentCols[i]}}"> {{column}}
            </th>
            <td mat-cell *matCellDef="let element">
          <a style="color: blue; text-decoration:underline; cursor:pointer" (click)="getCurrentStep($event, element)">{{element[column]}}</a>
            </td>
        </ng-container>
    </div>
  
    <tr mat-header-row *matHeaderRowDef="documentCols"></tr>
    <tr mat-row *matRowDef="let row; columns: documentCols;"></tr>
    </table>
    <mat-paginator *ngIf="allTasks.length>0" style = "padding-right:10%" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    <div *ngIf="allTasks.length<1">
        <mat-card class="mt-7" style = "background-color:#B5D9F3; text-align: center; margin-top: 10%; margin-left:25%; margin-right:25%; height:100px;" >
            <div style="padding-top: 3%;"><strong>There are no pending tasks in the queue</strong></div>
        </mat-card>
        </div>
    </div>
    
    <div *ngIf="tasks">
    <mat-card class="mt-7" style = "background-color:#B5D9F3; text-align: center; margin-top: 10%; margin-left:25%; margin-right:25%; height:100px;" *ngIf="tasks.length<1">
        <div style="padding-top: 3%;"><strong>You do not have any pending tasks</strong></div>
    </mat-card>
    </div>
    
    </div>
    
    <div *ngIf="isConfirmPPMD">
    <app-confirm-ppmd-form></app-confirm-ppmd-form>
    </div>
    
    <div *ngIf="isBackground">
    <app-background-form (hideNewResource)="backBackground($event)" [resourceDetails]="resourceDetails"></app-background-form>
    </div>
    <div *ngIf="isOnboarding">
    <app-onboarding-form (hideNewResource)="backOnboarding($event)" [resourceDetails]="resourceDetails"></app-onboarding-form>
    </div>
    <div *ngIf="isNewResource">
    <app-new-resource-form (hideNewResource)="backNewResource($event)" [resourceDetails]="resourceDetails"></app-new-resource-form>
    </div>
    <div *ngIf="isFieldGlassAdmin">
        <app-field-glass-form (hideNewResource)="backFieldglass($event)" [resourceDetails]="resourceDetails"></app-field-glass-form>
    </div>
    
    
    