import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DocumentService } from 'src/app/_services/document.services';

@Component({
  selector: 'app-confirm-ppmd-form',
  templateUrl: './confirm-ppmd-form.component.html',
  styleUrls: ['./confirm-ppmd-form.component.scss']
})
export class ConfirmPPMDFormComponent implements OnInit {

  constructor(
    private documentService: DocumentService
  ) {

    
   }

  fileValue

  @ViewChild('fileInput') fileInput: ElementRef;

  NDA: File
  fileByteArray = [];

  ngOnInit(): void {
  }

upload(){
  var meta = {
    "Region": "check",
  }

  var reader = new FileReader();
  reader.readAsDataURL(this.NDA);
  reader.onloadend = () => {
    this.fileValue = reader.result
  };

  console.log("FILE VALUE", this.fileValue)
  // reader.onload = processFile(this.NDA);
  // reader.readAsArrayBuffer(this.NDA);



  // create_blob(this.NDA)
  const formData = new FormData();
  formData.append("title", "check");
  formData.append("category_id", String(1));
  formData.append("meta_data", JSON.stringify(meta));
    formData.append("file", this.NDA);
    console.log("FORM", formData)

    formData.forEach((value,key) => {
      console.log(key+" "+value)
    });
this.documentService.uploadDocumentDirect(formData).subscribe(resp=>{
  console.log("RESP,", resp)
})
}

handleFileInput(files: File) {

  //this.NDA = files.item(0);
  this.NDA = files[0]
  

console.log("THIS.NDA", this.NDA)
}

}

function processFile(theFile){
  this.fileByteArray = []
  return function(e) { 

    var theBytes = e.target.result; //.split('base64,')[1]; // use with uploadFile2
    this.fileByteArray.push(theBytes);
    document.getElementById('file').innerText = '';
    for (var i=0; i<this.fileByteArray.length; i++) {
        document.getElementById('file').innerText += this.fileByteArray[i];
    }
  }
}

function create_blob(file) {
  var reader = new FileReader();
  // reader.onload = function() { callback(reader.result) };
  reader.readAsDataURL(file);
}
