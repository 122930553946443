import { Component, OnInit } from '@angular/core';
import { FormService } from 'src/app/_services/forms.service';
import { FormArray, Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-report-load',
  templateUrl: './report-load.component.html',
  styleUrls: ['./report-load.component.scss']
})
export class ReportLoadComponent implements OnInit {
  reportType: string[];
  loadForm: FormGroup;
  file: File = null;
  report: string;
  uploadMessage : string;
  documentLoaded: boolean = false

  constructor(
    
    private formService: FormService,
  ) { }

  ngOnInit(): void {
    this.formService.getKey('Report Type').subscribe(resp=>{
      this.reportType = resp['value']['ReportType']
    })

  }

  handleFileInput(files: FileList) {
    this.file = files.item(0);
  }

  load(){
    const formData = new FormData();
    formData.append("reporttype", this.report);
    formData.append("FILES", this.file);
    console.log("REPORT TYPE", this.report )

    this.formService.migration(formData).subscribe(resp=>{
      this.uploadMessage = resp.body.message
      console.log("UPLOAD", this.uploadMessage)
      this.documentLoaded = true
      
    })
  }

  back(){
    this.documentLoaded = false
    this.ngOnInit()
  }



}
