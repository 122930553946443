<mat-card style="background-color:#03277c; text-align: center; color: white">
    <strong>Document Reference Links</strong>

</mat-card>

<div *ngIf="!isPreview && !openUpload">
    <div class="input-group-sm mb-1 input-group col-9" style="padding-top: 3%; padding-left: 30%;">
        <div class="input-group-prepend">
            <div class="input-group-text form-label-50">Region</div>
        </div>
        <mat-select style="height:auto" name="region" class="form-control" (selectionChange)="fetchRegion(region)"
            [(ngModel)]="region" (ngModelChange)="region">
            <mat-option value="United States of America">United States of America</mat-option>
            <mat-option value="USI">USI</mat-option>
            <mat-option value="India">India</mat-option>
            <mat-option value="China">China</mat-option>
            <mat-option value="UK">UK</mat-option>
            <mat-option value="China">China</mat-option>
            <mat-option value="Australia">Australia</mat-option>
            <mat-option value="Mexico">Mexico</mat-option>
            <mat-option value="Poland">Poland</mat-option>
            <mat-option value="Singapore">Singapore</mat-option>
            <mat-option value="Sweden">Sweden</mat-option>
        </mat-select>

        <input style="display: none" type="file" (change)="openUploadForm($event.target.files)" #fileInput>
        <button mat-button *ngIf="isAdmin" style="background-color: #B5D9F3; width: 30%; margin-left:10%"
            (click)="fileInput.click()">Upload<mat-icon class="ml-2">cloud_upload</mat-icon></button>

    </div>


    <div *ngIf="docResultsList" style="padding-top: 3%; padding-left: 30%; padding-right:7%">
        <table mat-table [dataSource]="docResultsList" matSort matSortDirection="desc" aria-describedby="document-list">
            <ng-container matColumnDef="title">
                <th id="title" mat-header-cell *matHeaderCellDef mat-sort-header> Document Type
                </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="viewDocument($event, element)"
                        style="color: blue; text-decoration:underline; cursor:pointer"> {{element.documentName}}</a>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th id="action" mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon title="Download Document" class="mr-1" (click)="downloadDocument($event, element)">
                        save_alt</mat-icon>
                    <!-- <mat-icon style="padding-left:10%; padding-right:10%" title="View Document" svgIcon="file" (click)="viewDocument($event, element)">
            </mat-icon> -->
                    <mat-icon style="padding-left:15%;" *ngIf="isAdmin" title="Delete Document"
                        (click)="deleteDocument($event, element)">delete
                    </mat-icon>

                    <input style="display: none;padding-left:10%" type="file"
                        (change)="openUploadForm($event.target.files, element)" #fileInput>
                    <mat-icon class="ml-5" *ngIf="isAdmin" style="padding-left:8%"
                        title="Upload New Version of Document" (click)="fileInput.click()">cloud_upload</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="documentCols"></tr>
            <tr mat-row *matRowDef="let row; columns: documentCols;"></tr>
        </table>

        
    </div>

    <div class="col-6" style="margin-left: 28%;">
        <div style="padding-top: 5%;  font-size:small; ">
            <i><strong>*Please select the region your member firm belongs to and download the appropriate
                    documents.</strong></i>
        </div>
        <div style=" font-size:small; ">
            <i><strong>*Please open and sign the document in Adobe Reader after downloading.</strong></i>
        </div>
        <div style="font-size:small; ">
            <i><strong>*Once documents are downloaded and signed, please proceed to <a [routerLink]="'/work-item'"
                        routerLinkActive="selected">Work Item</a> tab to continue with your onboarding
                    process.</strong></i>
        </div>

    </div>


    <!-- <div style = "padding-top: 10%; padding-left:30%; font-size:small">
<i><strong>*Please select the region your member firm belongs to and download the appropriate documents</strong></i>
</div> -->
</div>

<div class="row" *ngIf="isPreview && !openUpload">

    <div class="col-6">

        <div class="input-group-sm mb-1 input-group col-11" style="padding-top: 10%; padding-left: 10%;">
            <div class="input-group-prepend">
                <div class="input-group-text form-label-50">Region</div>
            </div>
            <mat-select style="height:auto" name="region" class="form-control" (selectionChange)="fetchRegion(region)"
                [(ngModel)]="region" (ngModelChange)="region">
                <mat-option value="United States of America">United States of America</mat-option>
                <mat-option value="USI">USI</mat-option>
                <mat-option value="India">India</mat-option>
                <mat-option value="UK">UK</mat-option>
                <mat-option value="China">China</mat-option>
                <mat-option value="Australia">Australia</mat-option>
                <mat-option value="Mexico">Mexico</mat-option>
                <mat-option value="Poland">Poland</mat-option>
                <mat-option value="Singapore">Singapore</mat-option>
                <mat-option value="Sweden">Sweden</mat-option>
            </mat-select>

            <input style="display: none" type="file" (change)="openUploadForm($event.target.files)" #fileInput>
            <button mat-button *ngIf="isAdmin" style=" background-color: #B5D9F3; width: 30%; margin-left:10%"
                (click)="fileInput.click()">Upload<mat-icon class="ml-2">cloud_upload</mat-icon></button>
        </div>


        <div *ngIf="docResultsList" style="padding-top: 10%; width:123%; padding-left: 10%;">
            <table mat-table [dataSource]="docResultsList" matSort matSortDirection="desc"
                aria-describedby="document-list">
                <ng-container matColumnDef="title">
                    <th id="title" mat-header-cell *matHeaderCellDef mat-sort-header> Document Type
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <a (click)="viewDocument($event, element)"
                            style="color: blue; text-decoration:underline; cursor:pointer"> {{element.documentName}}</a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th id="action" mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon title="Download Document" class="mr-1" (click)="downloadDocument($event, element)">
                            save_alt</mat-icon>
                        <!-- <mat-icon  style="padding-left:10%; padding-right:10%" title="View Document" (click)="viewDocument($event, element)">preview
            </mat-icon> -->
                        <mat-icon style="padding-left:15%" *ngIf="isAdmin" title="Delete Document"
                            (click)="deleteDocument($event, element)">delete
                        </mat-icon>
                        <input style="display: none;padding-left:10%" type="file"
                            (change)="openUploadForm($event.target.files, element)" #fileInput>
                        <mat-icon class="ml-5" *ngIf="isAdmin" style="padding-left:8%"
                            title="Upload New Version of Document" (click)="fileInput.click()">cloud_upload</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="documentCols"></tr>
                <tr mat-row *matRowDef="let row; columns: documentCols;"></tr>
            </table>

            

            
        </div>
        <div class="col-10" style="margin-left:8%">
            <div style="padding-top: 5%;  font-size:small">
                <i><strong>*Please select the region your member firm belongs to and download the appropriate
                        documents.</strong></i>
            </div>
            <div style=" font-size:small">
                <i><strong>*Please open and sign the document in Adobe Reader after downloading.</strong></i>
            </div>
            <div style="font-size:small">
                <i><strong>*Once documents are downloaded and signed, please proceed to <a [routerLink]="'/work-item'"
                            routerLinkActive="selected">Work Item</a> tab to continue with your onboarding
                        process.</strong></i>
            </div>
        </div>
        <!-- <div style = "padding-top: 20%; padding-left:10%; font-size:small">
<i><strong>*Please select the region your member firm belongs to and download the appropriate documents</strong></i>
</div> -->
    </div>


    <div class="col-6">
        <app-file-view [documentID]="documentID" [documentType]="documentType" [versionNumber]="docVersionNumber">
        </app-file-view>
    </div>
</div>

<div *ngIf="openUpload">
    <app-upload [fileToUpload]="fileToUpload" [selectedDocument]="selectedDocument" (openUpload)="back($event)">
    </app-upload>
</div>