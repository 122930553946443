import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError, BehaviorSubject} from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { switchMap, catchError, first } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        this.alertService.clear()
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        if (!request.url.includes('.blob.core.windows.net/') && (!request.url.endsWith('s3.amazonaws.com/')) && (!request.url.includes('/upload/direct')) && (!request.url.includes('/upload-version/direct')) && (!request.url.includes('/migration')) && (!request.url.includes('googleapis.com/')) && (!request.url.includes('/states/completed?auto-progress=true')) && (!request.url.includes('onboarding_workflow-kjar-1_0-SNAPSHOT'))&& (!request.url.includes('ps24drplda.execute-api.us-east-2.amazonaws.com')) && (!request.url.includes('https://8mjigfuo2i.execute-api.us-east-2.amazonaws.com')) ) {
            if (currentUser && currentUser.token) {
                request = request.clone({
                    //setHeaders: { 
                    //    Authorization: `Bearer ${currentUser.token}`
                    //}
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${currentUser.token}`,
                    })
                });
            }
        }
        return next.handle(request).pipe(catchError(err => {
            //console.log("inside token error");
            if (err instanceof HttpErrorResponse && err.status === 401) {

                return this.authenticationService.refreshToken().pipe(
                    switchMap((user: any) => {
                        request = request.clone({
                            headers: new HttpHeaders({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${user.token}`,
                                'dms-user': currentUser.username
                            })
                        })
                        return next.handle(request)
                    }),
                    catchError(error => {
                        //console.log(error);
                        this.authenticationService.logout();
                        location.reload(true);
                        return Observable.throw('')
                    }));

            }
            return Observable.throw(err);

        }));
    }

}